/**
 * plugins/index.js
 */

// configure three.js plugin
import * as THREE from 'three'
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh'
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree
THREE.Mesh.prototype.raycast = acceleratedRaycast

// load plugins
import { loadFonts } from '@/plugins/webfont'
import router from '@/plugins/router'
import store from '@/plugins/vuex'
import vuetify from '@/plugins/vuetify'

// register plugins
export function registerPlugins (app) {
  loadFonts()
  app
    .use(router)
    .use(store)
    .use(vuetify)
}
