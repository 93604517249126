

export const texture = {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    getters: {

    },
    actions: {

    }
}