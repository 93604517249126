
//// CONSTANTS

// modify
const MODIFY_ACTIVE_DEFAULT = true
const MODIFY_RESOLUTION_DEFAULT = 64
const MODIFY_SMOOTH_DEFAULT = false

export const mesh = {
    namespaced: true,
    state: {

        modify: {
            active: MODIFY_ACTIVE_DEFAULT,
            resolution: MODIFY_RESOLUTION_DEFAULT,
            smooth: MODIFY_SMOOTH_DEFAULT,
        }
    },
    mutations: {
        RESET(state) {


            state.modify.active = MODIFY_ACTIVE_DEFAULT
            state.modify.resolution = MODIFY_RESOLUTION_DEFAULT
            state.modify.smooth = MODIFY_SMOOTH_DEFAULT
        },

        SET_MODIFY_ACTIVE(state, active) {
            state.modify.active = active
        },
        SET_MODIFY_RESOLUTION(state, resolution) {
            state.modify.resolution = resolution
        },
        SET_MODIFY_SMOOTH(state, smooth) {
            state.modify.smooth = smooth
        }
    },
    getters: {
        
        keyModifyActiveDefault: () => MODIFY_ACTIVE_DEFAULT,
        keyModifyResolutionDefault: () => MODIFY_RESOLUTION_DEFAULT,
        keyModifySmoothDefault: () => MODIFY_SMOOTH_DEFAULT,

        isModifiyActive: state => state.modifiy.active,
        isModifiySmooth: state => state.modifiy.smooth,
        
        getModifyResolution: state => state.modify.resolution,
    },
    actions: {
        resetMesh({ commit }) 
        {
            commit('RESET')
        },
        
        setModifiyActive({ commit }, active) 
        {
            commit('SET_MODIFIY_ACTIVE', active)
        },
        setModifyResolution({ commit }, resolution) 
        {
            commit('SET_MODIFY_RESOLUTION', resolution)
        },
        setModifySmooth({ commit }, smooth) 
        {
            commit('SET_MODIFY_SMOOTH', smooth)
        },


        
        // TODO move to new modulator vuex
        // modify model
        async modifyModel({ getters, dispatch, commit }, { 
            asset = null
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to delete.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })
                dispatch('app/setLoadingText', 'optimizing model', { root: true })

                // TODO switch to modify
                // call route to optimize model
                const response = await dispatch('api/modelsModelOptimizePost', {
                    asset,
                    centering_active: true,
                    voxelizing_active: getters.isModifyActive,
                    voxelizing_resolution: getters.getModifyResolution,
                    smoothing_active: getters.isModifySmooth,
                }, { root: true })

                // switch to modified model
                commit('SET_VIEWER_FILE', VIEWER_FILE_MODIFIED)

                // wait for file to be generated
                await dispatch('api/assetsAssetFileKeyGet', {
                    asset,
                    key: 'optimize'
                }, { root: true })

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                return response
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                throw error
            }
        },
    }
}