// Composables
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/plugins/vuex'

const routes = [
	{
		path: '/',
		component: () => import('@/layout/NavigationLayout.vue'),
		children: [
			{
				path: '',
				name: 'AppsView',
				component: () => import('@/views/AppsView.vue'),
				meta: {
					title: 'Apps',
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/share',
		component: () => import('@/layout/BaseLayout.vue'), 
		children: [
			{
				path: '',
				name: 'ShareView',
				component: () => import('@/views/ShareView.vue'),
				meta: {
					action: true,
					requiresAuth: false,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/login',
		component: () => import('@/layout/BaseLayout.vue'),
		children: [
			{
				path: '',
				name: 'LoginView',
				component: () => import('@/views/LoginView.vue'),
				meta: {
					requiresAuth: false,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/account',
		component: () => import('@/layout/NavigationLayout.vue'),
		children: [
			{
				path: '',
				name: 'AccountView',
				component: () => import('@/views/AccountView.vue'),
				meta: {
					title: 'Account',
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/developer',
		component: () => import('@/layout/NavigationLayout.vue'),
		children: [
			{
				path: '',
				name: 'DeveloperView',
				component: () => import('@/views/DeveloperView.vue'),
				meta: {
					title: 'Developer',
					requiresAuth: true,
					requiresDev: true
				}
			}
		]
	},
	{
		path: '/library',
		component: () => import('@/layout/NavigationLayout.vue'),
		children: [
			{
				path: '',
				name: 'LibraryView',
				component: () => import('@/views/LibraryView.vue'),
				meta: {
					title: 'Library',
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/sketch',
		component: () => import('@/layout/NavigationLayout.vue'),
		children: [
			{
				path: '',
				name: 'SketchView',
				component: () => import('@/views/SketchView.vue'),
				meta: {
					title: 'Sketchurizer',
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/flow',
		component: () => import('@/layout/NavigationLayout.vue'),
		children: [
			{
				path: '',
				name: 'FlowView',
				component: () => import('@/views/FlowView.vue'),
				meta: {
					title: 'Sketchurizer (beta)',
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/mesh',
		component: () => import('@/layout/NavigationLayout.vue'),
		children: [
			{
				path: '',
				name: 'MeshView',
				component: () => import('@/views/MeshView.vue'),
				meta: {
					title: 'Meshurizer',
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/texture',
		component: () => import('@/layout/NavigationLayout.vue'),
		children: [
			{
				path: '',
				name: 'TextureView',
				component: () => import('@/views/TextureView.vue'),
				meta: {
					title: 'Texturizer',
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/box',
		component: () => import('@/layout/BaseLayout.vue'),
		children: [
			{
				path: '',
				name: 'BoxView',
				component: () => import('@/views/BoxView.vue'),
				meta: {
					title: 'Boxurizer',
					icon: store.getters['app/getLegacyIcon'],
					action: true,
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/model',
		component: () => import('@/layout/BaseLayout.vue'),
		children: [
			{
				path: '',
				name: 'ModelView',
				component: () => import('@/views/ModelView.vue'),
				meta: {
					title: 'Modulator',
					icon: store.getters['app/getLegacyIcon'],
					action: true,
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	
	// set title of page
    document.title = to.meta.title ? 'GenerIO.app | ' + to.meta.title : 'GenerIO.app'

	// set loading state
	store.dispatch('app/activateLoading')

	// handle restrictions
    const isAuthenticated = store.getters['app/isAuthenticated']
	const isDeveloper = store.getters['app/isDeveloper']
	const hasAppLibrary = store.getters['app/hasAppLibrary']
	const hasAppSketch = store.getters['app/hasAppSketch']
	const hasAppMesh = store.getters['app/hasAppMesh']
	const hasAppTexture = store.getters['app/hasAppTexture']
	const hasAppBox = store.getters['app/hasAppBox']
	const hasAppModel = store.getters['app/hasAppModel']
	const hasAppText = store.getters['app/hasAppText']

	// developer restrictions
	if (to.meta.requiresDev && !isDeveloper) 
	{
		if (isAuthenticated) next({ name: 'AppsView' })
		else next({ name: 'LoginView' })
	}
	// authentication restrictions
	else if (to.meta.requiresAuth && !isAuthenticated)
	{
		next({ name: 'LoginView' })	
	}
	// app restrictions
	else if ((to.path === '/library' && !hasAppLibrary) ||
			 (to.path === '/sketch' && !hasAppSketch) ||
			 (to.path === '/mesh' && !hasAppMesh) ||
			 (to.path === '/texture' && !hasAppTexture) ||
			 (to.path === '/box' && !hasAppBox) ||
			 (to.path === '/model' && !hasAppModel) ||
			 (to.path === '/text' && !hasAppText))
	{
		next({ name: 'AppsView' })	
	}
	else  
	{
		next()
	}
})

export default router