// vuex.js store for Library


//// CONSTANTS

// mode
const MODE_FLOW = 'flows'
const MODE_MODEL = 'model'
const MODE_PREVIEW = 'previews'
const MODE_IMAGE = 'images'
const MODE_SKETCH = 'sketches'
const MODE_DEFAULT = MODE_FLOW


// sorting
const SORT_TYPE_DATE = 'date'
const SORT_TYPE_RATING = 'rating'
const SORT_TYPE_DEFAULT = SORT_TYPE_DATE
const SORT_ORDER_ASCENDING = 'ascending'
const SORT_ORDER_DESCENDING = 'descending'
const SORT_ORDER_DEFAULT = SORT_ORDER_DESCENDING


//// HELPER

// create state
const createState = () => {
    return {
        mode: MODE_DEFAULT,
        searchQuery: '',
        sortingType: SORT_TYPE_DEFAULT,
        sortingOrder: SORT_ORDER_DEFAULT,
    }
}


//// VUEX STORE
export const library = {
    namespaced: true,
    state: createState(),
    mutations: {
        RESET(state) {
            Object.assign(state, createState())
        },

        SET_MODE(state, mode) {
            state.mode = mode
        },
        SET_SEARCH_QUERY(state, searchQuery) {
            state.searchQuery = searchQuery
        },
        SET_SORTING_TYPE(state, sortingType) {
            state.sortingType = sortingType
        },
        SET_SORTING_ORDER(state, sortingOrder) {
            state.sortingOrder = sortingOrder
        }
    },
    getters: {
        keyModeDefault: () => MODE_DEFAULT,
        keyModeFlow: () => MODE_FLOW,
        keyModeModel: () => MODE_MODEL,
        keyModePreview: () => MODE_PREVIEW,
        keyModeImage: () => MODE_IMAGE,
        keyModeSketch: () => MODE_SKETCH,
        
        keySortTypeDate: () => SORT_TYPE_DATE,
        keySortTypeRating: () => SORT_TYPE_RATING,

        keySortOrderAscending: () => SORT_ORDER_ASCENDING,
        keySortOrderDescending: () => SORT_ORDER_DESCENDING,

        hasSearchQuery: state => state.searchQuery && state.searchQuery.length > 0,

        // TODO library should not be limited to Sketchurizer (and so Sketchurizer centric when more apps join)
        getAllAssets: (state, getters, rootState, rootGetters) => {
            return rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppSketch']
            )
        },
        getFilteredAssets: (state, getters) => {
            // get array of assets
            let assets = [...getters.getAllAssets]
            if (!assets) return []

            // apply search query
            const searchQuery = getters.getSearchQuery
            const optimizedQuery = searchQuery ? searchQuery.trim().toLowerCase() : null
            if (optimizedQuery) {
                assets = assets.filter(asset => 
                    asset.additional?.positive?.toLowerCase().includes(optimizedQuery)
                )
            }

            // get sorting
            const sortingType = getters.getSortingType
            const sortingOrder = getters.getSortingOrder

            // sort by date
            if (sortingType === SORT_TYPE_DATE) 
            {
                if (sortingOrder === SORT_ORDER_DESCENDING) {
                    assets.sort((a, b) => b.created - a.created)
                } else {
                    assets.sort((a, b) => a.created - b.created)
                }
            } 

            // sort by type
            else if (sortingType === SORT_TYPE_RATING) 
            {
                if (sortingOrder === SORT_ORDER_DESCENDING) {
                    assets.sort((a, b) => 
                        (b.additional?.rating || 0) - (a.additional?.rating || 0)
                    )
                } else {
                    assets.sort((a, b) => 
                        (a.additional?.rating || 0) - (b.additional?.rating || 0)
                    )
                }
            }

            return assets
        },
        getFilteredAssetsByFlow: (state, getters) => {

            const assets = getters.getFilteredAssets.filter(
                asset => asset.additional?.count >= 0
            )
        
            // group assets by count using a Map (maintains insertion order)
            const assetsGrouped = new Map()
        
            for (const asset of assets) {
                const count = asset.additional.count
                if (!assetsGrouped.has(count)) {
                    assetsGrouped.set(count, [])
                }
                assetsGrouped.get(count).push(asset)
            }
        
            // transform into an array of groups
            const assetsList = Array.from(assetsGrouped, ([count, groupAssets]) => ({
                count: parseInt(count),
                assets: groupAssets,
            }))
        
            return assetsList
        },
        getFilteredInputAssets: (state, getters, rootState, rootGetters) => {
            return getters.getFilteredAssets.filter(asset => rootGetters['sketch/isAssetInput'](asset))
        },
        getFilteredSketchAssets: (state, getters, rootState, rootGetters) => {
            return getters.getFilteredAssets.filter(asset => rootGetters['sketch/isAssetInputSketch'](asset))
        },
        getFilteredImageAssets: (state, getters, rootState, rootGetters) => {
            return getters.getFilteredAssets.filter(asset => rootGetters['sketch/isAssetInputImage'](asset))
        },
        getFilteredPreviewAssets: (state, getters, rootState, rootGetters) => {
            return getters.getFilteredAssets.filter(asset => rootGetters['sketch/isAssetPreview'](asset))
        },
        getFilteredModelAssets: (state, getters, rootState, rootGetters) => {
            return getters.getFilteredAssets.filter(asset => rootGetters['sketch/isAssetModel'](asset))
        },

        getMode: state => state.mode,
        getSearchQuery: state => state.searchQuery,
        getSortingType: state => state.sortingType,
        getSortingOrder: state => state.sortingOrder,
    },
    actions: {
        resetLibrary({ commit }) {
            commit('RESET')
        },

        setMode({ commit }, mode) {
            commit('SET_MODE', mode)
        },
        setSearchQuery({ commit }, searchQuery) {
            commit('SET_SEARCH_QUERY', searchQuery)
        },
        setSortingType({ commit }, sortingType) {
            commit('SET_SORTING_TYPE', sortingType)
        },
        setSortingOrder({ commit }, sortingOrder) {
            commit('SET_SORTING_ORDER', sortingOrder)
        },

        // rate asset
        async rateAsset({ dispatch }, {
            assetId,
            rating
        })
        {
            // ensure assetId parameter is provided
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an assetId to rate.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // call route to rate asset
                const response = await dispatch('api/assetsAssetAdditionalPut', {
                    assetId,
                    additional: {
                        rating
                    }
                }, { root: true })

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // log event
                dispatch('app/logEvent', { 
                    name: "Sketchurizer Asset Rating", 
                    description: "User has rated the asset: " + assetId
                }, { root: true })

                return response
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // log bug
                dispatch('app/logBug', { 
                    name: "Sketchurizer Asset Rating",
                    description: "Rating update failed for asset: " + assetId
                }, { root: true })

                // provide user feedback
                dispatch('app/showToast', { 
                    text: 'Asset rating failed to update.', 
                    warning: true
                }, { root: true })

                throw error
            }
        },

        // download asset
        async downloadAsset({ dispatch }, {
            asset,
            key = 'default',
            format = null
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to download.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // call route to download asset
                const response = await dispatch('api/assetsAssetDownloadHelper', {
                    asset,
                    key,
                    format
                }, { root: true })

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                dispatch('app/showToast', { 
                    text: 'Downloading the asset was successful.'
                }, { root: true })

                return response
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                 dispatch('app/showToast', { 
                    text: 'Downloading the asset has failed.', 
                    warning: true
                }, { root: true })

                throw error
            }
        },

        // delete asset 
        async deleteAsset({ commit, dispatch, getters }, {
            asset,
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to delete.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // call route to delete asset
                const response = await dispatch('api/assetsAssetDelete', {
                    assetId: asset.id
                }, { root: true })

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                dispatch('app/showToast', { 
                    text: 'Deleting the asset was successful.'
                }, { root: true })

                return response
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                 dispatch('app/showToast', { 
                    text: 'Deleting the asset has failed.', 
                    warning: true
                }, { root: true })

                throw error
            }
        },

        // delete assets
        async deleteAssets({ dispatch }, assets)
        {
            // ensure assets parameter is provided
            if (assets === null || assets === undefined) {
                throw new Error("Please provide a list of assets to delete.")
            }

            try
            {
                // iterate through all assets
                for (let asset of assets)
                {
                    // delete asset
                    dispatch('deleteAsset', { asset })
                }
            }
            catch(error)
            {
                throw error
            }
        }
    }
}