import store from '@/plugins/vuex'
import { ColorSingleMaterial } from '@/classes/data/material/ColorSingleMaterial'
import { ColorVertexMaterial } from '@/classes/data/material/ColorVertexMaterial'
import { TextureGenericMaterial } from '@/classes/data/material/TextureGenericMaterial'
import { TextureWoodMaterial } from '@/classes/data/material/TextureWoodMaterial'
import { TextureSandMaterial } from '@/classes/data/material/TextureSandMaterial'
import { TextureBrickMaterial } from '@/classes/data/material/TextureBrickMaterial'

/*
 * Material Factory Class
 */
export class MaterialFactory 
{
    // get all material classes
    static getClasses()
    {
        return [
            ColorSingleMaterial,
            ColorVertexMaterial,
            TextureGenericMaterial,
            TextureWoodMaterial,
            TextureSandMaterial,
            TextureBrickMaterial
        ]
    }

    static getClass(index)
    {
        const classes = MaterialFactory.getClasses()
        if (classes && classes.length > index) return classes[index]
        return null
    }

    // get name of material type
    static getName(materialClass, formatted=false)
    {
        // get type name
        const name = materialClass.typeName()

        // check if name should be formatted
        if(formatted) 
            return "<b>" + name + "</b>&nbsp;Material"

        // return unformatted
        return name + " Material"
    }

    // get description of material type
    static getDescription(materialClass)
    {
        return materialClass.typeDescription()
    }

    // get generator component name (string) of material type
    static getGenerator(materialClass)
    {
        return materialClass.typeGenerator()
    }

    static getPreview(materialClass)
    {
        if (store.getters['app/getTheme'].dark) return materialClass.typePreviewDark()
        return materialClass.typePreviewLight()
    }

    // get all material types
    static getTypes()
    {
        // get classes and prepare return
        const materialClasses = this.getClasses()
        const types = []

        // iterate through classes
        materialClasses.forEach((materialClass, index) => {

            // collect all type-related information
            const namePlain = this.getName(materialClass, false)
            const nameFormatted = this.getName(materialClass, true)
            const description = this.getDescription(materialClass)
            const generator = this.getGenerator(materialClass)

            // add the type to the list
            types.push({
                index,
                namePlain,
                nameFormatted,
                description,
                generator
            })
        })

        // return all types
        return types
    }


    //// NEW MATERIAL

    static newMaterialFromThree(mesh, materialThree, locked=false)
    {
        // texture material
        if (materialThree.map)
        {
            const material = TextureGenericMaterial.newInstanceFromThree(mesh, materialThree, locked)
            return material
        }

        // vertex color material
        else if (materialThree.vertexColors)
        {
            console.log('VERTEX COLOR')
            // TODO implement

            return
        }

        // single color
        else
        {
            const material = ColorSingleMaterial.newInstanceFromThree(mesh, materialThree, locked)
            return material
        }
    }

    static newMaterialFromAsset(mesh, asset)
    {
        console.log(asset)

        
        // check additional to find which data it is
        



    }







    // create material from component
    static newMaterialfromAPI(mesh, component)
    {
        switch(component.type)
        {
            case 'color':
                return ColorSingleMaterial.newInstance(mesh, component)
            default:
                console.error('method incomplete')
                return null
        }
    }

    static newMaterialFromInstance(mesh, materialInstance)
    {
        var newMaterial = materialInstance.clone(mesh)
        return newMaterial
    }

    static newMaterialFromClass(mesh, materialClass)
    {
        var newMaterial = materialClass.newDefaultInstance(mesh)
        return newMaterial
    }



    // initialize materials from JSON
    static newMaterialsfromJSON(json, jsonColor, mesh)
    {
        // important: support only for wavefront obj/mat

        // ensure json exists
        if (json == null) json = {}

        
        //// DEFAULT MATERIALS

        // list of materials
        const materials = []


        //// GENERAL

        // opacity
        const opacity = json.opacity || 1


        //// COLORS

        // parsing of array to JSON object
        const parseColor = (color) => {
            return {
                r: Math.floor(color[0] * 255),
                g: Math.floor(color[1] * 255),
                b: Math.floor(color[2] * 255)
            }
        }

        // ambientColor
        const ambientColor = json.ambientColor ? parseColor(json.ambientColor) : null

        // diffuseColor
        const diffuseColor = json.diffuseColor ? parseColor(json.diffuseColor) : null

        // specularColor
        const specularColor = json.specularColor ? parseColor(json.specularColor) : null

        // emissiveColor
        const emissiveColor = json.emissiveColor ? parseColor(json.emissiveColor) : null

        // specularIntensity
        const specularIntensity = json.specularIntensity ? json.specularIntensity : null

        // emissiveIntensity [NOT SUPPORTED]
        const emissiveIntensity = null

        // roughness [NOT SUPPORTED]
        const roughness = null

        // metalness [NOT SUPPORTED]
        const metalness = null


        //// VERTICES

        // vertex colors
        var vertexColors = jsonColor ? jsonColor : null


        //// TEXTURES

        //TODO implement


        //// DEFAULT MATERIAL


        // texture exists, so make it the default
        //TODO

        // vertex colors exist, so make it the default
        if (vertexColors && vertexColors.length > 0)
        {
            materials.push(
                new ColorVertexMaterial(
                    mesh,
                    'Default Material',
                    vertexColors,
                    true,
                    opacity
                )
            )
        }

        // add default color material
        else
        {
            materials.push(
                new ColorSingleMaterial(
                    mesh,
                    'Default Material',
                    {
                        ambientColor,
                        diffuseColor,
                        specularColor,
                        emissiveColor,
                        specularIntensity,
                        emissiveIntensity,
                        roughness,
                        metalness,
                    },
                    true,
                    opacity
                )
            )
        }

        return materials
    }

    // export material to json
    static toJSON(material)
    {
        // create empty json
        let json = {}

        // add material id
        json.id = material.id

        //TODO implement on basis of state

        // get three.js material
        const three = material.nonreactive.three

        // helper function to convert colors in arrays
        const color2Array = (color) => {
            return [color.r, color.g, color.b]
        }

        // set diffuse color
        json.diffuseColor = color2Array(three.color)

        // set emissive color
        json.emissiveColor = color2Array(three.emissive)

        // return serialized class as json
        return json
    }
}