/*
 * App Store
 */


//// IMPORTS
import { jwtDecode } from "jwt-decode"
import { useCookies } from '@vueuse/integrations/useCookies'
import { updateAccentColors } from '@/plugins/vuetify'
import router from '@/plugins/router'


//// CONSTANTS

// base
const COMPANY_NAME = 'GenerIO GmbH (i.G.)'

// deployment
const DEPLOYMENT_CHANNEL = import.meta.env.MODE
const DEPLOYMENT_DEBUGGING = DEPLOYMENT_CHANNEL === 'development'
const DEPLOYMENT_URL = import.meta.env.VITE_URL

// cookie
const COOKIE_EXPIRES_MAX = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000 * 10) // 10 years

// apps
// core apps
const APP_SHARE = 'share'
const APP_LOGIN = 'login'
const APP_APPS = 'apps'
const APP_ACCOUNT = 'account'
const APP_DEVELOPER = 'developer'
// preview apps
const APP_LIBRARY = 'library'
const APP_SKETCH = 'sketch'
// prototype apps
const APP_FLOW = 'flow'
const APP_MESH = 'mesh'
const APP_TEXTURE = 'texture'
// legacy apps
const APP_BOX = 'box'
const APP_MODEL = 'model'
const APP_TEXT = 'text'

// design
const DESIGN_MODE_LIGHT = 'light'
const DESIGN_MODE_DARK = 'dark'
const DESIGN_MODE_DEFAULT = DESIGN_MODE_LIGHT
const DESIGN_THEME_DEFAULT = 0
const DESIGN_THEME_MAXIMUM = 4

// loading
const LOADING_PROGRESS_INVISIBLE = -1


//// HELPER

// new permission
const newPermission = () => {
    return {
        library: false,
        sketch: false,
        flow: false,
        mesh: false,
        texture: false,
        box: false,
        text: false,
        model: false
    }
}


//// STORE
export const app = {
    namespaced: true,
    state: {

        // state
        initialized: false,

        // base
        version: '',
        appname: '',

        // token
        token: {
            raw: null,
            issued: null,
            expires: null,
        },
        
        // account
        username: '',
        developer: false,
        permission: newPermission(),
        group: null,
        permanent: true,

        // design
        design: {
            mode: DESIGN_MODE_DEFAULT,
            theme: DESIGN_THEME_DEFAULT,
            vuetify: null
        },

        // additional (contains experimental)
        additional: null,

        // navigation
        navigation: {
            visible: true,
            locked: true,
            hovered: false,
            width: 250
        },

        // loading
        loading: {
            active: false,
            progress: LOADING_PROGRESS_INVISIBLE, // 0..100 shows progress
            text: null
        },

        // toast
        toast: {
            active: false,
            text: '',
            warning: false
        }
    },
    mutations: {
        SET_INITIALIZED(state)
        {
            state.initialized = true
        },

        SET_VERSION(state, version) 
        {
            state.version = version
        },
        SET_APPNAME(state, appname) 
        {
            state.appname = appname
        },

        SET_TOKEN(state, token) 
        {
            // reset token
            state.token.raw = null
            state.token.issued = null
            state.token.expires = null

            // reset account
            state.username = ''
            state.developer = false
            state.permission = newPermission()
            state.group = null

            // only continue if token exists
            if (token)
            {
                try {
                    // decode token
                    const decodedToken = jwtDecode(token)

                    // update token 
                    state.token.raw = token
                    state.token.issued = new Date(decodedToken.iat * 1000)
                    state.token.expires = new Date(decodedToken.exp * 1000)

                    // update account
                    state.username = decodedToken.sub
                    state.developer = decodedToken.developer
                    state.permission = newPermission()
                    state.group = decodedToken.group

                    // update permission state
                    for (let permission of decodedToken.permission) 
                    {
                        const permissionKey = permission.toLowerCase()
                        state.permission[permissionKey] = true
                    }

                    // log developer permissions
                    if (state.developer)
                    {
                        console.warn("[INFO] User has developer permissions.")
                        console.log(decodedToken)
                    }
                }
                catch (error) 
                {
                    // log error
                    console.error('[ERROR] Your token is invalid.')
                }
            }
            
            // update cookie 
            const cookies = useCookies(['token'])
            if (state.token !== null && state.permanent) 
            {
                cookies.set('token', token, { expires: state.token.expires })
            }
            else 
            {
                cookies.set('token', null)
            }
        },
        SET_PERMANENT(state, permanent)
        {
            state.permanent = permanent            
        },

        SET_DESIGN_MODE(state, mode)
        {
            // mode needs to be a string
            if (typeof mode !== 'string') {
                return;
            }

            // set mode
            state.design.mode = mode.toLowerCase()

            // update cookie 
            const cookies = useCookies(['mode'])
            cookies.set('mode', mode, { expires: COOKIE_EXPIRES_MAX })
        },
        SET_DESIGN_THEME(state, theme) 
        {
            state.design.theme = theme

            // update cookie 
            const cookies = useCookies(['theme'])
            cookies.set('theme', theme, { expires: COOKIE_EXPIRES_MAX })

            // update vuetify
            updateAccentColors(theme)
        },
        SET_DESIGN_VUETIFY(state, vuetify)
        {
            state.design.vuetify = vuetify
        },

        SET_ADDITIONAL(state, additional) 
        {
            state.additional = additional
        },

        RESET_NAVIGATION(state)
        {
            state.navigation = {
                visible: true,
                locked: true,
                hovered: false,
                width: 250
            }
        },
        SET_NAVIGATION_VISIBILITY(state, visible)
        {
            state.navigation.visible = visible
        },
        SET_NAVIGATION_LOCKED(state, locked)
        {
            // avoid null or undefined for cookie
            if (locked === undefined || locked === null) return
            state.navigation.locked = locked

            // update cookie 
            const cookies = useCookies(['navigation'])
            cookies.set('navigation', locked, { expires: COOKIE_EXPIRES_MAX })
        },
        SET_NAVIGATION_HOVERED(state, hovered)
        {
            state.navigation.hovered = hovered
        },
        SET_NAVIGATION_WIDTH(state, width)
        {
            state.navigation.width = width
        },

        RESET_LOADING(state)
        {
            state.loading.active = false
            state.loading.progress = LOADING_PROGRESS_INVISIBLE
            state.loading.text = null
        },
        SET_LOADING_ACTIVE(state, active) 
        {
            state.loading.active = active
        },
        SET_LOADING_PROGRESS(state, progress)
        {
            state.loading.progress = progress
        },
        SET_LOADING_TEXT(state, text)
        {
            state.loading.text = text
        },

        SET_TOAST_ACTIVE(state, active) {
            state.toast.active = active
        },
        SET_TOAST_TEXT(state, text) {
            state.toast.text = text
        },
        SET_TOAST_WARNING(state, warning) {
            state.toast.warning = warning
        }
    },
    getters: {
        keyCompanyName: () => COMPANY_NAME,

        keyDeploymentChannel: () => DEPLOYMENT_CHANNEL,
        keyDeploymentDebugging: () => DEPLOYMENT_DEBUGGING,
        keyDeploymentUrl: () => DEPLOYMENT_URL,

        keyCookieExpiresMax: () => COOKIE_EXPIRES_MAX,

        keyAppShare: () => APP_SHARE,
        keyAppLogin: () => APP_LOGIN,
        keyAppApps: () => APP_APPS,
        keyAppAccount: () => APP_ACCOUNT,
        keyAppDeveloper: () => APP_DEVELOPER,
        keyAppLibrary: () => APP_LIBRARY,
        keyAppSketch: () => APP_SKETCH,
        keyAppFlow: () => APP_FLOW,
        keyAppMesh: () => APP_MESH,
        keyAppTexture: () => APP_TEXTURE,
        keyAppBox: () => APP_BOX,
        keyAppModel: () => APP_MODEL,
        keyAppText: () => APP_TEXT,

        keyDesignModeDefault: () => DESIGN_MODE_DEFAULT,
        keyDesignModeLight: () => DESIGN_MODE_LIGHT,
        keyDesignModeDark: () => DESIGN_MODE_DARK,
        keyDesignThemeDefault: () => DESIGN_THEME_DEFAULT,

        hasAnyApp: (state, getters) => getters.hasApps || getters.hasPrototypes || getters.hasLegacy,
        hasApps: state => 
            state.permission.library || 
            state.permission.sketch,
        hasPrototypes: state => 
            state.permission.flow ||
            state.permission.mesh || 
            state.permission.texture,
        hasLegacy: state => 
            state.permission.box ||
            state.permission.text || 
            state.permission.model,
        hasAppLibrary: state => state.permission.library,
        hasAppSketch: state => state.permission.sketch,
        hasAppFlow: state => state.permission.flow,
        hasAppMesh: (state, getters) => state.permission.mesh && getters.isExperimental,
        hasAppTexture: (state, getters) => state.permission.texture && getters.isExperimental,
        hasAppBox: state => state.permission.box,
        hasAppModel: state => state.permission.model,
        hasAppText: state => state.permission.text,
        hasGroup: state => state.group !== null,
        hasLoadingProgress: state => state.loading.progress > LOADING_PROGRESS_INVISIBLE,
        hasLoadingText: state => state.loading.text !== null,
        
        isDebugging: () => DEPLOYMENT_DEBUGGING,
        isInitialized: state => state.initialized,
        isExperimental: state => {
            if (state.developer) 
            {
                const experimental = state.additional?.appExperimental
                if (experimental) return experimental
            }
            return false
        },
        isAuthenticated: state => state.token.raw != null,
        isPermanent: state => state.permanent,
        isDeveloper: state => state.developer,
        isAppLibraryActive: state => state.appname === APP_LIBRARY,
        isAppSketchActive: state => state.appname === APP_SKETCH,
        isAppMeshActive: state => state.appname === APP_MESH,
        isAppTextureActive: state => state.appname === APP_TEXTURE,
        isAppBoxActive: state => state.appname === APP_BOX,
        isAppModelActive: state => state.appname === APP_MODEL,
        isAppTextActive: state => state.appname === APP_TEXT,
        isDesignModeLight: state => state.design.mode === DESIGN_MODE_LIGHT,
        isDesignModeDark: state => state.design.mode === DESIGN_MODE_DARK,
        isNavigationVisible: state => state.navigation.visible,
        isNavigationLocked: state => state.navigation.locked,
        isNavigationHovered: state => state.navigation.hovered,
        isLoadingActive: state => state.loading.active,
        isToastActive: state => state.toast.active,
        isToastWarning: state => state.toast.warning,

        getChannel: () => DEPLOYMENT_CHANNEL,
        getUrl: () => DEPLOYMENT_URL,
        getVersion: state => state.version, 
        getAppname: state => state.appname,
        getFormattedAppname: () => (app) => {
            switch(app) 
            {
                case APP_SHARE:
                    return "Share (Core)"
                case APP_LOGIN:
                    return "Login (Core)"
                case APP_APPS:
                    return 'Apps (Core)'
                case APP_ACCOUNT:
                    return "Account (Core)"
                case APP_DEVELOPER:
                    return 'Developer (Core)'
                case APP_LIBRARY:
                    return 'Library (Preview)'
                case APP_SKETCH:
                    return 'Sketchurizer (Preview)'
                case APP_FLOW:
                    return 'Flow (Prototype)'
                case APP_MESH:
                    return 'Meshurizer (Prototype)'
                case APP_TEXTURE:
                    return 'Texturizer (Prototype)'
                case APP_BOX:
                    return 'Boxurizer (Legacy)'
                case APP_MODEL:
                    return 'Modulator (Legacy)'
                case APP_TEXT:
                    return 'Textulator (Legacy)'
            }
        },
        getTokenRaw: state => state.token.raw,
        getTokenIssued: state => state.token.issued,
        getTokenExpires: state => state.token.expires,
        getUsername: state => state.username,
        getUsernameFormatted: state => {
            return state.username
                .split('@')[0]
                .replace(/[^a-zA-Z0-9 ]/g, ' ')
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
        },
        getGroup: state => state.group,
        getAppLibraryIcon: () => 'fa-solid fa-book',
        getAppSketchIcon: () => 'fa-solid fa-pencil',
        getPrototypeIcon: () => 'fa-solid fa-flask',
        getLegacyIcon: () => 'fa-solid fa-box-archive',
        getDesignMode: state => state.design.mode,
        getDesignTheme: state => state.design.theme,
        getDesignVuetify: state => state.design.vuetify,
        getAdditional: state => state.additional,
        getNavigationWidth: state => state.navigation.width,
        getLoadingProgress: state => {
            if(isNaN(state.loading.progress)) return 0
            if(state.loading.progress == LOADING_PROGRESS_INVISIBLE) return LOADING_PROGRESS_INVISIBLE
            return Math.floor(state.loading.progress / 0.1) * 0.1 // in steps of 10%
        },
        getLoadingText: state => state.loading.text,
        getToastText: state => state.toast.text,
        getUniqueIdentifier: () => {
            const now = new Date()
          
            const year = now.getFullYear().toString().slice(-2)
            const month = (now.getMonth() + 1).toString().padStart(2, '0')
            const day = now.getDate().toString().padStart(2, '0')
            const hours = now.getHours().toString().padStart(2, '0')
            const minutes = now.getMinutes().toString().padStart(2, '0')
            const seconds = now.getSeconds().toString().padStart(2, '0')
            const milliseconds = now.getMilliseconds().toString().padStart(3, '0')
          
            const uniqueIdentifier = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`

            return Number(uniqueIdentifier)
        },
          
          

        newAdditionalForContext: (state, getters, rootState, rootGetters) => {
            let additional = null

            if (getters.isAppLibraryActive)
            {
                additional = {
                    window: rootGetters['library/getMode']
                }
            }
            else if (getters.isAppSketchActive)
            {
                additional = {
                    window: rootGetters['sketch/getWindow'],
                    flow: rootGetters['sketch/getFlow']
                }
            }
            else if (getters.isAppBoxActive)
            {
                additional = {
                    window: rootGetters['box/getActiveWindow']
                }
            }

            return additional
        },
    },
    actions: {
        async initializeApp({ commit, dispatch, getters }, version)
        {
            // set app version
            commit('SET_VERSION', version)

            // get token
            const cookie_token = useCookies(['token']).get('token')
            if (typeof cookie_token !== 'undefined')
                commit('SET_TOKEN', cookie_token)

            // get navigation
            const cookie_navigation = useCookies(['navigation']).get('navigation')
            if (typeof cookie_navigation !== 'undefined')
                commit('SET_NAVIGATION_LOCKED', cookie_navigation)

            // update design
            await dispatch('updateDesign')

            // check if user is authenticated
            if (getters.isAuthenticated) 
            {
                // get user additional
                await dispatch('fetchAdditional')
            }

            // set initialization to complete
            commit('SET_INITIALIZED')
        },

        setVersion({ commit }, version)
        {
            commit('SET_VERSION', version)
        },
        setAppname({ commit }, appname)
        {
            commit('SET_APPNAME', appname)
        },

        setToken({ dispatch, commit }, token) {
            commit('SET_TOKEN', token)
        },

        setPermanent({ commit }, permanent)
        {
            commit('SET_PERMANENT', permanent)
        },
        setExperimental({ getters, dispatch }, experimental) 
        {
            // get additional
            const additional = getters.getAdditional

            // set experimental
            additional.appExperimental = experimental

            // change additional 
            dispatch('changeAdditional', additional)
        },

        setDesignVuetify({ commit }, vuetify)
        {
            commit('SET_DESIGN_VUETIFY', vuetify)
        },

        setNavigationVisiblity({ commit }, visible)
        {
            commit('SET_NAVIGATION_VISIBILITY', visible)
        },
        setNavigationLocked({ commit }, locked)
        {
            commit('SET_NAVIGATION_LOCKED', locked)
        },
        setNavigationHovered({ commit }, hovered)
        {
            commit('SET_NAVIGATION_HOVERED', hovered)
        },
        setNavigationWidth({ commit }, width)
        {
            commit('SET_NAVIGATION_WIDTH', width)
        },

        activateLoading({ commit })
        {
            commit('SET_LOADING_ACTIVE', true)
        },
        deactivateLoading({ commit })
        {
            commit('RESET_LOADING')
        },
        setLoadingProgress({ commit }, progress) 
        {
            commit('SET_LOADING_PROGRESS', progress)
        },
        setLoadingText({ commit }, text)
        {
            commit('SET_LOADING_TEXT', text)
        },
        
        showToast({ commit }, { text, warning = false }) {
            commit('SET_TOAST_TEXT', text)
            commit('SET_TOAST_WARNING', warning)
            commit('SET_TOAST_ACTIVE', true)
        },
        setToastActive({ commit }, active) {
            commit('SET_TOAST_ACTIVE', active)
        },
        setToastText({ commit }, text) {
            commit('SET_TOAST_ACTIVE', text)
        },
        setToastWarning({ commit }, warning) {
            commit('SET_TOAST_WARNING', warning)
        },  

        //// USERS

        // register user
        async registerUser({ dispatch, getters }, { 
            email
        }) 
        {
            // call route to register user
            return dispatch('api/usersRegisterPost', { 
                email, 
                mode: getters.getDesignMode,
                theme: getters.getDesignTheme
            }, { root: true })
        },

        // activate user
        async activateUser({ dispatch, commit }, { 
            token
        })
        {
            try
            {
                // call route to activate user
                const response = await dispatch('api/usersActivatePut', { 
                    token
                }, { root: true })

                // set token
                commit('SET_TOKEN', response.access_token)

                // initialize the user
                dispatch('api/initializeUser', null, { root: true })

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // deactivate user
        async deactivateUser({ dispatch, commit })
        {
            try
            {
                // call route to activate user
                const response = await dispatch('api/usersDectivatePut', null, { root: true })

                // set token
                commit('SET_TOKEN', null)

                return response
            }
            catch(error)
            {
                throw error
            }
        },
        
        // login user
        async loginUser({ dispatch, commit }, { 
            email, 
            password
        })
        {
            try
            {
                // call route to activate user
                const response = await dispatch('api/usersTokenPost', { 
                    email,
                    password
                }, { root: true })

                // set token
                commit('SET_TOKEN', response.access_token)

                // initialize user
                dispatch('api/initializeUser', null, { root: true })

                // update user design
                dispatch('updateDesign')

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // logout
        async logoutUser({ commit }, expired = false)
        {
            // return a promise
            return new Promise(async (resolve, reject) => {

                // remove token
                commit('SET_TOKEN', null)

                // navigate to login
                if (expired) {
                    router.push({ path: '/login', query: { expired } })
                } else {
                    router.push('/login')
                }

                resolve()
            })
        },

        // password forgot
        async passwordForgot({ dispatch }, { 
            email
        }) 
        {
            // call route to email password
            return dispatch('api/usersPasswordForgotPost', { 
                email 
            }, { root: true })
        },

        // password reset
        async passwordReset({ dispatch }, { 
            token, 
            password
        })
        {
            // call route to change password
            return dispatch('api/usersPasswordChangePut', {
                token,
                password
            }, { root: true })
        },

        // password change
        async passwordChange({ dispatch }, { 
            password
        })
        {
            // call route to change password
            return dispatch('api/usersPasswordChangePut', {
                password
            }, { root: true })
        },

        // fetch statistics
        async fetchStatistics({ dispatch })
        {
            try
            {
                // call route to fetch user statistics
                const response = await dispatch('api/usersStatisticsGet', null, { root: true })

                // extract create date
                const date = new Date(response.create_date)
                const day = String(date.getUTCDate()).padStart(2, '0')
                const month = String(date.getUTCMonth() + 1).padStart(2, '0')
                const year = date.getUTCFullYear()
                const formattedDate = `${day}.${month}.${year}`

                console.log(response)

                // generation statistics
                const models = response.statistics.models
                const previews = response.statistics.previews
                const images = response.statistics.images
                const sketches = response.statistics.sketches

                // new modified response
                const modifiedResponse = {
                    since: formattedDate,
                    models,
                    previews,
                    images,
                    sketches
                }

                return modifiedResponse
            }
            catch (error)
            {
                throw error
            }
        },

        // update design
        async updateDesign({ dispatch, commit, getters }) 
        {
            const updateDesignFromCookies = () => {

                // handle mode and theme based on cookies
                const cookies = useCookies(['mode', 'theme'])
        

                //// MODE
        
                // variable for mode
                let mode = DESIGN_MODE_DARK
        
                // get cookie
                const cookie_mode = cookies.get('mode')
        
                // if cookie exists and is valid, use it
                if (typeof cookie_mode !== 'undefined' && 
                    (cookie_mode === DESIGN_MODE_LIGHT || cookie_mode === DESIGN_MODE_DARK)) 
                {
                    mode = cookie_mode
                }
                // otherwise, use system default 
                else 
                {
                    const system_dark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
                    mode = system_dark ? DESIGN_MODE_DARK : DESIGN_MODE_LIGHT
                }
        
                // set mode
                commit('SET_DESIGN_MODE', mode)
        

                //// THEME
        
                // variable for theme
                let theme = 0
        
                // get cookie
                const cookie_theme = cookies.get('theme')
        
                // if cookie exists and is valid, use it
                if (typeof cookie_theme !== 'undefined' && 
                    cookie_theme >= 0 && cookie_theme < DESIGN_THEME_MAXIMUM) 
                {
                    theme = cookie_theme
                }

                // set theme
                commit('SET_DESIGN_THEME', theme)

                // return a resolved promise
                return { mode, theme }
            }

            // cookies as fallback design
            updateDesignFromCookies()

            // check if user is authenticated
            if (getters.isAuthenticated) 
            {
                try 
                {
                    // call route to 
                    const response = await dispatch('api/usersDesignGet', null, { root: true })
        
                    // commit mode and theme based on the response
                    commit('SET_DESIGN_MODE', response.mode)
                    commit('SET_DESIGN_THEME', response.theme)

                    return response
                } 
                catch (error) 
                {
                    // invalidate the token
                    commit('SET_TOKEN', null)

                    // propagate the error
                    throw error
                }
            } 
        },

        // change design
        async changeDesign({ dispatch, commit }, {
            mode,
            theme
        })
        {
            // directly set mode and theme
            commit('SET_DESIGN_MODE', mode)
            commit('SET_DESIGN_THEME', theme)

            // call route to update design
            return dispatch('api/usersDesignPut', {
                mode,
                theme
            }, { root: true })
        },

        // fetch newsletter subscription
        async fetchNewsletterSubscription({ dispatch })
        {
            // call route to fetch newsletter subscription
            return dispatch('api/usersNewsletterGet', null, { root: true })
        },

        // change newsletter subscription
        async changeNewsletterSubscription({ dispatch }, { 
            subscribed
        })
        {
            // call route to change newsletter subscription
            return dispatch('api/usersNewsletterPost', {
                subscribed
            }, { root: true })
        },

        // fetch additional 
        async fetchAdditional({ dispatch, commit })
        {
            try
            {
                // call route to get users additional
                const response = await dispatch('api/usersAdditionalGet', null, { root: true })

                // set additional
                commit('SET_ADDITIONAL', response) 

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // change additional
        async changeAdditional({ dispatch, commit }, additional)
        {
            try
            {
                // call route to set users additional
                const response = await dispatch('api/usersAdditionalPut', 
                    additional, { root: true })

                // set additional
                commit('SET_ADDITIONAL', additional) 

                return response
            }
            catch(error)
            {
                throw error
            }
        },


        //// LOGGING

        // log event
        async logEvent({ getters, dispatch }, {
            name, 
            description
        })
        {
            // do not track events for unauthenticated users
            if (!getters.isAuthenticated) return

            // call route to log event
            return dispatch('api/loggingEventPost', {
                name,
                description,
                additional: getters.newAdditionalForContext
            }, { root: true })
        },

        // log bug
        async logBug({ getters, dispatch }, {
            name, 
            description
        })
        {
            // do not track bugs for unauthenticated users
            if (!getters.isAuthenticated) return

            // call route to log bug
            return dispatch('api/loggingBugPost', {
                name,
                description,
                additional: getters.newAdditionalForContext
            }, { root: true })
        },

        // log feedback
        async logFeedback({ getters, dispatch, rootGetters }, {
            topic,
            feedback
        })
        {
            // call route to log feedback
            return dispatch('api/loggingFeedbackPost', {
                topic,
                feedback,
                additional: getters.newAdditionalForContext
            }, { root: true })
        },

        // fetch vote
        async fetchVote({ dispatch }, {
            topic
        })
        {
            // call route to fetch vote
            return dispatch('api/loggingVoteGet', {
                topic
            }, { root: true })
        },

        // update vote
        async updateVote({ dispatch }, {
            topic, 
            vote
        })
        {
            // call route to update vote
            return dispatch('api/loggingVotePut', {
                topic,
                vote
            }, { root: true })  
        },
    }
}