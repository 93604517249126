// vuex.js store for Sketchurizer


//// CONSTANTS

// flow
const FLOW_EMPTY = null
const FLOW_SKETCH = 'sketch'
const FLOW_IMAGE = 'image'
const FLOW_TEXT = 'text'

// window
const WINDOW_SKETCH = 'sketch'
const WINDOW_IMAGE = 'image'
const WINDOW_PREVIEW = 'preview'
const WINDOW_MODEL = 'model'

// asset category
const ASSET_CATEGORY_INPUT = 'input'
const ASSET_CATEGORY_PREVIEW = 'preview'
const ASSET_CATEGORY_MODEL = 'model'

// input mode
const INPUT_MODE_DRAW = 'draw'
const INPUT_MODE_CROP = 'crop'
const INPUT_MODE_DEFAULT = INPUT_MODE_DRAW

// prompt length
const PROMPT_LENGTH_MAX = 1024

// balance
const BALANCE_DEFAULT = 0.5

// viewer animation
const VIEWER_ANIMATION_DEFAULT = false

// viewer mode
const VIEWER_MODE_TEXTURE = 'texture'
const VIEWER_MODE_SOLID = 'solid'
const VIEWER_MODE_WIREFRAME = 'wireframe'
const VIEWER_MODE_DEFAULT = VIEWER_MODE_TEXTURE

// viewer file
const VIEWER_FILE_DEFAULT = 'default'
const VIEWER_FILE_MODIFIED = 'modified'


//// HELPER

// create input state
const createInputState = () => {
    return {
        mode: INPUT_MODE_DEFAULT,
        strokes: 0,
        raw: null,
        asset: null,
        assetOutdated: false
    }
}

// create state
const createState = () => {
    return {
        flow: null,
        count: null,
        step: 1,

        input: createInputState(),
        previewAssets: null,
        modelAsset: null,

        promptPositive: '',
        promptNegative: '',
        promptAdvanced: false,
        balance: BALANCE_DEFAULT,

        viewer: {
            animation: VIEWER_ANIMATION_DEFAULT,
            mode: VIEWER_MODE_DEFAULT,
            file: VIEWER_FILE_DEFAULT
        }
    }
}


//// VUEX STORE
export const sketch = {
    namespaced: true,
    state: createState(),
    mutations: {
        RESET(state) {
            Object.assign(state, createState())
        },
        RESET_INPUT(state) {
            state.input = createInputState()
        },

        SET_FLOW(state, flow) {
            state.flow = flow
        },
        SET_COUNT(state, count) {
            state.count = count
        },
        SET_STEP(state, step) {
            state.step = step
        },

        SET_INPUT_MODE(state, mode) {
            state.input.mode = mode
        },
        SET_INPUT_STROKES(state, strokes) {
            state.input.strokes = strokes
        },
        SET_INPUT_RAW(state, raw) {
            state.input.raw = raw
            state.input.assetOutdated = true
        },
        SET_INPUT_ASSET(state, asset) {
            state.input.raw = asset.files['default']?.data 
            state.input.asset = asset
            state.input.assetOutdated = false
        },
        SET_PREVIEW_ASSETS(state, assets) {
            state.previewAssets = assets
        },
        SET_MODEL_ASSET(state, asset) {
            state.modelAsset = asset
        },

        SET_PROMPT_POSITIVE(state, prompt) {
            state.promptPositive = prompt
        },
        SET_PROMPT_NEGATIVE(state, prompt) {
            state.promptNegative = prompt
        },
        SET_PROMPT_ADVANCED(state, advanced) {
            state.promptAdvanced = advanced
        },
        SET_BALANCE(state, balance) {
            state.balance = balance
        },

        SET_VIEWER_ANIMATION(state, animation) {
            state.viewer.animation = animation
        },
        SET_VIEWER_MODE(state, mode) {
            state.viewer.mode = mode
        },
        SET_VIEWER_FILE(state, file) {
            state.viewer.file = file
        }
    },
    getters: {

        // keys

        keyFlowEmpty: () => FLOW_EMPTY,
        keyFlowSketch: () => FLOW_SKETCH,
        keyFlowImage: () => FLOW_IMAGE,
        keyFlowText: () => FLOW_TEXT,

        keyWindowSketch: () => WINDOW_SKETCH,
        keyWindowImage: () => WINDOW_IMAGE,
        keyWindowPreview: () => WINDOW_PREVIEW,
        keyWindowModel: () => WINDOW_MODEL,

        keyInputModeDraw: () => INPUT_MODE_DRAW,
        keyInputModeCrop: () => INPUT_MODE_CROP,

        keyAssetCategoryInput: () => ASSET_CATEGORY_INPUT,
        keyAssetCategoryPreview: () => ASSET_CATEGORY_PREVIEW,
        keyAssetCategoryModel: () => ASSET_CATEGORY_MODEL,

        keyPromptLengthMax: () => PROMPT_LENGTH_MAX,
        keyBalanceDefault: () => BALANCE_DEFAULT,

        keyViewerModeDefault: () => VIEWER_MODE_DEFAULT,
        keyViewerModeTexture: () => VIEWER_MODE_TEXTURE,
        keyViewerModeSolid: () => VIEWER_MODE_SOLID,
        keyViewerModeWireframe: () => VIEWER_MODE_WIREFRAME,

        keyViewerAnimationDefault: () => VIEWER_ANIMATION_DEFAULT,
        keyViewerFileDefault: () => VIEWER_FILE_DEFAULT,
        keyViewerFileModified: () => VIEWER_FILE_MODIFIED,

        // has

        hasFlow: state => state.flow !== null,
        hasInput: state => state.flow !== FLOW_TEXT,
        hasInputSketch: state => state.flow === FLOW_SKETCH,
        hasInputImage: state => state.flow === FLOW_IMAGE,

        hasChanges: state => 
            state.input.raw !== null ||
            state.input.asset !== null ||
            state.previewAssets !== null ||
            state.modelAsset !== null
        ,
        hasInputRaw: state => state.input.raw !== null,
        hasInputStrokes: state => state.input.strokes > 0,
        hasInputAsset: state => state.input.asset !== null,
        hasPreviewAssets: state => state.previewAssets !== null && state.previewAssets.length > 0,
        hasModelAsset: state => state.modelAsset !== null,
        hasModelModified: state => {
            if (state.modelAsset && VIEWER_FILE_MODIFIED in state.modelAsset.files) return true
            return false
        },

        hasPromptPositive: state => state.promptPositive !== null && state.promptPositive !== undefined && state.promptPositive.trim() !== '',
        hasPromptNegative: state => state.promptNegative !== null && state.promptNegative !== undefined && state.promptNegative.trim() !== '',
        hasAdditionalCustom: (state, getters, rootState, rootGetters) => {
            const additional = rootGetters['app/getAdditional']
            const positive = additional?.sketchCustomPositive
            if (positive && positive.length > 0) return true
            const negative = additional?.sketchCustomNegative
            if (negative && negative.length > 0) return true
            return false
        },

        // is

        isFlowSketch: state => state.flow === FLOW_SKETCH,
        isFlowImage: state => state.flow === FLOW_IMAGE,
        isFlowText: state => state.flow === FLOW_TEXT,

        isStepInput: (state, getters) => state.step === getters.getStepInput,
        isStepPreview: (state, getters) => state.step === getters.getStepPreview,
        isStepModel: (state, getters) => state.step === getters.getStepModel,

        isInputModeDefault: state => state.input.mode === INPUT_MODE_DEFAULT,
        isInputModeDraw: state => state.input.mode === INPUT_MODE_DRAW,
        isInputModeCrop: state => state.input.mode === INPUT_MODE_CROP,
        
        isPromptPositiveTooLong: state => state.promptPositive ? state.promptPositive.length > PROMPT_LENGTH_MAX : false,
        isPromptNegativeTooLong: state => state.promptNegative ? state.promptNegative.length > PROMPT_LENGTH_MAX : false,
        isPromptAdvanced: state => state.promptAdvanced,

        isInputEmpty: state => {
            return state.input.raw === null || state.input.raw === undefined
        },
        isInputAssetOutdated: state => state.input.assetOutdated,

        isViewerAnimated: state => state.viewer.animation,
        isViewerModeDefault: state => state.viewer.mode === VIEWER_MODE_DEFAULT,
        isViewerModeTexture: state => state.viewer.mode === VIEWER_MODE_TEXTURE,
        isViewerModeSolid: state => state.viewer.mode === VIEWER_MODE_SOLID,
        isViewerModeWireframe: state => state.viewer.mode === VIEWER_MODE_WIREFRAME,

        isAssetInput: () => (asset) => asset.additional?.category === ASSET_CATEGORY_INPUT,
        isAssetInputSketch: () => (asset) => asset.additional?.category === ASSET_CATEGORY_INPUT && asset.additional?.flow === FLOW_SKETCH,
        isAssetInputImage: () => (asset) => asset.additional?.category === ASSET_CATEGORY_INPUT && asset.additional?.flow === FLOW_IMAGE,
        isAssetPreview: () => (asset) => asset.additional?.category === ASSET_CATEGORY_PREVIEW,
        isAssetModel: () => (asset) => asset.additional?.category === ASSET_CATEGORY_MODEL,

        isAdditionalGenerationPersistent: (state, getters, rootState, rootGetters) => {
            // get additional
            const additional = rootGetters['app/getAdditional']
            
            // get flag persistent
            const persistent = additional?.sketchGenerationPersistent

            // if flag exists
            if (persistent) return persistent

            return false
        },

        // get

        getFlow: state => state.flow,
        getAllFlows: () => [ FLOW_SKETCH, FLOW_IMAGE, FLOW_TEXT ],
        getCount: state => state.count,
        getStep: state => state.step,
        getSteps: (state, getters) => getters.hasInput ? [ getters.getFlow, WINDOW_PREVIEW, WINDOW_MODEL ] : [ WINDOW_PREVIEW, WINDOW_MODEL ],
        getStepInput: (state, getters) => getters.hasInput ? 1 : 0,
        getStepPreview: (state, getters) => getters.hasInput ? 2 : 1,
        getStepModel: (state, getters) => getters.hasInput ? 3 : 2,
        getWindow: (state, getters) => {
            if (getters.isStepInput) {
                if (getters.isFlowSketch) return WINDOW_SKETCH
                if (getters.isFlowImage) return WINDOW_IMAGE
            }
            if (getters.isStepPreview) return WINDOW_PREVIEW
            if (getters.isStepModel) return WINDOW_MODEL
            return null
        },

        getInputRaw: (state) => state.input.raw,
        getInputMode: (state) => state.input.mode,
        getInputStrokes: (state) => state.input.strokes,
        getInputAsset: (state) => state.input.asset,
        getPreviewAssets: (state) => state.previewAssets,
        getModelAsset: (state) => state.modelAsset,

        getAllAssets: (state, getters, rootState, rootGetters) => {
            return rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppSketch']
            )
        },
        getAllBlueprints: (state, getters, rootState, rootGetters) => {
            let assets = getters.getAllAssets
            assets = assets.filter(asset => 
                asset.generated === true && asset.additional?.blueprint
            )
            assets.sort((a, b) => b.created - a.created)
            return assets
        },

        getAssetCategory: () => (asset) => {
            if (asset === null || asset === undefined) return null
            const category = asset.additional?.category
            if (category === ASSET_CATEGORY_INPUT) return ASSET_CATEGORY_INPUT
            if (category === ASSET_CATEGORY_PREVIEW) return ASSET_CATEGORY_PREVIEW
            if (category === ASSET_CATEGORY_MODEL) return ASSET_CATEGORY_MODEL
            return null
        },
        getAssetCategoryDetailed: (state, getters) => (asset) => {
            const category = getters.getAssetCategory(asset)
            if (category === ASSET_CATEGORY_INPUT && getters.isAssetInputSketch(asset)) return 'Input: Sketch'
            if (category === ASSET_CATEGORY_INPUT && getters.isAssetInputImage(asset)) return 'Input: Image'
            return category
        },
        getAssetTitle: (state, getters) => (asset) => {
            if (asset == null) return ''
            if (asset.additional == null) return getters.getAssetCategoryDetailed(asset) 
            const prompt = asset.additional.positive
            if (prompt == null || prompt == undefined || prompt == '') return getters.getAssetCategoryDetailed(asset)
            return prompt
        },

        getPromptPositive: state => state.promptPositive,
        getPromptPositiveOptimized: state => 
            state.promptPositive ? state.promptPositive.trim().toLowerCase().slice(0, PROMPT_LENGTH_MAX) : '',
        getPromptPositiveAugmented: (state, getters) =>
            getters.getPromptPositiveOptimized + ', ' + getters.getAdditionalCustomPositive,
        getPromptNegative: state => state.promptNegative,
        getPromptNegativeOptimized: state => 
            state.promptNegative ? state.promptNegative.trim().toLowerCase().slice(0, PROMPT_LENGTH_MAX) : '',
        getPromptNegativeAugmented: (state, getters) =>
            getters.getPromptNegativeOptimized + ', ' + getters.getAdditionalCustomNegative,
        getBalance: state => state.balance,

        getViewerMode: state => state.viewer.mode,
        getViewerFile: (state, getters) => {
            if (state.viewer.file === VIEWER_FILE_MODIFIED)
            {
                if (getters.hasModelModified) 
                    return VIEWER_FILE_MODIFIED
            }
            return VIEWER_FILE_DEFAULT
        },

        getAdditionalCustomPositive: (state, getters, rootState, rootGetters) => {
            const additional = rootGetters['app/getAdditional']
            const prompt = additional?.sketchCustomPositive
            if (prompt) return prompt
            return ''
        },
        getAdditionalCustomNegative: (state, getters, rootState, rootGetters) => {
            const additional = rootGetters['app/getAdditional']
            const prompt = additional?.sketchCustomNegative
            if (prompt) return prompt
            return ''
        },
        getAdditionalGenerationQuality: (state, getters, rootState, rootGetters) => {
            const additional = rootGetters['app/getAdditional']
            const quality = additional?.sketchGenerationQuality
            if (quality) return quality
            return rootGetters['api/keyModelGenerationQualityDefault']
        },
        getAdditionalExportFormat: (state, getters, rootState, rootGetters) => {
            const additional = rootGetters['app/getAdditional']
            const format = additional?.sketchExportFormat
            if (format) return format
            return rootGetters['api/keyModelExportFormatDefault']
        }
    },
    actions: {
        resetSketch({ commit }) 
        {
            commit('RESET')
        },
        resetSketchInput({ commit })
        {
            commit('RESET_INPUT')
        },

        newFlow({ commit, rootGetters }) {
            const count = rootGetters['app/getUniqueIdentifier']
            commit('SET_COUNT', count)
        },
        setFlow({ commit, dispatch }, flow) {
            dispatch('newFlow')
            commit('SET_FLOW', flow)
        },
        setFlowToEmpty({ commit, dispatch }) {
            dispatch('newFlow')
            commit('SET_FLOW', FLOW_EMPTY)
        },
        setFlowToSketch({ commit, dispatch }) {
            dispatch('newFlow')
            commit('SET_FLOW', FLOW_SKETCH)
        },
        setFlowToImage({ commit, dispatch }) {
            dispatch('newFlow')
            commit('SET_FLOW', FLOW_IMAGE)
        },
        setFlowToText({ commit, dispatch }) {
            dispatch('newFlow')
            commit('SET_FLOW', FLOW_TEXT)
        },
        setFlowFromAsset({ getters, commit, dispatch }, asset) 
        {
            const flow = asset.additional?.flow
            if (getters.getAllFlows.includes(flow)) 
            {
                commit('SET_FLOW', flow)

                const count = asset.additional?.count
                if (count) commit('SET_COUNT', count)
                else dispatch('newFlow')
            }
            else 
            {
                throw new Error("Please provide a valid flow.")
            }
        },

        setStep({ commit, getters, dispatch }, step) {
            if (step === getters.getStepPreview) dispatch('setInputAssetFromRaw')
            commit('SET_STEP', step)
        },
        setStepToInput({ commit, getters }) {
            commit('SET_STEP', getters.getStepInput)
        },
        setStepToPreview({ dispatch, commit, getters }) {
            dispatch('setInputAssetFromRaw')
            commit('SET_STEP', getters.getStepPreview)
        },
        setStepToModel({ commit, getters }) {
            commit('SET_STEP', getters.getStepModel)
        },

        setInputMode({ commit }, mode) {
            commit('SET_INPUT_MODE', mode)
        },
        setInputModeToDefault({ commit }) {
            commit('SET_INPUT_MODE', INPUT_MODE_DEFAULT)
        },
        setInputModeToDraw({ commit }) {
            commit('SET_INPUT_MODE', INPUT_MODE_DRAW)
        },
        setInputModeToCrop({ commit }) {
            commit('SET_INPUT_MODE', INPUT_MODE_CROP)
        },
        resetInputStrokes({ commit }) {
            commit('SET_INPUT_STROKES', 0)
        },
        setInputStrokes({ commit }, strokes) {
            commit('SET_INPUT_STROKES', strokes)
        },
        setInputRaw({ commit }, raw) {
            commit('SET_INPUT_RAW', raw)
        },
        setInputAsset({ commit }, asset) {
            commit('SET_INPUT_ASSET', asset)
        },

        setPreviewAssets({ commit }, assets) {
            commit('SET_PREVIEW_ASSETS', assets)
        },
        setModelAsset({ commit }, asset) {
            commit('SET_MODEL_ASSET', asset)
        },

        setPromptPositive({ commit }, prompt) {
            commit('SET_PROMPT_POSITIVE', prompt)
        },
        setPromptNegative({ commit }, prompt) {
            commit('SET_PROMPT_NEGATIVE', prompt)
        },
        setPromptAdvanced({ commit }, advanced) {
            commit('SET_PROMPT_ADVANCED', advanced)
        },
        setBalance({ commit }, balance) {
            commit('SET_BALANCE', balance)
        },

        setViewerAnimation({ commit }, animation) {
            commit('SET_VIEWER_ANIMATION', animation)
        },

        setViewerMode({ commit }, mode) {
            commit('SET_VIEWER_MODE', mode)
        },
        setViewerModeToDefault({ commit }) {
            commit('SET_VIEWER_MODE', VIEWER_MODE_DEFAULT)
        },
        setViewerModeToTexture({ commit }) {
            commit('SET_VIEWER_MODE', VIEWER_MODE_TEXTURE)
        },
        setViewerModeToSolid({ commit }) {
            commit('SET_VIEWER_MODE', VIEWER_MODE_SOLID)
        },
        setViewerModeToWireframe({ commit }) {
            commit('SET_VIEWER_MODE', VIEWER_MODE_WIREFRAME)
        },

        setViewerFile({ commit }, file)
        {
            commit('SET_VIEWER_FILE', file)
        },
        setViewerFileToDefault({ commit })
        {
            commit('SET_VIEWER_FILE', VIEWER_FILE_DEFAULT)
        },
        setViewerFileToModified({ commit })
        {
            commit('SET_VIEWER_FILE', VIEWER_FILE_MODIFIED)
        },

        setAdditionalCustomPositive({ dispatch, rootGetters }, prompt) {
            // get additional
            let additional = rootGetters['app/getAdditional']
            if (!additional) additional = {}

            // set prompt custom positive
            additional.sketchCustomPositive = prompt

            // change additional 
            dispatch('app/changeAdditional', additional, { root: true })
        },
        setAdditionalCustomNegative({ dispatch, rootGetters }, prompt) {
            // get additional
            let additional = rootGetters['app/getAdditional']
            if (!additional) additional = {}

            // set prompt custom negative
            additional.sketchCustomNegative = prompt

            // change additional 
            dispatch('app/changeAdditional', additional, { root: true })
        },
        setAdditionalGenerationPersistent({ dispatch, rootGetters }, persistent) {
            // get additional
            let additional = rootGetters['app/getAdditional']
            if (!additional) additional = {}

            // set generation persistence
            additional.sketchGenerationPersistent = persistent

            // change additional 
            dispatch('app/changeAdditional', additional, { root: true })
        },
        setAdditionalGenerationQuality({ dispatch, rootGetters }, quality) {
            // get additional
            let additional = rootGetters['app/getAdditional']
            if (!additional) additional = {}

            // set generation quality
            additional.sketchGenerationQuality = quality

            // change additional 
            dispatch('app/changeAdditional', additional, { root: true })
        },
        setAdditionalExportFormat({ dispatch, rootGetters }, format) {
            // get additional
            let additional = rootGetters['app/getAdditional']
            if (!additional) additional = {}

            // set export format
            additional.sketchExportFormat = format

            // change additional 
            dispatch('app/changeAdditional', additional, { root: true })
        },

        // open an asset (input, preview, or model)
        async openAsset({ dispatch, getters }, {
            asset,
            show = true
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to open.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // get asset category
                const category = asset.additional?.category

                if (show)
                    dispatch('app/setLoadingText', 'opening ' + category, { root: true })
                else
                    dispatch('app/setLoadingText', 'opening flow', { root: true })

                // reset vuex store 
                await dispatch('resetSketch')

                // set flow
                await dispatch('setFlowFromAsset', asset)

                // if input asset
                if (getters.isAssetInput(asset))
                {
                    if (show) dispatch('setStepToInput')
                    return await dispatch('openAssetAsInput', asset)
                }

                // if preview asset
                else if (getters.isAssetPreview(asset))
                {
                    if (show) dispatch('setStepToPreview')
                    return await dispatch('openAssetAsPreview', asset)
                }

                // if model asset
                else if (getters.isAssetModel(asset))
                {
                    if (show) dispatch('setStepToModel')
                    return await dispatch('openAssetAsModel', asset)
                }

                else 
                {
                    throw new Error('Cannot open unsupported asset type.')
                }
            }
            catch(error)
            {
                // provide user feedback
                dispatch('app/showToast', { 
                    text: 'Opening the asset has failed.', 
                    warning: true
                }, { root: true })

                throw error
            }
            finally 
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })
            }
        },

        // open asset as input
        // INFO: normally only indirectly called from openAsset
        async openAssetAsInput({ getters, dispatch, rootGetters }, asset)
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to open.")
            }

            // ensure asset type is supported for input
            if (!rootGetters['api/isAssetTypeImage'](asset)) {
                throw new Error("Cannot open unsupported asset type.")
            }

            try
            {
                // call route to retrieve asset file (with default key 'default')
                const response = await dispatch('api/assetsAssetFileKeyGet', { 
                    asset,
                    key: 'default',
                    untilReady: false,
                }, { root: true })

                // after the file has been retreived, set the asset as active input
                dispatch('setInputAsset', asset)

                // set prompt 
                if (asset.additional && asset.additional.positive)
                    dispatch('setPromptPositive', asset.additional.positive)
                if (asset.additional && asset.additional.negative)
                    dispatch('setPromptNegative', asset.additional.negative)

                // set guidance strength
                if (asset.additional && asset.additional.guidance)
                    dispatch('setBalance', asset.additional.guidance)

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // open asset as preview
        // INFO: normally only indirectly called from openAsset
        async openAssetAsPreview({ dispatch, getters, rootGetters }, asset)
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to open.")
            }

            // ensure asset type is supported for input
            if (!rootGetters['api/isAssetTypeImage'](asset)) {
                throw new Error("Cannot open unsupported asset type.")
            }

            try
            {
                // if not a preview asset
                if (getters.isAssetInput(asset))
                {
                    // directly open as input
                    return await dispatch('openAssetAsInput', asset)
                }
                else
                {
                    // call route to retrieve asset file (with default key 'default')
                    const response = await dispatch('api/assetsAssetFileKeyGet', { 
                        asset,
                        key: 'default',
                        untilReady: false,
                    }, { root: true })

                    // after the file has been retreived, set the asset as active preview
                    dispatch('setPreviewAssets', [ asset ])

                    // set prompt 
                    if (asset.additional && asset.additional.positive)
                        dispatch('setPromptPositive', asset.additional.positive)
                    if (asset.additional && asset.additional.negative)
                        dispatch('setPromptNegative', asset.additional.negative)

                    // set guidance strength
                    if (asset.additional && asset.additional.guidance)
                        dispatch('setBalance', asset.additional.guidance)

                    // has parent?
                    if (asset.parent) 
                    {
                        // get parent asset (input)
                        const parentAsset = rootGetters['api/getAsset'](asset.parent)

                        // parent retrieved successfully?
                        if (parentAsset) await dispatch('openAssetAsInput', parentAsset)
                    }

                    return response
                }
            }
            catch(error)
            {
                throw error
            }
        },

        // open asset as model
        // INFO: normally only indirectly called from openAsset
        async openAssetAsModel({ dispatch, rootGetters }, asset)
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to open.")
            }

            // ensure asset type is supported for input
            if (!rootGetters['api/isAssetTypeModel'](asset)) {
                throw new Error("Cannot open unsupported asset type.")
            }

            try
            {
                // call route to retrieve asset file (with default key 'default')
                const response = await dispatch('api/assetsAssetFileKeyGet', { 
                    asset,
                    key: 'default',
                    untilReady: false,
                }, { root: true })

                // after the file has been retreived, set the asset as active model
                dispatch('setModelAsset', asset)

                // has parent?
                if (asset.parent)
                {
                    // get parent asset (preview)
                    const parentAsset = rootGetters['api/getAsset'](asset.parent)

                    // parent retrieved successfully?
                    if (parentAsset) await dispatch('openAssetAsPreview', parentAsset)
                }            

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // edit asset
        async editAsset({ commit, dispatch, getters, rootGetters }, {
            asset,
            flow = null
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to edit.")
            }

            // ensure flow is provided and supported
            if (flow !== getters.keyFlowSketch && flow !== getters.keyFlowImage) {
                throw new Error("Please provide a valid flow for editing.")
            }

            // ensure asset type is supported for input
            if (!rootGetters['api/isAssetTypeImage'](asset)) {
                throw new Error("Cannot open unsupported asset type.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })
                dispatch('app/setLoadingText', 'preparing ' + flow, { root: true })

                // reset vuex store 
                await dispatch('resetSketch')

                // set flow
                commit('SET_FLOW', flow)
                await dispatch('newFlow')

                // does it need to be converted into a sketch?
                if (flow === getters.keyFlowSketch && !getters.isAssetInputSketch(asset))
                {
                    // prepare additional
                    const additional = {
                        flow: getters.keyFlowSketch,
                        count: getters.getCount,
                        category: getters.keyAssetCategoryInput,
                        positive: getters.getPromptPositive,
                        rating: 0
                    }

                    // add positive if available
                    const positive = asset.additional?.positive
                    if (positive) additional.positive = positive

                    // inform user
                    dispatch('app/setLoadingText', 'generating sketch', { root: true })

                    // generate sketch from image
                    asset = await dispatch('api/imagesImageSketchGeneratePost', {
                        asset,
                        additional
                    }, { root: true })

                    // retrieve generated sketch
                    await dispatch('api/assetsAssetFileKeyGet', { 
                        asset,
                        key: 'default'
                    }, { root: true })
                }
                // does it need to be reuploaded as input image?
                else if (flow === getters.keyFlowImage && getters.isAssetPreview(asset))
                {
                    // prepare additional
                    const additional = {
                        flow: getters.keyFlowImage,
                        count: getters.getCount,
                        category: getters.keyAssetCategoryInput,
                        positive: getters.getPromptPositive,
                        rating: 0
                    }

                    // add positive if available
                    const positive = asset.additional?.positive
                    if (positive) additional.positive = positive

                    // upload image
                    asset = await dispatch('api/assetsAssetClonePost', {
                        assetId: asset.id,
                        keys: ['default'],
                        additional
                    }, { root: true })

                    // retrieve generated sketch
                    await dispatch('api/assetsAssetFileKeyGet', { 
                        asset,
                        key: 'default'
                    }, { root: true })
                }

                // open asset as input
                const response = await dispatch('openAssetAsInput', asset)

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // log event
                dispatch('app/logEvent', { 
                    name: "Sketchurizer Asset Edit", 
                    description: "User has started to edit the asset: " + asset.id
                }, { root: true })

                return response
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                dispatch('app/showToast', { 
                    text: 'Opening the asset for editing has failed.', 
                    warning: true
                }, { root: true })

                throw error
            }
        },

        // create and set input asset (from base64 input)
        async setInputAssetFromBase64({ commit, dispatch, getters }, base64)
        {
            // ensure base64 parameter is provided
            if (base64 === null || base64 === undefined) {
                throw new Error("Please provide an base64 parameter.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })
                dispatch('app/setLoadingText', 'processing ' + getters.getFlow, { root: true })

                // prepare additional 
                const additional = {
                    flow: getters.getFlow,
                    count: getters.getCount,
                    category: getters.keyAssetCategoryInput,
                    rating: 0
                }

                // add positive if available
                const positive = getters.getPromptPositive
                if (positive) additional.positive = positive

                // create input asset
                const asset = await dispatch('api/assetsPost', {
                    fileData: base64, 
                    process: {
                        mode: 'limit',
                        resolution: 1024
                    },
                    additional
                }, { root: true })

                // wait for asset to be ready
                await dispatch('api/assetsAssetFileKeyGet', {
                    asset,
                }, { root: true })

                // set input asset
                commit('SET_INPUT_ASSET', asset)

                return asset
            }
            catch(error)
            {
                throw error
            }
            finally 
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // log event
                dispatch('app/logEvent', { 
                    name: "Sketchurizer Input Asset", 
                    description: "User has created a new input asset."
                }, { root: true })
            }
        },

        // create and set input asset (from raw input)
        async setInputAssetFromRaw({ dispatch, getters })
        {
            // if input is empty, return
            if (getters.isInputEmpty) return null

            try
            {
                // check if a new asset should be created
                if (getters.isInputAssetOutdated || getters.getInputAsset === null)
                {
                    // get raw input
                    const raw = getters.getInputRaw

                    // create input asset from raw
                    const asset = await dispatch('setInputAssetFromBase64', raw)

                    return asset
                }
                else
                {
                    return getters.getInputAsset
                }
            }
            catch(error)
            {
                throw error
            }
        },

        // remove background of input image
        async removeInputBackground({ getters, dispatch, commit })
        {
            try
            {
                // set input asset
                let asset = await dispatch('setInputAssetFromRaw')

                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // inform the user
                dispatch('app/setLoadingText', 'removing background', { root: true })

                // prepare additional
                const additional = {
                    flow: getters.getFlow,
                    count: getters.getCount,
                    category: getters.keyAssetCategoryInput,
                    rating: 0
                }

                // add positive if available
                const positive = asset.additional?.positive
                if (positive) additional.positive = positive

                // convert image to transparent image (layer)
                asset = await dispatch('api/imagesImageModifyAlphaAddPost', {
                    asset,
                    additional
                }, { root: true })

                // wait for asset to be ready
                await dispatch('api/assetsAssetFileKeyGet', {
                    asset,
                }, { root: true })

                // set input asset
                commit('SET_INPUT_ASSET', asset)

                return asset
            }
            catch(error)
            {
                // inform user
                dispatch('app/showToast', {
                    text: 'Background removal has failed. Please try again.', 
                    warning: true 
                }, { root: true })

                throw error
            }
            finally 
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // log event
                dispatch('app/logEvent', { 
                    name: "Sketchurizer Background Removal Request", 
                    description: "User has requested the background removal of the input image"
                }, { root: true })
            }
        },

        // generate sketch
        async generateSketchBlueprint({ getters, dispatch, rootGetters }, {
            count = 3,
            resolution = 768
        } = {})
        {
            // prompt required
            if (!getters.hasPromptPositive) 
            {
                // inform user
                dispatch('app/showToast', {
                    text: 'Please describe your model with a prompt.'
                }, { root: true })

                return
            }

            try
            {
                // generate three random previews
                const seeds = new Array(count).fill(-1)

                // prepare additional
                const additional = {
                    flow: getters.keyFlowSketch,
                    count: getters.getCount,
                    category: getters.keyAssetCategoryInput,
                    positive: getters.getPromptPositive,
                    blueprint: true,
                    rating: 0
                }

                // if no guidance is provided
                if (getters.hasInputRaw || getters.hasInputStrokes)
                {
                    // create or get input asset
                    const parent = await dispatch('setInputAssetFromRaw')

                    // call route to generate sketch
                    const sketch = await dispatch('api/imagesFromGuidanceGeneratePost', {
                        parentId: parent.id,
                        positive: getters.getPromptPositiveAugmented,
                        strength: 0.5,
                        alpha: true,
                        seeds,
                        resolution,
                        style: rootGetters['api/keyImageSubSketch'],
                        additional
                    }, { root: true })

                    return sketch
                }
                else 
                {
                    // call route to generate sketch 
                    const sketch = await dispatch('api/imagesFromPromptGeneratePost', {
                        positive: getters.getPromptPositiveAugmented,
                        seeds,
                        alpha: true,
                        resolution,
                        style: rootGetters['api/keyImageSubSketch'],
                        additional
                    }, { root: true })

                    return sketch
                }
            }
            catch(error)
            {
                // inform user
                dispatch('app/showToast', {
                    text: 'Generating sketches has failed. Please try again.', 
                    warning: true 
                }, { root: true })

                throw error
            }
            finally
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // log event
                dispatch('app/logEvent', { 
                    name: "Sketchurizer Sketch Request", 
                    description: "User has requested the generation of sketches"
                }, { root: true })
            }
        },

        // generate previews
        async generatePreviews({ commit, dispatch, getters, rootGetters }, {
            count = 3,
            resolution = 1024
        } = {})
        {
            // prompt required
            if (!getters.hasPromptPositive) 
            {
                // inform user
                dispatch('app/showToast', {
                    text: 'Please describe your model with a prompt.'
                }, { root: true })

                return
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // reset preview assets
                commit('SET_PREVIEW_ASSETS', null)

                // prepare additional
                const additional = {
                    flow: getters.getFlow,
                    count: getters.getCount,
                    category: getters.keyAssetCategoryPreview,
                    positive: getters.getPromptPositive,
                    negative: getters.getPromptNegative,
                    guidance: getters.getBalance,
                    rating: 0
                }

                // generate three random previews
                const seeds = new Array(count).fill(-1)

                // if no input provided
                if (getters.isInputEmpty || getters.getBalance <= 0)
                {
                    // generate previews from prompt
                    const previewAssets = await dispatch('api/imagesFromPromptGeneratePost', {
                        positive: getters.getPromptPositiveAugmented,
                        negative: getters.getPromptNegativeAugmented,
                        seeds,
                        alpha: true,
                        resolution,
                        additional
                    }, { root: true })

                    // display preview assets
                    commit('SET_PREVIEW_ASSETS', previewAssets)

                    return previewAssets
                }

                // input provided
                else
                {
                    // create or get input asset
                    const inputAsset = await dispatch('setInputAssetFromRaw')

                    // sketch does not need to be processed
                    const process = getters.isFlowSketch ? null : rootGetters['api/keyImageSubCanny']

                    // generate previews from guidance
                    const previewAssets = await dispatch('api/imagesFromGuidanceGeneratePost', {
                        parentId: inputAsset.id,
                        positive: getters.getPromptPositiveAugmented,
                        negative: getters.getPromptNegativeAugmented,
                        process,
                        strength: getters.getBalance,
                        seeds,
                        alpha: true,
                        resolution,
                        additional
                    }, { root: true })

                    // display preview assets
                    commit('SET_PREVIEW_ASSETS', previewAssets)

                    // set step to preview
                    dispatch("setStepToPreview")

                    return previewAssets
                }
            }
            catch(error)
            {
                // inform user
                dispatch('app/showToast', {
                    text: 'Generating previews has failed. Please try again.', 
                    warning: true 
                }, { root: true })

                throw error
            }
            finally
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // log event
                dispatch('app/logEvent', { 
                    name: "Sketchurizer Previews Request", 
                    description: "User has requested the generation of image previews"
                }, { root: true })
            }
        },

        // model generation 
        // INFO: if parent is null, the input asset is used
        async generateModel({ commit, dispatch, getters, rootGetters }, {
            parent = null,
            fileKey = null,
            quality = 'low',
            lod = 0.95,
            seed = -1
        } = {})
        {
            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // set model asset
                //commit('SET_MODEL_ASSET', null)

                // prepare additional
                const additional = {
                    flow: getters.getFlow,
                    count: getters.getCount,
                    category: getters.keyAssetCategoryModel,
                    quality: quality,
                    positive: getters.getPromptPositive,
                    rating: 0
                }

                // fill seeds array
                const seeds = [ seed ]
        
                // check if a parent is not specified
                if (parent === null)
                {
                    // inform users about input upload
                    dispatch('app/setLoadingText', 'processing input', { root: true })

                    // create or get input asset
                    parent = await dispatch('setInputAssetFromRaw')
                }

                /*
                // inform users about model generation
                dispatch('app/setLoadingText', 'analyzing input', { root: true })

                // check if albedo is missing currently
                if (!parent.files.hasOwnProperty('albedo'))
                {
                    // call route to generate albedo
                    parent = await dispatch('api/imagesImageAlbedoGeneratePost', {
                        asset: parent,
                        key: 'albedo'
                    }, { root: true })
                }

                // wait for file to be generated
                await dispatch('api/assetsAssetFileKeyGet', {
                    asset: parent,
                    key: 'albedo',
                    timeMaximum: 300000 // wait five minutes for the model (at max)
                }, { root: true })
                */

                // inform users about model generation
                dispatch('app/setLoadingText', 'generating model', { root: true })

                // call route to generate model
                const response = await dispatch('api/modelsFromImageGeneratePost', {
                    sourceAssets: [ parent ],
                    sourceFileKey: 'default',
                    targetFileKey: fileKey,
                    additional,
                    quality,
                    seeds,
                    lod
                }, { root: true })

                // container for asset and file key
                let asset = null
                let key = null

                // if file key is provided
                if (fileKey !== null) 
                {
                    asset = response
                    key = rootGetters['api/keyFileKeyModified']
                }
                else 
                {
                    asset = response[0]
                    key = rootGetters['api/keyFileKeyDefault']
                }

                // wait for file to be generated
                await dispatch('api/assetsAssetFileKeyGet', {
                    asset,
                    key,
                    timeMaximum: 300000 // wait five minutes for the model (at max)
                }, { root: true })

                // set model asset
                commit('SET_MODEL_ASSET', asset)

                // set step to model
                dispatch("setStepToModel")

                // if file key is provided, then only one model has been generated
                if (fileKey !== null) 
                    dispatch('setViewerFileToModified')
                else
                    dispatch('setViewerFileToDefault')

                return asset
            }
            catch(error)
            {
                // inform user
                dispatch('app/showToast', {
                    text: 'Generating model has failed. Please try again.', 
                    warning: true 
                }, { root: true })

                throw error
            }
            finally
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // log event
                dispatch('app/logEvent', { 
                    name: "Sketchurizer Models Request", 
                    description: "User has requested the generation of models"
                }, { root: true })
            }
        }
    }
}