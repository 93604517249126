/*
 * API Store
 */


//// IMPORTS
import { base64ToArrayBuffer, downloadFile } from '@/utils/file'


//// ROUTE CONSTANTS
const ROUTE_BASE = '/api'

// users routes
const ROUTE_USERS = '/users'
const ROUTE_USERS_REGISTER = `${ROUTE_USERS}/register`
const ROUTE_USERS_ACTIVATE = `${ROUTE_USERS}/activate`
const ROUTE_USERS_DEACTIVATE = `${ROUTE_USERS}/deactivate`
const ROUTE_USERS_TOKEN = `${ROUTE_USERS}/token`
const ROUTE_USERS_PASSWORD_FORGOT = `${ROUTE_USERS}/password/forgot`
const ROUTE_USERS_PASSWORD_CHANGE = `${ROUTE_USERS}/password/change`
const ROUTE_USERS_STATISTICS = `${ROUTE_USERS}/statistics`
const ROUTE_USERS_DESIGN = `${ROUTE_USERS}/design`
const ROUTE_USERS_NEWSLETTER = `${ROUTE_USERS}/newsletter`
const ROUTE_USERS_ADDITIONAL = `${ROUTE_USERS}/additional`

// app routes
const ROUTE_APPS_APP_ADDITIONAL = (appName) => `/apps/${appName}/additional`

// assets routes
const ROUTE_ASSETS = `/assets`
const ROUTE_ASSETS_SHARE = `${ROUTE_ASSETS}/share`
const ROUTE_ASSETS_ASSET = (assetId) => `${ROUTE_ASSETS}/${String(assetId)}`
const ROUTE_ASSETS_ASSET_CLONE = (assetId) => `${ROUTE_ASSETS_ASSET(assetId)}/clone`
const ROUTE_ASSETS_ASSET_ADDITIONAL = (assetId) => `${ROUTE_ASSETS_ASSET(assetId)}/additional`
const ROUTE_ASSETS_ASSET_THUMBNAIL = (assetId) => `${ROUTE_ASSETS_ASSET(assetId)}/thumbnail`
const ROUTE_ASSETS_ASSET_THUMBNAIL_STATUS = (assetId) => `${ROUTE_ASSETS_ASSET(assetId)}/thumbnail/status`
const ROUTE_ASSETS_ASSET_FILES = (assetId) => `${ROUTE_ASSETS_ASSET(assetId)}/files`
const ROUTE_ASSETS_ASSET_FILES_STATUS = (assetId) => `${ROUTE_ASSETS_ASSET(assetId)}/files/status`
const ROUTE_ASSETS_ASSET_FILE_KEY = (assetId, key) => `${ROUTE_ASSETS_ASSET(assetId)}/file/${key}`
const ROUTE_ASSETS_ASSET_FILE_KEY_STATUS = (assetId, key) => `${ROUTE_ASSETS_ASSET(assetId)}/file/${key}/status`
const ROUTE_ASSETS_ASSET_SHARE = (assetId) => `${ROUTE_ASSETS_ASSET(assetId)}/share`

// texts routes
const ROUTE_TEXTS = '/texts'
const ROUTE_TEXTS_GENERATE = `${ROUTE_TEXTS}/generate`
const ROUTE_TEXTS_TEXT = (textAssetId) => `${ROUTE_TEXTS}/${String(textAssetId)}`
const ROUTE_TEXTS_TEXT_CONVERT = (textAssetId) => `${ROUTE_TEXTS_TEXT(textAssetId)}/convert`
const ROUTE_TEXTS_TEXT_RENDER = (textAssetId) => `${ROUTE_TEXTS_TEXT(textAssetId)}/render`

// images routes
const ROUTE_IMAGES = `/images`
const ROUTE_IMAGES_FROMPROMPT_GENERATE = `${ROUTE_IMAGES}/from-prompt/generate`
const ROUTE_IMAGES_FROMGUIDANCE_GENERATE = `${ROUTE_IMAGES}/from-guidance/generate`
const ROUTE_IMAGES_IMAGE = (imageAssetId) => `${ROUTE_IMAGES}/${String(imageAssetId)}`
const ROUTE_IMAGES_IMAGE_CONVERT = (imageAssetId) => `${ROUTE_IMAGES_IMAGE(imageAssetId)}/convert`
const ROUTE_IMAGES_IMAGE_MODIFY = (imageAssetId) => `${ROUTE_IMAGES_IMAGE(imageAssetId)}/modify`
const ROUTE_IMAGES_IMAGE_MODIFY_ALPHA_ADD = (imageAssetId) => `${ROUTE_IMAGES_IMAGE_MODIFY(imageAssetId)}/alpha/add`
const ROUTE_IMAGES_IMAGE_MODIFY_ALPHA_REMOVE = (imageAssetId) => `${ROUTE_IMAGES_IMAGE_MODIFY(imageAssetId)}/alpha/remove`
const ROUTE_IMAGES_IMAGE_MODIFY_ANNOTATIONS_REMOVE = (imageAssetId) => `${ROUTE_IMAGES_IMAGE_MODIFY(imageAssetId)}/annotations/remove`
const ROUTE_IMAGES_IMAGE_SUB = (imageAssetId) => `${ROUTE_IMAGES_IMAGE(imageAssetId)}/sub`
const ROUTE_IMAGES_IMAGE_SUB_ALBEDO_GENERATE = (imageAssetId) => `${ROUTE_IMAGES_IMAGE_SUB(imageAssetId)}/albedo/generate`
const ROUTE_IMAGES_IMAGE_SUB_CANNY_GENERATE = (imageAssetId) => `${ROUTE_IMAGES_IMAGE_SUB(imageAssetId)}/canny/generate`
const ROUTE_IMAGES_IMAGE_SUB_DEPTH_GENERATE = (imageAssetId) => `${ROUTE_IMAGES_IMAGE_SUB(imageAssetId)}/depth/generate`
const ROUTE_IMAGES_IMAGE_SUB_IRRADIANCE_GENERATE = (imageAssetId) => `${ROUTE_IMAGES_IMAGE_SUB(imageAssetId)}/irradiance/generate`
const ROUTE_IMAGES_IMAGE_SUB_METALLIC_GENERATE = (imageAssetId) => `${ROUTE_IMAGES_IMAGE_SUB(imageAssetId)}/metallic/generate`
const ROUTE_IMAGES_IMAGE_SUB_NORMAL_GENERATE = (imageAssetId) => `${ROUTE_IMAGES_IMAGE_SUB(imageAssetId)}/normal/generate`
const ROUTE_IMAGES_IMAGE_SUB_ROUGHNESS_GENERATE = (imageAssetId) => `${ROUTE_IMAGES_IMAGE_SUB(imageAssetId)}/roughness/generate`
const ROUTE_IMAGES_IMAGE_SUB_SEGMENT_GENERATE = (imageAssetId) => `${ROUTE_IMAGES_IMAGE_SUB(imageAssetId)}/segment/generate`
const ROUTE_IMAGES_IMAGE_SUB_SKETCH_GENERATE = (imageAssetId) => `${ROUTE_IMAGES_IMAGE_SUB(imageAssetId)}/sketch/generate`
const ROUTE_IMAGES_IMAGE_RENDER = (imageAssetId) => `${ROUTE_IMAGES_IMAGE(imageAssetId)}/render`

// models routes
const ROUTE_MODELS = `/models`
const ROUTE_MODELS_FROMIMAGE_GENERATE = `${ROUTE_MODELS}/from-image/generate` 
const ROUTE_MODELS_FROMSCRIPT_GENERATE = `${ROUTE_MODELS}/from-script/generate` 
const ROUTE_MODELS_MODEL = (modelAssetId) => `${ROUTE_MODELS}/${modelAssetId}`
const ROUTE_MODELS_MODEL_CONVERT = (modelAssetId) => `${ROUTE_MODELS_MODEL(modelAssetId)}/convert`
const ROUTE_MODELS_MODEL_MODIFY = (modelAssetId) => `${ROUTE_MODELS_MODEL(modelAssetId)}/modify`
const ROUTE_MODELS_MODEL_MODIFY_POSE = (modelAssetId) => `${ROUTE_MODELS_MODEL_MODIFY(modelAssetId)}/pose`
const ROUTE_MODELS_MODEL_MODIFY_SURFACE = (modelAssetId) => `${ROUTE_MODELS_MODEL_MODIFY(modelAssetId)}/surface`
const ROUTE_MODELS_MODEL_MODIFY_TOPOLOGY = (modelAssetId) => `${ROUTE_MODELS_MODEL_MODIFY(modelAssetId)}/topology`
const ROUTE_MODELS_MODEL_RENDER = (modelAssetId) => `${ROUTE_MODELS_MODEL(modelAssetId)}/render`

// logging routes
const ROUTE_LOGGING = '/logging'
const ROUTE_LOGGING_EVENT = `${ROUTE_LOGGING}/event`
const ROUTE_LOGGING_BUG = `${ROUTE_LOGGING}/bug`
const ROUTE_LOGGING_FEEDBACK = `${ROUTE_LOGGING}/feedback`
const ROUTE_LOGGING_VOTE = `${ROUTE_LOGGING}/vote`

// monitoring routes
const ROUTE_MONITORING_STATUS = '/monitoring/status'

// administration routes
const ROUTE_ADMINISTRATION = '/administration'
const ROUTE_ADMINISTRATION_ACTIVITY_USERS = `${ROUTE_ADMINISTRATION}/activity/users`
const ROUTE_ADMINISTRATION_ACTIVITY_ASSETS = `${ROUTE_ADMINISTRATION}/activity/assets`
const ROUTE_ADMINISTRATION_LOGGING_EVENTS = `${ROUTE_ADMINISTRATION}/logging/events`
const ROUTE_ADMINISTRATION_LOGGING_BUGS = `${ROUTE_ADMINISTRATION}/logging/bugs`
const ROUTE_ADMINISTRATION_LOGGING_FEEDBACK = `${ROUTE_ADMINISTRATION}/logging/feedback`
const ROUTE_ADMINISTRATION_LOGGING_VOTES = `${ROUTE_ADMINISTRATION}/logging/votes`
const ROUTE_ADMINISTRATION_NEWSLETTERS = `${ROUTE_ADMINISTRATION}/newsletters`
const ROUTE_ADMINISTRATION_NEWSLETTERS_SEND = `${ROUTE_ADMINISTRATION}/newsletters/send`


//// REST CONSTANTS
const REST_POST = 'POST'
const REST_GET = 'GET'
const REST_PUT = 'PUT'
const REST_DELETE = 'DELETE'


//// SERVICE CONSTANTS

// status
const SERVICE_STATUS_UP = 'up'
const SERVICE_STATUS_DOWN = 'down'


//// ASSET CONSTANTS

// types
const ASSET_TYPE_TEXT = 'text'
const ASSET_TYPE_IMAGE = 'image'
const ASSET_TYPE_TEXTURE = 'texture'
const ASSET_TYPE_MODEL = 'model'

// status
const ASSET_STATUS_UNREQUESTED = 'unrequested'
const ASSET_STATUS_REQUESTED = 'requested'
const ASSET_STATUS_RETRIEVED = 'retrieved'
const ASSET_STATUS_UNRETRIEVABLE = 'unretrievable'


//// FILE CONSTANTS

// file key
const FILE_KEY_DEFAULT = 'default'
const FILE_KEY_MODIFIED = 'modified'

// file status
const FILE_STATUS_PENDING = 'pending'
const FILE_STATUS_ERROR = 'error'
const FILE_STATUS_READY = 'ready'


//// IMAGE CONSTANTS

// subs
const IMAGE_SUB_ALBEDO = 'albedo'
const IMAGE_SUB_CANNY = 'canny'
const IMAGE_SUB_DEPTH = 'depth'
const IMAGE_SUB_IRRADIANCE = 'irradiance'
const IMAGE_SUB_NORMAL = 'normal'
const IMAGE_SUB_METALLIC = 'metallic'
const IMAGE_SUB_ROUGHNESS = 'roughness'
const IMAGE_SUB_SEGMENT = 'segment'
const IMAGE_SUB_SKETCH = 'sketch'

// export formats
const IMAGE_EXPORT_FORMAT_PNG = 'Portable Network Graphics (.png)'
const IMAGE_EXPORT_FORMAT_JPG = 'Joint Photographic Experts Group (.jpg)'
const IMAGE_EXPORT_FORMAT_BMP = 'Bitmap (.bmp)'
const IMAGE_EXPORT_FORMAT_TIFF = 'Tagged Image File Format (.tiff)'
const IMAGE_EXPORT_FORMAT_WEBP = 'WebP (.webp)'
const IMAGE_EXPORT_FORMAT_DEFAULT = IMAGE_EXPORT_FORMAT_PNG


//// MODEL CONSTANTS

// generation quality
const MODEL_GENERATION_QUALITY_LOW = 'low'
const MODEL_GENERATION_QUALITY_HIGH = 'high'
const MODEL_GENERATION_QUALITY_DEFAULT = MODEL_GENERATION_QUALITY_LOW 

// export format
const MODEL_EXPORT_FORMAT_GLB = 'Graphics Library Binary (.glb)'
const MODEL_EXPORT_FORMAT_FBX = 'Autodesk Filmbox (.fbx)'
const MODEL_EXPORT_FORMAT_STL = 'Stereolithography (.stl)'
const MODEL_EXPORT_FORMAT_OBJ = 'Wavefront Object (.obj)'
const MODEL_EXPORT_FORMAT_PLY = 'Polygon File Format (.ply)'
const MODEL_EXPORT_FORMAT_DEFAULT = MODEL_EXPORT_FORMAT_GLB


//// RESOLUTION CONSTANTS
const RESOLUTION_THUMBNAIL_DEFAULT = 200


//// VUEX STORE
export const api = {
    namespaced: true,
    state: {
        service: {
            status: SERVICE_STATUS_DOWN,
            domains: null,
            messaging: null
        },
        loading: false,
        assets: [],

        // TODO move to app vuex store
        votes: {} 
    },
    mutations: {
        // set service
        SET_SERVICE(state, { status, domains, queues })
        {
            state.service.status = status
            state.service.domains = domains
            state.service.queues = queues
        },
        // set service status
        SET_SERVICE_STATUS(state, status)
        {
            state.service.status = status
        },

        SET_LOADING(state, loading)
        {
            state.loading = loading
        },

        // reset assets
        RESET_ASSETS(state)
        {
            state.assets = []
        },

        // add asset
        ADD_ASSET(state, asset)
        {
            // check if asset is invalid
            if (asset === null || asset === undefined) return
            if (asset.id === null || asset.id === undefined) return

            // parse dates
            if (asset.created !== null && asset.created !== undefined)
                asset.created = new Date(asset.created)
            if (asset.update !== null && asset.update !== undefined)
                asset.update = new Date(asset.update)

            // push asset to assets list
            state.assets.push(asset)
        },

        // add asset
        ADD_OR_UPDATE_ASSET(state, asset)
        {
            // check if asset is invalid
            if (asset === null || asset === undefined) return
            if (asset.id === null || asset.id === undefined) return

            // parse dates
            if (asset.created !== null && asset.created !== undefined)
                asset.created = new Date(asset.created)
            if (asset.update !== null && asset.update !== undefined)
                asset.update = new Date(asset.update)

            // find asset in assets
            const assetExisting = state.assets.find(entry => entry.id === asset.id)

            // if asset exists, update it
            if (assetExisting)
            {   
                // update additional field
                if (asset.additional) assetExisting.additional = asset.additional

                // update shared field
                if (asset.shared) assetExisting.shared = asset.shared

                // update update(d) field
                if (asset.update) assetExisting.update = asset.update

                // update thumbnail field
                if (asset.thumbnail)
                {
                    // update if already retrieved
                    if (asset.thumbnail.status === ASSET_STATUS_RETRIEVED)
                    {
                        assetExisting.thumbnail.data = asset.thumbnail.data
                        assetExisting.thumbnail.status = asset.thumbnail.status
                    }
                }

                // update files field
                if (asset.files)
                {
                    // iterate through all keys
                    for (const key in asset.files)
                    {
                        // update if file does not exist
                        if (!(key in assetExisting.files))
                        {
                            assetExisting.files[key] = asset.files[key]
                        }

                        // update if already retrieved
                        if (asset.files[key].status === ASSET_STATUS_RETRIEVED)
                        {
                            assetExisting.files[key].data = asset.files[key].data
                            assetExisting.files[key].status = asset.files[key].status
                            assetExisting.files[key].details = asset.files[key].details
                        }
                    }
                }
            }
            else
            {
                // push asset to assets list
                state.assets = [...state.assets, asset]
            }
        },

        // remove asset
        REMOVE_ASSET(state, assetId)
        {
            // find index of asset in assets
            const index = state.assets.findIndex(asset => asset.id === assetId)
            // if it exists in the list, remove it
            if (index !== -1) 
            {
                state.assets.splice(index, 1)
                state.assets = [...state.assets]
            }
        },

        // set a specific vote with a key (value true/false)
        SET_VOTE(state, { key, value })
        {
            state.votes[key] = value
        }
    },
    getters: {
        keyServiceStatusUp: () => SERVICE_STATUS_UP,
        keyServiceStatusDown: () => SERVICE_STATUS_DOWN,
        keyServiceStatusUndefned: () => SERVICE_STATUS_UNDEFINED,

        keyAssetTypeText: () => ASSET_TYPE_TEXT,
        keyAssetTypeImage: () => ASSET_TYPE_IMAGE,
        keyAssetTypeTexture: () => ASSET_TYPE_TEXTURE,
        keyAssetTypeModel: () => ASSET_TYPE_MODEL,

        keyAssetStatusUnrequested: () => ASSET_STATUS_UNREQUESTED,
        keyAssetStatusRequested: () => ASSET_STATUS_REQUESTED,
        keyAssetStatusRetrieved: () => ASSET_STATUS_RETRIEVED,
        keyAssetStatusUnretrievable: () => ASSET_STATUS_UNRETRIEVABLE,

        keyFileKeyDefault: () => FILE_KEY_DEFAULT,
        keyFileKeyModified: () => FILE_KEY_MODIFIED,

        keyFileStatusPending: () => FILE_STATUS_PENDING,
        keyFileStatusError: () => FILE_STATUS_ERROR,
        keyFileStatusReady: () => FILE_STATUS_READY,

        keyImageSubAlbedo: () => IMAGE_SUB_ALBEDO,
        keyImageSubCanny: () => IMAGE_SUB_CANNY,
        keyImageSubDepth: () => IMAGE_SUB_DEPTH,
        keyImageSubIrradiance: () => IMAGE_SUB_IRRADIANCE,
        keyImageSubNormal: () => IMAGE_SUB_NORMAL,
        keyImageSubMetallic: () => IMAGE_SUB_METALLIC,
        keyImageSubRoughness: () => IMAGE_SUB_ROUGHNESS,
        keyImageSubSegment: () => IMAGE_SUB_SEGMENT,
        keyImageSubSketch: () => IMAGE_SUB_SKETCH,

        keyImageExportFormatDefault: () => IMAGE_EXPORT_FORMAT_DEFAULT,
        keyImageExportFormatPNG: () => IMAGE_EXPORT_FORMAT_PNG,
        keyImageExportFormatJPG: () => IMAGE_EXPORT_FORMAT_JPG,
        keyImageExportFormatBMP: () => IMAGE_EXPORT_FORMAT_BMP,
        keyImageExportFormatTIFF: () => IMAGE_EXPORT_FORMAT_TIFF,
        keyImageExportFormatWEBP: () => IMAGE_EXPORT_FORMAT_WEBP,

        keyModelGenerationQualityDefault: () => MODEL_GENERATION_QUALITY_DEFAULT,
        keyModelGenerationQualityLow: () => MODEL_GENERATION_QUALITY_LOW,
        keyModelGenerationQualityHigh: () => MODEL_GENERATION_QUALITY_HIGH,

        keyModelExportFormatDefault: () => MODEL_EXPORT_FORMAT_DEFAULT,
        keyModelExportFormatGLB: () => MODEL_EXPORT_FORMAT_GLB,
        keyModelExportFormatFBX: () => MODEL_EXPORT_FORMAT_FBX,
        keyModelExportFormatSTL: () => MODEL_EXPORT_FORMAT_STL,
        keyModelExportFormatPLY: () => MODEL_EXPORT_FORMAT_PLY,
        keyModelExportFormatOBJ: () => MODEL_EXPORT_FORMAT_OBJ,

        keyResolutionThumbnailDefault: () => RESOLUTION_THUMBNAIL_DEFAULT,

        isServiceStatusUp: state => state.service.status === SERVICE_STATUS_UP,
        isServiceStatusDown: state => state.service.status === SERVICE_STATUS_DOWN,

        isLoading: state => state.loading,

        isAssetTypeText: () => (asset) => asset.type === ASSET_TYPE_TEXT,
        isAssetTypeImage: () => (asset) => asset.type === ASSET_TYPE_IMAGE,
        isAssetTypeModel: () => (asset) => asset.type === ASSET_TYPE_MODEL,

        isFileDataReady: () => (data) => {
            if (data === null || data === undefined || data === '') return false
            if (data.startsWith('data:')) return true
            return false
        },

        hasVote: state => (key) => key in state.votes,

        getServiceStatus: state => state.service.status,
        getServiceDomains: state => state.service.domains,
        getServiceQueues: state => state.service.queues,

        getAssets: state => state.assets,
        getAsset: state => (assetId) => {
            return state.assets.find(asset => asset.id === assetId)
        },

        getTextAssets: state => {
            return state.assets.filter(asset => asset.type === ASSET_TYPE_TEXT)
        },
        getImageAssets: state => {
            return state.assets.filter(asset => asset.type === ASSET_TYPE_IMAGE)
        },
        getModelAssets: state => {
            return state.assets.filter(asset => asset.type === ASSET_TYPE_MODEL)
        },
        
        getFilteredAssets: state => (app = null, type = null, generated = null) => {
            return state.assets.filter(asset => {
                return (app === null || asset.app === app) &&
                       (type === null || asset.type === type) &&
                       (generated === null || asset.generated === generated)
            })
        },
        getChildAssets: state => (assetId) => {
            return state.assets.filter(asset => asset.parent === assetId)
        },

        getFileFromAsset: () => (asset, key = FILE_KEY_DEFAULT) => {
            if (asset === null || asset === undefined) return null
            if (key === null || key === undefined) return null
            if (key in asset.files) return asset.files[key]
            return null
        },

        getVote: state => (key) => {
            return state.votes[key]
        },

        getImageSubs: () => {
            return [
                IMAGE_SUB_ALBEDO,
                IMAGE_SUB_CANNY,
                IMAGE_SUB_DEPTH,
                IMAGE_SUB_IRRADIANCE,
                IMAGE_SUB_NORMAL,
                IMAGE_SUB_METALLIC,
                IMAGE_SUB_ROUGHNESS,
                IMAGE_SUB_SEGMENT,
                IMAGE_SUB_SKETCH
            ]
        },
        getImageExportFormats: () => {
            return [
                IMAGE_EXPORT_FORMAT_PNG,
                IMAGE_EXPORT_FORMAT_JPG,
                IMAGE_EXPORT_FORMAT_BMP,
                IMAGE_EXPORT_FORMAT_TIFF,
                IMAGE_EXPORT_FORMAT_WEBP
            ]
        },
        getImageExportExtension: () => (format) => {
            switch(format) {
                case IMAGE_EXPORT_FORMAT_PNG:
                    return 'png'
                case IMAGE_EXPORT_FORMAT_JPG:
                    return 'jpg'
                case IMAGE_EXPORT_FORMAT_BMP:
                    return 'bmp'
                case IMAGE_EXPORT_FORMAT_TIFF:
                    return 'tiff'
                case IMAGE_EXPORT_FORMAT_WEBP:
                    return 'webp'
            }
            return null
        },
        getImageExportMimeType: () => (format) => {
            switch(format) {
                case IMAGE_EXPORT_FORMAT_PNG:
                    return 'image/png'
                case IMAGE_EXPORT_FORMAT_JPG:
                    return 'image/jpg'
                case IMAGE_EXPORT_FORMAT_BMP:
                    return 'image/bmp'
                case IMAGE_EXPORT_FORMAT_TIFF:
                    return 'image/tiff'
                case IMAGE_EXPORT_FORMAT_WEBP:
                    return 'image/webp'
            }
            return null
        },

        getModelGenerationQualities: () => {
            return [
                MODEL_GENERATION_QUALITY_LOW,
                MODEL_GENERATION_QUALITY_HIGH
            ]
        },
        getModelGenerationQualityName: () => (quality) => {
            switch(quality) {
                case MODEL_GENERATION_QUALITY_LOW:
                    return 'Faster Generation'
                case MODEL_GENERATION_QUALITY_HIGH:
                    return 'Higher Quality'
            }
            return null
        },
        getModelExportFormats: () => {
            return [
                MODEL_EXPORT_FORMAT_GLB,
                MODEL_EXPORT_FORMAT_FBX,
                MODEL_EXPORT_FORMAT_STL,
                MODEL_EXPORT_FORMAT_PLY,
                MODEL_EXPORT_FORMAT_OBJ
            ]
        },
        getModelExportExtension: () => (format) => {
            switch(format) {
                case MODEL_EXPORT_FORMAT_GLB:
                    return 'glb'
                case MODEL_EXPORT_FORMAT_FBX:
                    return 'fbx'
                case MODEL_EXPORT_FORMAT_STL:
                    return 'stl'
                case MODEL_EXPORT_FORMAT_PLY:
                    return 'ply'
                case MODEL_EXPORT_FORMAT_OBJ:
                    return 'obj'
            }
            return null
        },
        getModelExportMimeType: () => (format) => {
            switch(format) {
                case MODEL_EXPORT_FORMAT_GLB:
                    return 'model/gltf-binary'
                case MODEL_EXPORT_FORMAT_FBX:
                    return 'model/fbx'
                case MODEL_EXPORT_FORMAT_STL:
                    return 'model/stl'
                case MODEL_EXPORT_FORMAT_PLY:
                    return 'model/ply'
                case MODEL_EXPORT_FORMAT_OBJ:
                    return 'model/obj'
            }
            return null
        }
    },
    actions: {

        //// INITIALIZATION

        // initialize the API
        async initializeAPI({ dispatch })
        {
            // get monitoring status
            dispatch('monitoringStatusGet')

            // initialize user
            dispatch('initializeUser')
        },

        // intialize the user
        async initializeUser({ dispatch, commit, rootGetters })
        {
            // check if user is authenticated
            if (rootGetters['app/isAuthenticated'])
            {
                // empty list of assets
                commit('RESET_ASSETS')

                // get all assets
                // IMPORTANT: requires initializeApp to be called before initializeAPI
                dispatch('assetsGet')
            }
        },



        //// USERS

        // users register (post)
        async usersRegisterPost({ dispatch }, {
            email,
            mode = 'dark',
            theme = 1,
            additional = null
        })
        {
            // ensure email parameter is provided
            if (email === null || email === undefined) {
                throw new Error("Please provide an email to register your account.")
            }

            // payload
            const payload = {
                email,
                mode,
                theme,
                additional
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_REGISTER,
                method: REST_POST,
                payload
            })
        },

        // users activate (put)
        async usersActivatePut({ dispatch }, {
            token
        })
        {
            // ensure token parameter is provided
            if (token === null || token === undefined) {
                throw new Error("Please provide a token to activate your account.")
            }

            // payload
            const payload = {
                token
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_ACTIVATE,
                method: REST_PUT,
                payload
            })
        },

        // users deactivate (put)
        async usersDeactivatePut({ dispatch })
        {
            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_DEACTIVATE,
                method: REST_PUT
            })
        },

        // users token (post)
        async usersTokenPost({ dispatch }, {
            email,
            password
        })
        {
            // ensure email parameter is provided
            if (email === null || email === undefined) {
                throw new Error("Please provide an email to login.")
            }

            // ensure password parameter is provided
            if (password === null || password === undefined) {
                throw new Error("Please provide a password to login.")
            }

            // payload
            const payload = {
                email,
                password
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_TOKEN,
                method: REST_POST,
                payload
            })
        },

        // users password forgot (post)
        async usersPasswordForgotPost({ dispatch }, {
            email
        })
        {
            // ensure email parameter is provided
            if (email === null || email === undefined) {
                throw new Error("Please provide an email to reset your password.")
            }

            // payload
            const payload = {
                email
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_PASSWORD_FORGOT,
                method: REST_POST,
                payload
            })
        },

        // users password change (put)
        async usersPasswordChangePut({ dispatch }, {
            token,
            password
        })
        {
            // ensure password parameter is provided
            if (password === null || password === undefined) {
                throw new Error("Please provide a new password to change your old password.")
            }

            // payload
            const payload = {
                password: password
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_PASSWORD_CHANGE,
                method: REST_PUT,
                payload,
                token
            })
        },

        // users statistics (get)
        async usersStatisticsGet({ dispatch })
        {
            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_STATISTICS,
                method: REST_GET
            })
        },

        // users design (get)
        async usersDesignGet({ dispatch })
        {
            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_DESIGN,
                method: REST_GET
            })
        },

        // users design (put)
        async usersDesignPut({ dispatch }, {
            mode = 'dark',
            theme = 0
        })
        {
            // payload
            const payload = {
                mode,
                theme
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_DESIGN,
                method: REST_PUT,
                payload
            })
        },

        // users newsletter (get)
        async usersNewsletterGet({ dispatch })
        {
            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_NEWSLETTER,
                method: REST_GET
            })
        },

        // users newsletter (post)
        async usersNewsletterPost({ dispatch }, {
            subscribed = false
        })
        {
            // payload
            const payload = {
                subscribed
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_NEWSLETTER,
                method: REST_POST,
                payload
            })
        },

        // users additional (get)
        async usersAdditionalGet({ dispatch, commit })
        {
            try
            {
                // send API request
                const response = await dispatch('requestApi', {
                    url: ROUTE_USERS_ADDITIONAL,
                    method: REST_GET
                })

                // TODO fix response to only provide additional
                const additional = response?.message ?? {}

                return additional
            }
            catch(error)
            {
                throw error
            }
        },

        // users additional (put)
        async usersAdditionalPut({ dispatch, commit }, additional)
        {
            // payload
            const payload = {
                additional
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_ADDITIONAL,
                method: REST_PUT,
                payload
            })
        },



        //// APPS

        // apps {app} additional (get)
        async appsAppAdditionalGet({ dispatch, rootGetters })
        {
            // get app name
            const app = rootGetters['app/getAppname']

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_APPS_APP_ADDITIONAL(app),
                method: REST_GET
            })
        },

        // apps {app} additional (put)
        async appsAppAdditionalPut({ dispatch, rootGetters }, {
            additional = null
        })
        {
            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                additional
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_APPS_APP_ADDITIONAL(app),
                method: REST_PUT,
                payload
            })
        },



        //// ASSETS

        // assets (get)
        async assetsGet({ commit, dispatch })
        {
            try
            {
                commit('SET_LOADING', true)

                // send API request and await the response
                const assets = await dispatch('requestApi', {
                    url: ROUTE_ASSETS,
                    method: REST_GET
                })

                // clear the list of assets
                commit('RESET_ASSETS')

                // iterate through all assets and add them
                assets.forEach(asset => {
                    commit('ADD_ASSET', asset)
                })

                return assets
            }
            catch (error)
            {
                throw error
            }
            finally 
            {
                commit('SET_LOADING', false)
            }
        },

        // assets (post)
        async assetsPost({ commit, rootGetters, dispatch }, {
            fileData,
            fileKey = null,
            fileDetails = null,
            process = null, // e.g., for image: { mode: limit/fixed, resolution: 512 }
            additional = null,
            shared = false
        })
        {
            // ensure parameter file data is present
            if (fileData === null || fileData === undefined) {
                throw new Error("Please provide a file for the asset.")
            }

            try
            {
                // get app name
                const app = rootGetters['app/getAppname']

                // prepare payload
                const payload = {
                    file_data: fileData,
                    app,
                    shared
                }
                // optional
                if (fileKey !== null) payload.file_key = fileKey
                if (fileDetails !== null) payload.file_details = fileDetails
                if (process !== null) payload.process = process
                if (additional !== null) payload.additional = additional

                // send API request and await the response
                const asset = await dispatch('requestApi', {
                    url: ROUTE_ASSETS,
                    method: REST_POST,
                    payload
                })

                // directly inject the uploaded file (if possible)
                if (asset !== null && asset !== undefined)
                {
                    // ensure base type with no converting taking place
                    if (fileData.startsWith('data:text/plain') ||
                        fileData.startsWith('data:image/png') ||
                        fileData.startsWith('data:model/gltf-binary'))
                    {
                        // no processing required
                        if (process === null) {
                            asset.files[fileKey] = {
                                status: ASSET_STATUS_RETRIEVED,
                                data: fileData,
                                details: fileDetails
                            }
                        }
                    }
                }

                // add local copy of asset
                commit('ADD_OR_UPDATE_ASSET', asset)

                return asset
            }
            catch (error)
            {
                throw error
            }
        },

        // assets {asset} (get)
        // TODO add flag to determine whether the retrieved asset will be updated in the local asset storage or not (useful to avoid shared assets in the users library)
        async assetsAssetGet({ dispatch }, {
            assetId,
            useToken = true
        })
        {
            // ensure url parameter is present
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id.")
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ASSETS_ASSET(assetId),
                method: REST_GET,
                useToken
            })
        },

        // assets {asset} (delete)
        async assetsAssetDelete({ commit, dispatch }, {
            assetId
        })
        {
            // ensure url parameter are present
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id.")
            }

            try
            {
                // send API request and await the response
                const asset = await dispatch('requestApi', {
                    url: ROUTE_ASSETS_ASSET(assetId),
                    method: REST_DELETE
                })

                // remove local copy of asset
                commit('REMOVE_ASSET', assetId)

                return asset
            }
            catch(error)
            {
                throw error
            }
        },

        // assets {asset} clone (post)
        async assetsAssetClonePost({ commit, dispatch, rootGetters }, {
            assetId,
            keys = [ 'default' ], // TODO rename to fileKeys
            additional = null,
        })
        {
            // ensure url parameter is present
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id.")
            }

            try
            {
                // get app name
                const app = rootGetters['app/getAppname']

                // payload
                const payload = {
                    app,
                    keys,
                    additional
                }

                // send API request and await the response
                const asset = await dispatch('requestApi', {
                    url: ROUTE_ASSETS_ASSET_CLONE(assetId),
                    method: REST_POST,
                    payload
                })

                // add local copy of asset
                commit('ADD_OR_UPDATE_ASSET', asset)

                return asset
            }
            catch (error)
            {
                throw error
            }
        },

        // assets {asset} additional (get)
        async assetsAssetAdditionalGet({ dispatch }, {
            assetId
        })
        {
            // ensure url parameter is present
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id.")
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ASSETS_ASSET_ADDITIONAL(assetId),
                method: REST_GET
            })
        },

        // assets {asset} additional (put)
        async assetsAssetAdditionalPut({ dispatch }, {
            assetId,
            additional
        })
        {
            // ensure url parameter is present
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id.")
            }

            // ensure additional parameter is present
            if (additional === null || additional === undefined) {
                throw new Error("Please provide the parameter additional.")
            }

            // payload
            const payload = {
                additional
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ASSETS_ASSET_ADDITIONAL(assetId),
                method: REST_PUT,
                payload
            })
        },

        // assets {asset} thumbnail (get)
        async assetsAssetThumbnailGet({ dispatch }, {
            asset,
            animate = true,
            untilReady = true,
            timeIncrement = 300, // in milliseconds (0.3 secs)
            timeMaximum = 15000, // in milliseconds (15 secs)
        })
        {
            // ensure the asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset.")
            }

            // set status to indicate thumbnail is being requested
            asset.thumbnail.status = ASSET_STATUS_REQUESTED

            // helper function to request the thumbnail
            const requestThumbnail = async () => {
                try
                {
                    // payload
                    const payload = {
                        animate
                    }

                    // send API request to get the thumbnail
                    const thumbnail = await dispatch('requestApi', {
                        url: asset.thumbnail.url,
                        method: REST_GET,
                        payload
                    })

                    // update thumbnail of asset
                    asset.thumbnail.data = thumbnail
                    asset.thumbnail.status = ASSET_STATUS_RETRIEVED

                    return thumbnail
                }
                catch (error)
                {
                    // update thumbnail status
                    asset.thumbnail.status = ASSET_STATUS_RETRIEVED

                    // throw the error to propagate it upwards
                    throw error
                }
            }

            // wait for asset to be ready
            try
            {
                // construct the URL for the API request
                const url = ROUTE_ASSETS_ASSET_THUMBNAIL_STATUS(asset.id)

                // once the asset is ready 
                const status = await dispatch('assetsAssetStatusHelper', {
                    url,
                    untilReady,
                    timeIncrement,
                    timeMaximum
                })

                // check if status is ready
                if (status && status.status === 'ready')
                {
                    // after asset status is ready, request the thumbnail
                    const thumbnail = await requestThumbnail()
                    return thumbnail
                }

                // if the thumbnail is not ready, flag error
                asset.thumbnail.status = ASSET_STATUS_UNRETRIEVABLE

                return null
            }
            catch (error)
            {
                throw error
            }
        },

        // assets {asset} files (get)
        async assetsAssetFilesGet({ dispatch }, {
            asset,
            untilReady = true,
            timeIncrement = 300, // in milliseconds (0.3 secs)
            timeMaximum = 15000, // in milliseconds (15 secs)
        })
        {
            // ensure asset parameter is present
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset.")
            }

            // set the status to indicate that files are being requested
            for (const key in asset.files) {
                asset.files[key].status = ASSET_STATUS_REQUESTED
            }

            // helper function to request the files
            const requestFiles = async () => {
                try
                {
                    // send API request to get the asset files
                    const files = await dispatch('requestApi', {
                        url: ROUTE_ASSETS_ASSET_FILES(asset.id),
                        method: REST_GET
                    })

                    // iterate through files and update their status and data
                    for (const key in asset.files) {
                        asset.files[key].status = ASSET_STATUS_RETRIEVED
                        asset.files[key].data = files[key].data
                        asset.files[key].details = files[key].details
                    }

                    return files
                }
                catch (error)
                {
                    // handle any errors: mark all files as retrieved (to avoid loops)
                    for (const key in asset.files) {
                        asset.files[key].status = ASSET_STATUS_RETRIEVED
                    }

                    // propagate the error upwards
                    throw error
                }
            }

            // wait for asset to be ready
            try {

                // construct the URL for the API request
                const url = ROUTE_ASSETS_ASSET_FILES_STATUS(asset.id)

                // once the asset is ready 
                const status = await dispatch('assetsAssetStatusHelper', {
                    url,
                    untilReady,
                    timeIncrement,
                    timeMaximum
                })
                
                // check if status is ready
                if (status && status.status === FILE_STATUS_READY)
                {
                    // after the asset status is ready, request the files
                    const files = await requestFiles()
                    return files
                }

                return null
            }
            catch (error)
            {
                throw error
            }
        },

        // assets {asset} files (post)
        async assetsAssetFilesPost({ commit, dispatch }, {
            assetId, 
            file, // TODO rename to fileData
            key = 'default', // TODO rename to fileKey
            details = {}, // TODO rename to fileDetails
            overwrite = true
        })
        {
            // ensure url parameter is present
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id.")
            }

            try
            {
                // payload
                const payload = {
                    files: {
                        [key]: {
                            data: file,
                            details: details
                        }
                    },
                    overwrite
                }

                // send API request and return the promise
                const asset = await dispatch('requestApi', {
                    url: ROUTE_ASSETS_ASSET_FILES(assetId),
                    method: REST_POST,
                    payload,
                })

                // update local asset
                commit('ADD_OR_UPDATE_ASSET', asset)

                return asset
            }
            catch(error)
            {
                throw error
            }
        },

        // assets {asset} file {key} (get)
        async assetsAssetFileKeyGet({ dispatch, getters }, {
            asset,
            key = 'default', // TODO rename to fileKey
            untilReady = true,
            timeIncrement = 300, // in milliseconds (0.3 secs)
            timeMaximum = 30000, // in milliseconds (30 secs)
            onPending = null, // callback for when the asset is still pending
            onReady = null, // callback for when the asset is ready
            onError = null, // callback for when the asset has an error
            useToken = true
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset.")
            }

            // ensure asset has a file with the specified key
            if (!asset.files.hasOwnProperty(key)) {
                throw new Error(`File key ('${key}') not found in asset.`)
            }

            // get file referenced by key
            const file = asset.files[key]

            // if the file has already been retrieved, return it directly
            if (file.status === getters.keyAssetStatusRetrieved) {
                return { data: file.data, details: file.details }
            }

            // set status to indicate the file is being requested
            file.status = ASSET_STATUS_REQUESTED

            // helper function to request the file from the server
            const requestFile = async () => {
                try
                {
                    // send the API request to get the file
                    const response = await dispatch('requestApi', {
                        url: file.url,
                        method: REST_GET,
                        useToken
                    })

                    // update file status and data
                    file.data = response.data
                    file.details = response.details
                    file.status = ASSET_STATUS_RETRIEVED

                    return response
                }
                catch (error)
                {
                    // set status to indicate the file has been retrieved, even if there was an error
                    file.status = ASSET_STATUS_RETRIEVED

                    // propagate the error up the chain
                    throw error
                }
            }

            // wait for the asset to be ready
            try 
            {
                // construct the URL for the API request
                const url = ROUTE_ASSETS_ASSET_FILE_KEY_STATUS(asset.id, key)

                // once the asset is ready 
                const status = await dispatch('assetsAssetStatusHelper', {
                    url,
                    untilReady,
                    timeIncrement,
                    timeMaximum,
                    onPending,
                    useToken
                })
                
                // check if status is ready
                if (status && status.status === FILE_STATUS_READY)
                {
                    // trigger the onReady callback
                    if (typeof onReady === 'function') {
                        onReady()
                    }

                    // request the file
                    const file = await requestFile()
                    return file
                }

                // trigger the onError callback
                if (typeof onError === 'function') {
                    onError()
                }

                // if the asset is not ready, flag error
                file.status = ASSET_STATUS_UNRETRIEVABLE

                return null
            }
            catch (error)
            {
                throw error
            }
        },

        async assetsAssetFileKeyStatusGet({ dispatch, getters }, {
            asset,
            key = 'default', // TODO rename to fileKey
            untilReady = true,
            timeIncrement = 300, // in milliseconds (0.3 secs)
            timeMaximum = 30000, // in milliseconds (30 secs)
            onPending = null // callback for when the asset is still pending
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset.")
            }

            // ensure asset has a file with the specified key
            if (!asset.files.hasOwnProperty(key)) {
                throw new Error(`File key ('${key}') not found in asset.`)
            }

            // wait for the asset to be ready
            try 
            {
                // construct the URL for the API request
                const url = ROUTE_ASSETS_ASSET_FILE_KEY_STATUS(asset.id, key)

                // once the asset is ready 
                const status = await dispatch('assetsAssetStatusHelper', {
                    url,
                    untilReady,
                    timeIncrement,
                    timeMaximum,
                    onPending
                })
                
                return status
            }
            catch (error)
            {
                throw error
            }
        },

        // assets {asset} share (get)
        // information: shared assets are not added to local library
        async assetsAssetShareGet({ dispatch }, {
            assetId
        })
        {
            // IMPORTANT get shared asset not stored in local list of assets

            // ensure url parameter is present
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id.")
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ASSETS_ASSET_SHARE(assetId),
                method: REST_GET
            })
        },

        // assets {asset} share (put)
        async assetsAssetSharePut({ dispatch }, {
            asset,
            shared = 0 // 0: not shared, 1: privately shared, 2: publicly shared
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset.")
            }

            // ensure shared parameter is valid
            if (shared < 0 || shared > 2) {
                throw new Error("Please provide a valid shared status.")
            }

            // payload to send with the request
            const payload = {
                shared: shared
            }

            try
            {
                // send API request to update the shared status
                const response = await dispatch('requestApi', {
                    url: ROUTE_ASSETS_ASSET_SHARE(asset.id),
                    method: REST_PUT,
                    payload
                })

                // update the asset's shared field based on the response
                asset.shared = response.shared

                return response
            }
            catch (error)
            {
                throw error
            }
        },

        // helper function
        async assetsAssetStatusHelper({ dispatch }, {
            url, 
            untilReady = true,
            timeIncrement = 300, // in milliseconds (0.3 secs)
            timeMaximum = 15000, // in milliseconds (15 secs)
            onPending = null, // callback function when status is pending
            useToken = true	
        })
        {
            // ensure url parameter is present
            if (url === null || url === undefined) {
                throw new Error("Please provide the url to retrieve the status.")
            }

            // save how much time has passed
            let timePassed = 0

            // helper function to delay execution
            const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))

            // function to poll the status until ready
            const requestStatus = async (timeCurrent) => {
                try
                {
                    // send API request to get asset status
                    const status = await dispatch('requestApi', {
                        url,
                        method: REST_GET,
                        useToken
                    })

                    // if untilReady is true and the asset is not ready, poll again after a delay
                    if (untilReady && status.status === FILE_STATUS_PENDING && timePassed < timeMaximum)
                    {
                        // Call the callback function if provided
                        if (typeof onPending === 'function') {
                            onPending({ timePassed, timeIncrement: timeCurrent })
                        }
                        
                        // wait for the specified time increment
                        await delay(timeCurrent)

                        // update passed time
                        timePassed += timeCurrent

                        // recursively call the requestStatus function to check again
                        return requestStatus(timeCurrent + timeIncrement)
                    }
                    else
                    {
                        return status
                    }
                }
                catch (error)
                {
                    throw error
                }
            }

            // start the polling process
            return requestStatus(timePassed)
        },

        // helper function to simplify downloads (not directly an API route)
        async assetsAssetDownloadHelper({ dispatch, getters }, {
            asset,
            key = 'default', // rename to fileKey
            format = null
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to download.")
            }

            try
            {
                // create asset file name
                const fileName = `${asset.type}_${asset.id}`

                // images
                if (getters.isAssetTypeImage(asset)) {

                    // get file mime type and extension
                    const fileMime = getters.getImageExportMimeType(format)
                    const fileExtension = getters.getImageExportExtension(format)

                    // check if base format is requested
                    if (format === IMAGE_EXPORT_FORMAT_DEFAULT)
                    {
                        // ensure asset file is retrieved
                        await dispatch('assetsAssetFileKeyGet', {
                            asset,
                            key
                        })

                        // get asset file data
                        const base64Data = asset.files[key].data
                        const binaryData = base64ToArrayBuffer(base64Data)

                        downloadFile(binaryData, fileMime, fileName, fileExtension)
                        return
                    }
                    else
                    {
                        // call route to convert image asset
                        const responseConvert = await dispatch('imagesImageConvertPost', {
                            assetId: asset.id,
                            fileKey: key,
                            format: format
                        })

                        const binaryData = base64ToArrayBuffer(responseConvert)
                        downloadFile(binaryData, fileMime, fileName, fileExtension)

                        return
                    }
                }

                // models
                else if (getters.isAssetTypeModel(asset)) {

                    // get file mime type and extension
                    const fileMime = getters.getModelExportMimeType(format)
                    const fileExtension = getters.getModelExportExtension(format)

                    // check if base format is requested
                    if (format === MODEL_EXPORT_FORMAT_DEFAULT)
                    {
                        // ensure asset file is retrieved
                        await dispatch('assetsAssetFileKeyGet', {
                            asset,
                            key
                        })

                        // get asset file data
                        const base64Data = asset.files[key].data
                        const binaryData = base64ToArrayBuffer(base64Data)

                        downloadFile(binaryData, fileMime, fileName, fileExtension)
                        return
                    }
                    else
                    {
                        // call route to convert model asset
                        const responseConvert = await dispatch('modelsModelConvertPost', {
                            assetId: asset.id,
                            fileKey: key,
                            format: format
                        })

                        const binaryData = base64ToArrayBuffer(responseConvert)
                        downloadFile(binaryData, fileMime, fileName, fileExtension)

                        return
                    }

                }

                // no supported
                else {
                    throw new Error("Asset type not supported.")
                }
            }
            catch(error)
            {
                console.log(error)
                throw error
            }
        },



        //// TEXT

        // texts generic generate (post)
        async textsGeneratePost({ commit, dispatch, rootGetters }, {
            query,
            template = null,
            additional = null
        })
        {
            // get app name
            const app = rootGetters['app/getAppname']

            // simulate asset
            const asset = {
                id: 'text-example',
                type: ASSET_TYPE_TEXT,
                app,
                files: {
                    'default': {
                        status: ASSET_STATUS_RETRIEVED,
                        data: '',
                        details: {
                            material: {
                                ambientColor: { r: 0, g: 255, b: 0 },
                                diffuseColor: { r: 0, g: 255, b: 0 },
                                specularColor: { r: 255, g: 0, b: 255 },
                                emissiveColor: { r: 0, g: 100, b: 0 },
                                specularIntensity: 30,
                                emissiveIntensity: 0,
                                roughness: 1,
                                metalness: 0
                            }
                        },
                    }
                },
                additional
            }

            // add to assets
            commit('ADD_OR_UPDATE_ASSET', asset)

            return asset
        },

        // texts render route



        //// IMAGES

        // images from prompt generate (post)
        async imagesFromPromptGeneratePost({ commit, dispatch, rootGetters }, {
            positive = '',
            negative = '',
            seeds = [ -1 ],
            alpha = false,
            fillActive = true,
            fillMargin = 32,
            additional = null,
            resolution = 512,
            model = "generio-v1-sfw",
            adherence = 2.0,
            denoising = 1.0,
            steps = 6,
            style = null
        })
        {
            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                prompt: {
                    positive,
                    negative
                },
                seeds,
                alpha, 
                fill: {
                    active: fillActive,
                    margin: fillMargin
                },
                additional,
                app,
                resolution,
                diffusion: {
                    model,
                    adherence,
                    denoising,
                    steps
                },
                style
            }

            try
            {
                // send API request to generate images from prompt
                const assets = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_FROMPROMPT_GENERATE,
                    method: REST_POST,
                    payload
                })

                // iterate through response
                assets.forEach(asset => {

                    // add asset to assets list
                    commit('ADD_OR_UPDATE_ASSET', asset)
                })

                return assets
            }
            catch(error)
            {
                throw error
            }
        },

        // images from guidance generate (post)
        async imagesFromGuidanceGeneratePost({ commit, dispatch, rootGetters }, {
            parentId,
            positive = '',
            negative = '',
            fileKey = 'default',
            process = null,
            strength = 0,
            seeds = [ -1 ],
            alpha = false,
            fillActive = true,
            fillMargin = 32,
            additional = null,
            resolution = 512,
            model = "generio-v1-sfw",
            adherence = 2.0,
            denoising = 1.0,
            steps = 6,
            style = null
        })
        {
            // ensure parentId parameter is provided
            if (parentId === null || parentId === undefined) {
                throw new Error("Please provide a guiding asset.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                prompt: {
                    positive,
                    negative
                },
                guidance: {
                    parent_id: parentId,
                    sources: [
                        {
                            file_key: fileKey,
                            process,
                            strength
                        }
                    ]
                },
                seeds,
                alpha,
                fill: {
                    active: fillActive,
                    margin: fillMargin
                },
                app,
                resolution,
                diffusion: {
                    model,
                    adherence,
                    denoising,
                    steps
                }
            }
            // optional
            if (additional !== null) payload.additional = additional
            if (style !== null) payload.style = style

            try
            {
                // send API request to generate images from guidance
                const assets = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_FROMGUIDANCE_GENERATE,
                    method: REST_POST,
                    payload
                })

                // iterate through response
                assets.forEach(asset => {

                    // add asset to assets list
                    commit('ADD_OR_UPDATE_ASSET', asset)
                })

                return assets
            }
            catch(error)
            {
                throw error
            }
        },

        // images {image} convert (post)
        async imagesImageConvertPost({ getters, dispatch }, {
            assetId,
            fileKey = 'default',
            format = IMAGE_EXPORT_FORMAT_DEFAULT
        })
        {
            // IMPORTANT convert does not save the asset in the vuex state
        
            // ensure asset parameter is provided
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id for the image.")
            }
        
            // payload
            const payload =  {
                mime: getters.getImageExportMimeType(format),
                file_key: fileKey,
            }
        
            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_IMAGES_IMAGE_CONVERT(assetId),
                method: REST_POST,
                payload
            })
        },

        // images {image} albedo generate (post)
        async imagesImageAlbedoGeneratePost({ commit, dispatch, rootGetters }, {
            asset,
            key = null, // TODO rename to fileKey; if specified, depth stored as file of the asset using the file key; otherwise, new asset created
            additional = null,
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a image asset.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                app
            }
            // optional
            if (key !== null) payload.file_key = key
            if (additional !== null) payload.additional = additional

            try
            {
                // send API request to generate depth image
                asset = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_IMAGE_SUB_ALBEDO_GENERATE(asset.id),
                    method: REST_POST,
                    payload
                })
                
                // update or add asset
                commit('ADD_OR_UPDATE_ASSET', asset)

                return asset
            }
            catch(error)
            {
                throw error
            }
        },

        // images {image} canny generate (post)
        async imagesImageCannyGeneratePost({ commit, dispatch, rootGetters }, {
            asset,
            key = null, // TODO rename to fileKey; if specified, canny stored as file of the asset using the file key; otherwise, new asset created
            additional = null,
            thresholdHigh = 200,
            thresholdLow = 100
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a image asset.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                threshold: {
                    high: thresholdHigh,
                    low: thresholdLow
                },
                app
            }
            // optional
            if (key !== null) payload.file_key = key
            if (additional !== null) payload.additional = additional

            try
            {
                // send API request to generate canny image
                asset = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_IMAGE_SUB_CANNY_GENERATE(asset.id),
                    method: REST_POST,
                    payload
                })

                // update or add asset
                commit('ADD_OR_UPDATE_ASSET', asset)

                return asset
            }
            catch(error)
            {
                throw error
            }
        },

        // images {image} depth generate (post)
        async imagesImageDepthGeneratePost({ dispatch, rootGetters }, {
            asset,
            key = null, // TODO rename to fileKey; if specified, depth stored as file of the asset using the file key; otherwise, new asset created
            additional = null,
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a image asset.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                app
            }
            // optional
            if (key !== null) payload.file_key = key
            if (additional !== null) payload.additional = additional

            try
            {
                // send API request to generate depth image
                asset = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_IMAGE_SUB_DEPTH_GENERATE(asset.id),
                    method: REST_POST,
                    payload
                })

                // update or add asset
                commit('ADD_OR_UPDATE_ASSET', asset)

                return asset
            }
            catch(error)
            {
                throw error
            }
        },

        // images {image} metallic generate (post)
        async imagesImageMetallicGeneratePost({ commit, dispatch, rootGetters }, {
            asset,
            key = null, // TODO rename to fileKey; if specified, depth stored as file of the asset using the file key; otherwise, new asset created
            additional = null,
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a image asset.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                app
            }
            // optional
            if (key !== null) payload.file_key = key
            if (additional !== null) payload.additional = additional

            try
            {
                // send API request to generate depth image
                asset = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_IMAGE_SUB_METALLIC_GENERATE(asset.id),
                    method: REST_POST,
                    payload
                })
                
                // update or add asset
                commit('ADD_OR_UPDATE_ASSET', asset)

                return asset
            }
            catch(error)
            {
                throw error
            }
        },

        // images {image} normal generate (post)

        // images {image} segment generate (post)
        async imagesImageSegmentGeneratePost({ dispatch }, {
            asset,
            key = null, // TODO rename to fileKey; if specified, segment stored as file of the asset using the file key; otherwise, new asset created
            additional = null,
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a image asset.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                app
            }
            // optional
            if (key !== null) payload.file_key = key
            if (additional !== null) payload.additional = additional

            try
            {
                // send API request to generate segment image
                asset = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_IMAGE_SUB_SEGMENT_GENERATE(asset.id),
                    method: REST_POST,
                    payload
                })

                // update or add asset
                commit('ADD_OR_UPDATE_ASSET', asset)

                return asset
            }
            catch(error)
            {
                throw error
            }
        },

        // images {image} sketch generate (post)
        async imagesImageSketchGeneratePost({ commit, dispatch, rootGetters }, {
            asset,
            fileKey = null, // fileKey specified, sketch stored as file of the asset using the file key; otherwise, new asset created
            additional = null,
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a image asset.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                app
            }
            // optional
            if (fileKey !== null) payload.file_key = fileKey
            if (additional !== null) payload.additional = additional

            try
            {
                // send API request to generate sketch image
                asset = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_IMAGE_SUB_SKETCH_GENERATE(asset.id),
                    method: REST_POST,
                    payload
                })

                // update or add asset
                commit('ADD_OR_UPDATE_ASSET', asset)

                return asset
            }
            catch(error)
            {
                throw error
            }
        },

        // images {image} convert (post)

        // images {image} modify alpha add (post)
        async imagesImageModifyAlphaAddPost({ commit, dispatch, rootGetters }, {
            asset,
            fileKey = null,
            additional = null,
            threshold = null,
            mode = "bg",
            fillActive = false,
            fillMargin = 0
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a image asset.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                app,
                threshold,
                mode,
                fill: {
                    active: fillActive,
                    margin: fillMargin
                }
            }
            // optional
            if (fileKey !== null) payload.file_key = fileKey
            if (additional !== null) payload.additional = additional
            if (threshold !== null) payload.threshold = threshold

            try
            {
                // send API request to generate transparent image (or layer)
                asset = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_IMAGE_MODIFY_ALPHA_ADD(asset.id),
                    method: REST_POST,
                    payload
                })

                // update or add asset
                commit('ADD_OR_UPDATE_ASSET', asset)

                return asset
            }
            catch(error)
            {
                throw error
            }
        },

        // images {image} modify alpha remove (post)
        async imagesImageModifyAlphaRemovePost({ commit, dispatch }, {
            asset,
            key = null,
            additional = null,
            background = [0, 0, 0],
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a image asset.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                app,
                background
            }
            // optional
            if (key !== null) payload.key = key
            if (additional !== null) payload.additional = additional

            try
            {
                // send API request to generate opaque image
                asset = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_IMAGE_MODIFY_ALPHA_REMOVE(asset.id),
                    method: REST_POST,
                    payload
                })

                // update or add asset
                commit('ADD_OR_UPDATE_ASSET', asset)

                return asset
            }
            catch(error)
            {
                throw error
            }
        },

        // images {image} modify annotation remove (post)
        async imagesImageModifyAnnotionRemovePost({ commit, dispatch, rootGetters }, {
            asset,
            key = null,
            confidence = 0.3,
            additional = null,
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a image asset.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                app,
                confidence
            }
            // optional
            if (key !== null) payload.key = key
            if (additional !== null) payload.additional = additional

            try
            {
                // send API request to generate opaque image
                asset = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_IMAGE_MODIFY_ANNOTATIONS_REMOVE(asset.id),
                    method: REST_POST,
                    payload
                })

                // update or add asset
                commit('ADD_OR_UPDATE_ASSET', asset)

                return asset
            }
            catch(error)
            {
                throw error
            }
        },

        // images {image} render (post)
        async imagesImageRenderPost({ commit, dispatch }, {
            asset,
            key = null,
            resolution = 128,
            additional = null
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a image asset.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                app,
                resolution
            }
            // optional
            if (key !== null) payload.key = key
            if (additional !== null) payload.additional = additional

            try
            {
                // send API request to render image
                asset = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_IMAGE_RENDER(asset.id),
                    method: REST_POST,
                    payload
                })

                // update or add asset
                commit('ADD_OR_UPDATE_ASSET', asset)

                return response
            }
            catch(error)
            {
                throw error
            }
        },




        //// MODELS

        // models from image generate (post)
        async modelsFromImageGeneratePost({ commit, dispatch, rootGetters }, {
            sourceAssets = null, 
            sourceFileKey = 'default',
            targetFileKey = null,
            fileKey = null,
            additional = null,
            quality = MODEL_GENERATION_QUALITY_HIGH,
            seeds = [ -1 ],
            lod = 0.95,
            geometry_adherence = 7.5,
            material_adherence = 3.0
        })
        {
            // ensure sourceAssetId parameter is provided
            if (sourceAssets === null || sourceAssets === undefined) {
                throw new Error("Please provide a source asset id to generate a model.")
            }

            // build sources
            const sources = []
            for (const key in sourceAssets) {
                sources.push({
                    asset_id: sourceAssets[key].id,
                    file_key: sourceFileKey
                })
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                sources,
                target: {},
                app,
                quality,
                seeds,
                lod,
                geometry_adherence,
                material_adherence
            }

            // add optional parameter to payload
            if (targetFileKey !== null) {
                payload.target.file_key = targetFileKey
            }
            if (additional !== null) {
                payload.additional = additional
            }

            try
            {
                // send API request to generate model(s)
                const response = await dispatch('requestApi', {
                    url: ROUTE_MODELS_FROMIMAGE_GENERATE,
                    method: REST_POST,
                    payload
                })

                // if file key is provided, then only one model has been generated
                if (fileKey !== null)
                {
                    // add model asset to assets list
                    commit('ADD_OR_UPDATE_ASSET', response)
                }
                // response contains array of model(s)
                else
                {
                    // iterate through response
                    response.forEach(model => {

                        // add model asset to assets list
                        commit('ADD_OR_UPDATE_ASSET', model)
                    })
                }

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // models from script generate (post)
        async modelsFromScriptGeneratePost({ commit, dispatch, rootGetters }, {
            script,
            libraries = [],
            additional = null
        })
        {
            // ensure script parameter is provided
            if (script === null || script === undefined) {
                throw new Error("Please provide a script to generate a model.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                script,
                libraries,
                additional,
                app
            }

            try
            {
                // send API request to generate model
                const response = await dispatch('requestApi', {
                    url: ROUTE_MODELS_FROMSCRIPT_GENERATE,
                    method: REST_POST,
                    payload
                })

                // add asset to assets list
                commit('ADD_OR_UPDATE_ASSET', response)

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // models {model} convert (post)
        async modelsModelConvertPost({ getters, dispatch }, {
            assetId,
            fileKey = 'default',
            format = MODEL_EXPORT_FORMAT_DEFAULT
        })
        {
            // IMPORTANT convert does not save the asset in the vuex state

            // ensure asset parameter is provided
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id for the model.")
            }

            // payload
            const payload =  {
                mime: getters.getModelExportMimeType(format),
                file_key: fileKey,
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_MODELS_MODEL_CONVERT(assetId),
                method: REST_POST,
                payload
            })
        },

        // models {model} modify (post)
        // models {model} modify pose (post)
        // models {model} modify surface (post)
        // models {model} modify topology (post)

        // models {model} render (post)



        //// LOGGING

        // logging event (post)
        async loggingEventPost({ dispatch, rootGetters }, {
            name,
            description,
            additional = null
        })
        {
            // ensure name parameter is provided
            if (name === null || name === undefined) {
                throw new Error("Please provide a name for the event.")
            }

            // ensure description parameter is provided
            if (description === null || description === undefined) {
                throw new Error("Please provide a description for the event.")
            }

            // get app name and version
            const app = rootGetters['app/getAppname']
            const version = rootGetters['app/getVersion']

            // payload
            const payload = {
                name,
                description,
                app,
                version
            }
            // optional
            if (additional !== null) payload.additional = additional

            // app in debugging mode
            if (rootGetters['app/isDebugging'])
            {
                console.warn("event not send to server")
                console.log(payload)
                return
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_LOGGING_EVENT,
                method: REST_POST,
                payload
            })
        },

        // logging bug (post)
        async loggingBugPost({ dispatch, rootGetters }, {
            name,
            description,
            additional = null
        })
        {
            // ensure name parameter is provided
            if (name === null || name === undefined) {
                throw new Error("Please provide a name for the bug.")
            }

            // ensure description parameter is provided
            if (description === null || description === undefined) {
                throw new Error("Please provide a description for the bug.")
            }

            // get app name and version
            const app = rootGetters['app/getAppname']
            const version = rootGetters['app/getVersion']

            // payload
            const payload = {
                name,
                description,
                app,
                version
            }
            // optional
            if (additional !== null) payload.additional = additional

            // app in debugging mode
            if (rootGetters['app/isDebugging'])
            {
                console.warn("bug not send to server")
                console.log(payload)
                return
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_LOGGING_BUG,
                method: REST_POST,
                payload
            })
        },

        // logging feedback (post)
        async loggingFeedbackPost({ dispatch, rootGetters }, {
            topic,
            feedback,
            additional = null
        })
        {
            // ensure topic parameter is provided
            if (topic === null || topic === undefined) {
                throw new Error("Please provide a topic for the feedback.")
            }

            // ensure feedback parameter is provided
            if (feedback === null || feedback === undefined) {
                throw new Error("Please provide content for the feedback.")
            }

            // get app name and version
            const app = rootGetters['app/getAppname']
            const version = rootGetters['app/getVersion']

            // payload
            const payload = {
                topic,
                feedback,
                app,
                version
            }
            // optional
            if (additional !== null) payload.additional = additional

            // app in debugging mode
            if (rootGetters['app/isDebugging'])
            {
                console.warn("feedback not send to server")
                console.log(payload)
                return
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_LOGGING_FEEDBACK,
                method: REST_POST,
                payload
            })
        },

        // logging vote (get)
        async loggingVoteGet({ dispatch, rootGetters }, {
            topic
        })
        {
            // ensure topic parameter is provided
            if (topic === null || topic === undefined) {
                throw new Error("Please provide a topic for the vote.")
            }

            // get app name and version
            const app = rootGetters['app/getAppname']
            const version = rootGetters['app/getVersion']

            // payload
            const payload = {
                topic,
                app,
                version
            }

            try
            {
                // send API request to retrieve vote
                const response = await dispatch('requestApi', {
                    url: ROUTE_LOGGING_VOTE,
                    method: REST_GET,
                    payload
                })

                // extract vote
                const voting = response.voting.toLowerCase()

                // commit response to vuex state
                commit('SET_VOTE', { key, value: voting === 'true' })

                return response
            }
            catch(error)
            {
                // user has not voted yet
                commit('SET_VOTE', { key, value: false })

                throw error
            }
        },

        // logging vote (put)
        async loggingVotePut({ getters, dispatch, rootGetters }, {
            topic,
            vote
        })
        {
            // ensure topic parameter is provided
            if (topic === null || topic === undefined) {
                throw new Error("Please provide a topic for the vote.")
            }

            // toggle existing vote if left blank
            const voting = vote ? vote : !getters.getVote(topic)

            // get app name and version
            const app = rootGetters['app/getAppname']
            const version = rootGetters['app/getVersion']

            // payload
            const payload = {
                topic,
                voting,
                app,
                version
            }

            try
            {
                // send API request to retrieve vote
                const response = await dispatch('requestApi', {
                    url: ROUTE_LOGGING_VOTE,
                    method: REST_PUT,
                    payload
                })

                // commit response to vuex state
                commit('SET_VOTE', { key, value: voting })

                return response
            }
            catch(error)
            {
                throw error
            }
        },



        //// MONITORING

        // monitoring status (get)
        async monitoringStatusGet({ commit, dispatch })
        {
            try
            {
                // send API request to retrieve status
                const response = await dispatch('requestApi', {
                    url: ROUTE_MONITORING_STATUS,
                    method: REST_GET
                })

                // extract domains
                const domainList = []
                if (response.domains && Array.isArray(response.domains)) {
                    response.domains.forEach((domain) => {
                        const { name, group, status } = domain
                        domainList.push({
                            name,
                            group,
                            status
                        })
                    })
                }

                // extract queues
                const queueList = []
                if (response.messaging && Array.isArray(response.messaging)) {
                    response.messaging.forEach((message) => {
                        const { queue, vhost, consumers } = message
                        queueList.push({
                            name: queue,
                            group: vhost,
                            consumers
                        })
                    })
                }

                // commit status
                commit('SET_SERVICE', {
                    status: SERVICE_STATUS_UP,
                    domains: domainList,
                    queues: queueList
                })

                return response
            }
            catch(error)
            {
                // commit status
                commit('SET_SERVICE', {
                    status: SERVICE_STATUS_DOWN,
                    domains: null,
                    messaging: null
                })

                throw error
            }
        },



        //// ADMINISTRATION

        // administration activity users (get)
        async administrationActivityUsersGet({ dispatch }, {
            count = 4
        })
        {
            // payload
            const payload = {
                count
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_ACTIVITY_USERS,
                method: REST_GET,
                payload
            })
        },

        // administration activity assets (get)
        async administrationActivityAssetsGet({ dispatch }, {
            count = 4
        })
        {
            // payload
            const payload = {
                count
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_ACTIVITY_ASSETS,
                method: REST_GET,
                payload
            })
        },

        // administration logging events (get)
        async administrationLoggingEventsGet({ dispatch }, {
            count = 4
        })
        {
            // payload
            const payload = {
                count
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_LOGGING_EVENTS,
                method: REST_GET,
                payload
            })
        },

        // administration logging bugs (get)
        async administrationLoggingBugsGet({ dispatch }, {
            count = 4
        })
        {
            // payload
            const payload = {
                count
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_LOGGING_BUGS,
                method: REST_GET,
                payload
            })
        },

        //  administration logging feedback (get)
        async administrationLoggingFeedbackGet({ dispatch }, {
            count = 4
        })
        {
            // payload
            const payload = {
                count
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_LOGGING_FEEDBACK,
                method: REST_GET,
                payload
            })
        },

        // administration logging votes (get)
        async administrationLoggingVotesGet({ dispatch, rootGetters })
        {
            // payload
            const payload = {
                version: "0.4.0" //rootGetters['app/getVersion']
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_LOGGING_VOTES,
                method: REST_GET,
                payload
            })
        },

        // administration newsletters (get)
        async administrationNewslettersGet({ dispatch })
        {
            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_NEWSLETTERS,
                method: REST_GET
            })
        },

        // administration newsletters send (post)
        async administrationNewslettersSendPost({ dispatch }, {
            subject,
            body,
        })
        {
            // ensure subject parameter is provided
            if (subject === null || subject === undefined) {
                throw new Error("Please providde a subject for the newsletter.")
            }

            // ensure body parameter is provided
            if (body === null || body === undefined) {
                throw new Error("Please provide a body for the newsletter.")
            }

            // payload
            const payload = {
                subject,
                body
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_NEWSLETTERS_SEND,
                method: REST_GET,
                payload
            })
        },



        //// UTILS

        // send a request to the API
        async requestApi({ dispatch, commit, rootGetters }, {
            url,
            method = REST_POST,
            payload = null,
            token = null,
            useToken = true
        })
        {
            return new Promise(async (resolve, reject) => {

                // retrieve token
                if (token === null) token = rootGetters['app/getTokenRaw']

                // ignore token if necessary
                //if (!useToken) token = null

                try
                {
                    // content of request
                    const content = {
                        method: method,
                        headers: {
                            'Content-Type': 'application/json',
                            // token only when specified
                            ...(token && { 'Authorization': 'Bearer ' + token })
                        }
                    }

                    // payload when post / put
                    if (method === REST_POST || method === REST_PUT) {
                        content.body = JSON.stringify(payload)
                    }
                    // payload when get
                    else if (method === REST_GET && payload && Object.keys(payload).length > 0) {
                        // serialize payload into query parameters
                        const queryParams = new URLSearchParams(payload).toString()

                        // append query parameters to URL
                        url += `?${queryParams}`
                    }

                    // await raw response
                    const rawResponse = await fetch(ROUTE_BASE + url, content)

                    // handle error response
                    if (!rawResponse.ok)
                    {
                        let errorMessage = 'We had trouble processing your request.'

                        // attempt to parse the error response as JSON
                        try {
                            const errorResponse = await rawResponse.json()
                            if (errorResponse && typeof errorResponse.detail === 'string') {
                                errorMessage = errorResponse.detail
                            }
                        } catch (parseError) {}

                        // throw a new error with the detailed message
                        const error = new Error(errorMessage)
                        error.status = rawResponse.status
                        throw error
                    }

                    // await response (as json)
                    const response = await rawResponse.json()

                    // service seems to be running
                    commit('SET_SERVICE_STATUS', SERVICE_STATUS_UP)

                    resolve(response)
                }
                catch (error)
                {
                    // prepare error data
                    const errorCode = error.status || null

                    // unauthorized access (invalid or outdated token)
                    if (errorCode === 401 && useToken)
                    {
                        console.log(useToken)
                        // logout user
                        dispatch('app/logoutUser', true, { root: true })
                    }
                    // service unavailable / encounters problems
                    else if (errorCode === 500) {

                        // change service status to not running
                        commit('SET_SERVICE', { status: SERVICE_STATUS_DOWN, queues: null })
                    }

                    reject(error)
                }
            })
        }
    }
}