import * as THREE from 'three'
import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js'


export function centerGeometry(geometry)
{
    // compute bounding box 
    geometry.computeBoundingBox()

    // calculate center offset
    const offset = new THREE.Vector3()
    geometry.boundingBox.getCenter(offset).negate()
        
    // translate by center of bounding box
    geometry.center()

    return offset
}

export function normalizeGeometry(geometry, conversionSize)
{
    // compute bounding box 
    geometry.computeBoundingBox()
        
    // get the size of the bounding box
    const size = new THREE.Vector3()
    geometry.boundingBox.getSize(size)
        
    // convert size from meters to millimeters
    const width = size.x * conversionSize
    const height = size.y * conversionSize
    const depth = size.z * conversionSize
        
    // normalize the geometry size
    const scaleX = 1 / width
    const scaleY = 1 / height
    const scaleZ = 1 / depth
        
    // apply the scale to the geometry
    geometry.scale(scaleX, scaleY, scaleZ)

    return { width, height, depth }
}

export function mergeGeometries(object3D) 
{
	const geometries = [];
	object3D.traverse((child) => {
		if (child.isMesh) {
			const geom = child.geometry.clone();
			if (child.matrixAutoUpdate) child.updateMatrix();
			geom.applyMatrix4(child.matrix);
			geometries.push(geom);
		}
	});
	// Merge all geometries into one
	const mergedGeometry = mergeBufferGeometries(geometries, true);
	return mergedGeometry;
}

export function removeLargeFacesInGeometry(geometry, max_distance)
{
    const positions = geometry.attributes.position.array
    const indices = geometry.index.array

    const newIndices = []
    const threshold = max_distance * max_distance // square of max distance for efficiency

    for (let i = 0; i < indices.length; i += 3) 
    {
        const a = indices[i + 0]
        const b = indices[i + 1]
        const c = indices[i + 2]

        // vertex positions for the triangle
        const ax = positions[a * 3], ay = positions[a * 3 + 1], az = positions[a * 3 + 2]
        const bx = positions[b * 3], by = positions[b * 3 + 1], bz = positions[b * 3 + 2]
        const cx = positions[c * 3], cy = positions[c * 3 + 1], cz = positions[c * 3 + 2]

        // compute squared distances between the vertices
        const ab = (ax - bx) ** 2 + (ay - by) ** 2 + (az - bz) ** 2
        const ac = (ax - cx) ** 2 + (ay - cy) ** 2 + (az - cz) ** 2
        const bc = (bx - cx) ** 2 + (by - cy) ** 2 + (bz - cz) ** 2

        // check if all edges are within the threshold
        if (ab <= threshold && ac <= threshold && bc <= threshold) 
        {
            // keep the triangle
            newIndices.push(a, b, c)
        }
    }

    // update the geometry's index attribute
    geometry.setIndex(newIndices)
    geometry.index.needsUpdate = true

    return geometry
}

export function divideGeometry(geometry) 
{
    // Helper function to create a key for a vertex position with tolerance
    function getPositionKey(x, y, z, tolerance = 1e-5) {
        return `${Math.round(x / tolerance) * tolerance},${Math.round(y / tolerance) * tolerance},${Math.round(z / tolerance) * tolerance}`;
    }

    // Get index array
    let indexArray;
    if (geometry.index) {
        indexArray = geometry.index.array;
    } else {
        // Generate index array for non-indexed geometry
        const position = geometry.attributes.position;
        indexArray = [];
        for (let i = 0; i < position.count; i++) {
            indexArray.push(i);
        }
    }

    const positionArray = geometry.attributes.position.array;
    const vertexCount = positionArray.length / 3;

    // Build a map from position to canonical vertex index
    const positionToIndex = {};
    const indexToCanonicalIndex = new Array(vertexCount);

    for (let i = 0; i < vertexCount; i++) {
        const x = positionArray[i * 3];
        const y = positionArray[i * 3 + 1];
        const z = positionArray[i * 3 + 2];
        const key = getPositionKey(x, y, z);
        if (positionToIndex[key] === undefined) {
            positionToIndex[key] = i;
        }
        indexToCanonicalIndex[i] = positionToIndex[key];
    }

    const numFaces = indexArray.length / 3;

    // Build a map from canonical vertices to faces
    const vertexToFaces = {};
    for (let i = 0; i < numFaces; i++) {
        let a = indexArray[i * 3];
        let b = indexArray[i * 3 + 1];
        let c = indexArray[i * 3 + 2];

        // Map to canonical indices
        a = indexToCanonicalIndex[a];
        b = indexToCanonicalIndex[b];
        c = indexToCanonicalIndex[c];

        for (const vertex of [a, b, c]) {
            if (!vertexToFaces[vertex]) {
                vertexToFaces[vertex] = [];
            }
            vertexToFaces[vertex].push(i);
        }
    }

    // Initialize face neighbors
    const faceNeighbors = [];
    for (let i = 0; i < numFaces; i++) {
        faceNeighbors[i] = [];
    }

    // Build adjacency list based on shared canonical vertices
    for (let i = 0; i < numFaces; i++) {
        let a = indexArray[i * 3];
        let b = indexArray[i * 3 + 1];
        let c = indexArray[i * 3 + 2];

        // Map to canonical indices
        a = indexToCanonicalIndex[a];
        b = indexToCanonicalIndex[b];
        c = indexToCanonicalIndex[c];

        const vertices = [a, b, c];
        const neighborFacesSet = new Set();
        for (const vertex of vertices) {
            for (const faceIndex of vertexToFaces[vertex]) {
                if (faceIndex !== i) {
                    neighborFacesSet.add(faceIndex);
                }
            }
        }
        faceNeighbors[i] = Array.from(neighborFacesSet);
    }

    // Perform BFS to find connected components
    const visited = new Array(numFaces).fill(false);
    const components = [];

    for (let i = 0; i < numFaces; i++) {
        if (!visited[i]) {
            const componentFaces = [];
            const queue = [i];
            visited[i] = true;

            while (queue.length > 0) {
                const f = queue.shift();
                componentFaces.push(f);

                for (const neighbor of faceNeighbors[f]) {
                    if (!visited[neighbor]) {
                        visited[neighbor] = true;
                        queue.push(neighbor);
                    }
                }
            }

            components.push(componentFaces);
        }
    }

    // Create geometries for each component
    const geometries = components.map((componentFaces) => {
        const newGeometry = new THREE.BufferGeometry();

        // Collect vertices and indices
        const vertexMap = {};
        const vertices = [];
        const indices = [];

        let newIndex = 0;
        for (const faceIndex of componentFaces) {
            let a = indexArray[faceIndex * 3];
            let b = indexArray[faceIndex * 3 + 1];
            let c = indexArray[faceIndex * 3 + 2];

            // Map to canonical indices
            a = indexToCanonicalIndex[a];
            b = indexToCanonicalIndex[b];
            c = indexToCanonicalIndex[c];

            for (const vertexIndex of [a, b, c]) {
                if (vertexMap[vertexIndex] === undefined) {
                    vertexMap[vertexIndex] = newIndex++;
                    vertices.push(
                        positionArray[vertexIndex * 3],
                        positionArray[vertexIndex * 3 + 1],
                        positionArray[vertexIndex * 3 + 2]
                    );
                }
            }

            indices.push(
                vertexMap[a],
                vertexMap[b],
                vertexMap[c]
            );
        }

        // Create new BufferGeometry
        newGeometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
        newGeometry.setIndex(indices);

        return newGeometry;
    });

    return geometries;
}

export function repairTextureMapping(mesh, conversion)
{
    if (!mesh.geometry.attributes.uv) 
    {
        // unindex the geometry to ensure each face has unique vertices
        mesh.geometry = mesh.geometry.toNonIndexed()

        const geometry = mesh.geometry
        const positionAttribute = geometry.attributes.position
        const normalAttribute = geometry.attributes.normal

        const uvAttribute = new Float32Array(positionAttribute.count * 2)

        const scaling = 1000 / conversion

        // iterate over each face (triangle)
        for (let i = 0; i < positionAttribute.count; i += 3) 
        {
            // get vertex positions
            const p0 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i)
            const p1 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 1)
            const p2 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 2)

            // get vertex normals
            const n0 = new THREE.Vector3().fromBufferAttribute(normalAttribute, i)
            const n1 = new THREE.Vector3().fromBufferAttribute(normalAttribute, i + 1)
            const n2 = new THREE.Vector3().fromBufferAttribute(normalAttribute, i + 2)

            // calculate face normal (average of vertex normals)
            const faceNormal = new THREE.Vector3()
                .add(n0)
                .add(n1)
                .add(n2)
                .normalize()

            // determine UV mapping based on face normal
            let uDir, vDir
            if (Math.abs(faceNormal.y) >= Math.abs(faceNormal.x) && Math.abs(faceNormal.y) >= Math.abs(faceNormal.z)) 
            {
                // normal is predominantly Y axis
                // map UVs using X and Z axes
                uDir = 'x'
                vDir = 'z'
            } 
            else if (Math.abs(faceNormal.x) >= Math.abs(faceNormal.y) && Math.abs(faceNormal.x) >= Math.abs(faceNormal.z)) 
            {
                // normal is predominantly X axis
                // map UVs using Y and Z axes
                uDir = 'y'
                vDir = 'z'
            } 
            else 
            {
                // normal is predominantly Z axis
                // map UVs using X and Y axes
                uDir = 'x'
                vDir = 'y'
            }

            // assign UVs using absolute positions
            function setUV(idx, p) 
            {
                uvAttribute[idx * 2] = p[uDir] / scaling
                uvAttribute[idx * 2 + 1] = p[vDir] / scaling 
            }

            setUV(i, p0)
            setUV(i + 1, p1)
            setUV(i + 2, p2)
        }

        // set the UV attribute
        geometry.setAttribute('uv', new THREE.BufferAttribute(uvAttribute, 2))
    }

    return mesh
}

export function calculateLimitedDimensions(width, height, depth, center, limit, tolerance = 0) 
{
    const shift = [0, 0, 0] 
    const dimensions = [width, height, depth]
    const centerCoords = [center.x, center.y, center.z]
    const adjustedLimit = [limit[0], limit[1], limit[2]]

    // iterate through x, y, z
    for (let i = 0; i < 3; i++) 
    {
        // consider tolerance
        adjustedLimit[i] = adjustedLimit[i] - tolerance * 2

        // detect if box is too wide in the current dimension
        if (Math.abs(centerCoords[i]) * 2 + dimensions[i] > adjustedLimit[i]) 
        {
            // calculate overflow
            const overflow = Math.abs(centerCoords[i]) * 2 + dimensions[i] - adjustedLimit[i]

            // calculate shift
            shift[i] = -(Math.min(overflow / 4, Math.abs(centerCoords[i]))) * Math.sign(centerCoords[i])

            // calculate new dimension
            dimensions[i] = Math.min(dimensions[i] - overflow / 2, adjustedLimit[i] - (tolerance * 2))
        }

        // offset center from being directly inside the highlighting plane
        if (Math.abs(centerCoords[i]) * 2 >= adjustedLimit[i])
            shift[i] -= Math.sign(centerCoords[i]) * tolerance
    }

    return { dimensions, shift }
}

export function calculateNormals(bufferGeometry) 
{
    const positionAttribute = bufferGeometry.getAttribute('position');
    const normalAttribute = bufferGeometry.getAttribute('normal') || new THREE.BufferAttribute(new Float32Array(positionAttribute.count * 3), 3);

    const pA = new THREE.Vector3(), pB = new THREE.Vector3(), pC = new THREE.Vector3();
    const cb = new THREE.Vector3(), ab = new THREE.Vector3();

    for (let i = 0; i < positionAttribute.count; i += 3) {
        pA.fromBufferAttribute(positionAttribute, i);
        pB.fromBufferAttribute(positionAttribute, i + 1);
        pC.fromBufferAttribute(positionAttribute, i + 2);

        cb.subVectors(pC, pB);
        ab.subVectors(pA, pB);
        cb.cross(ab);

        cb.normalize();

        normalAttribute.setXYZ(i, cb.x, cb.y, cb.z);
        normalAttribute.setXYZ(i + 1, cb.x, cb.y, cb.z);
        normalAttribute.setXYZ(i + 2, cb.x, cb.y, cb.z);
    }

    bufferGeometry.setAttribute('normal', normalAttribute);
    bufferGeometry.attributes.normal.needsUpdate = true;

    return bufferGeometry;
}