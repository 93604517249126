/*
 * Model Store
 */


//// IMPORTS
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js'
import { ModelFactory } from '@/classes/factory/ModelFactory.js'
import { extractExtension } from '@/utils/file.js'


//// CONSTANTS

// window
const WINDOW_IMPORT = 'import'
const WINDOW_MODIFY = 'modify'
const WINDOW_RENDER = 'render'
const WINDOW_EXPORT = 'export'
const WINDOW_DEFAULT = WINDOW_IMPORT

// page (positive numbers are reserved for meshes)
const PAGE_PREVIEW = -1 // not needed anymore because of render?
const PAGE_MODEL = 0
const PAGE_DEFAULT = PAGE_PREVIEW

// tab
const TAB_GENERAL = 'general'
const TAB_TOOLS = 'tools'
const TAB_GEOMETRY = 'geometry'
const TAB_MATERIAL = 'material'
const TAB_DEFAULT = TAB_GENERAL

// view
const VIEW_DEFAULT = 0

// mode
const MODE_RENDER = 'render'
const MODE_TEXTURE = 'texture'
const MODE_NORMALS = 'normals'
const MODE_DEPTH = 'depth'
const MODE_DEFAULT = MODE_RENDER

// target
const TARGET_MESH = 'mesh'
const TARGET_SET = 'set'
const TARGET_FACE = 'face'
const TARGET_EDGE = 'edge'
const TARGET_VERTEX = 'vertex'
const TARGET_DEFAULT = TARGET_MESH

// cursor
const CURSOR_POINTER = 'pointer'
const CURSOR_SELECT = 'select'
const CURSOR_DEFAULT = CURSOR_POINTER

// preset
const PRESET_DEFAULT = 0
const PRESET_STUDIO = -1

// camera
const CAMERA_THETA_DEFAULT = Math.PI
const CAMERA_PHI_DEFAULT = Math.PI / 2.5
const CAMERA_RADIUS_DEFAULT = 2

// limit
const LIMIT_DEFAULT = true
const LIMIT_PHI_MIN = 20 * (Math.PI / 180) // convert degree to rad
const LIMIT_PHI_MAX = 160 * (Math.PI / 180) // convert degree to rad
const LIMIT_RADIUS_MIN = 1
const LIMIT_RADIUS_MAX = 20

// animation
const ANIMATION_FLY_DEFAULT = true
const ANIMATION_FLY_VALUE = 0.05
const ANIMATION_TURNTABLE_DEFAULT = false
const ANIMATION_TURNTABLE_VALUE = 0.3


//// TABS

// general tab
const createTabGeneral = () => {
    return {
        id: TAB_GENERAL,
        view: VIEW_DEFAULT
    }
}

// tool tab
const createTabTools = () => {
    return {
        id: TAB_TOOLS,
        view: VIEW_DEFAULT
    }
}

// geometry tab
const createTabGeometry = () => {
    return {
        id: TAB_GEOMETRY,
        view: VIEW_DEFAULT
    }
}

// material tab
const createTabMaterial = () => {
    return {
        id: TAB_MATERIAL,
        view: VIEW_DEFAULT
    }
}


//// PAGES

// preview page
const createPagePreview = () => {
    return {
        id: PAGE_PREVIEW,
        tabs: null,
        activeTab: null
    }
}

// model page
const createPageModel = () => {
    return {
        id: PAGE_MODEL,
        tabs: [
            createTabGeneral(),
            //createTabTools()
        ],
        activeTab: TAB_GENERAL
    }
}

// mesh page
const createPageMesh = (pageId) => {
    return {
        id: pageId,
        tabs: [
            createTabGeneral(),
            createTabGeometry(),
            createTabMaterial()
        ],
        activeTab: TAB_DEFAULT/*,
        camera: null*/
    }
}

// default pages
const createPagesDefault = () => {
    return [
        createPagePreview(),
        createPageModel()
    ]
}


//// MODEL

function createModelState() {
    return {
        asset: null,
        wrapper: null,
        outdated: false, // if outdated, save is requested in UI
    }
}


//// VIEWER

// info
// camera (analogy: comparable to a satellite flying aroung earth)
// theta: tilt (analogy: satellite movement from north to south pole)
// phi: yaw (analogy: satellite would describe movement around the equator)
// no roll implemented

function newCameraState() {
    return {
        center: {
            x: 0,
            y: 0,
            z: 0
        },
        theta: CAMERA_THETA_DEFAULT,
        phi: CAMERA_PHI_DEFAULT,
        radius: CAMERA_RADIUS_DEFAULT
    }
}

function createViewerState() {
    return {

        // active
        activeMode: MODE_DEFAULT,
        activeTarget: TARGET_DEFAULT,
        activeCursor: CURSOR_DEFAULT,

        // enabled
        enabledMode: {
            [MODE_RENDER]: true,
            [MODE_TEXTURE]: true,
            [MODE_NORMALS]: true,
            [MODE_DEPTH]: true
        },
        enabledTargets: {
            [TARGET_MESH]: true,
            [TARGET_SET]: true,
            [TARGET_FACE]: true,
            [TARGET_EDGE]: false,
            [TARGET_VERTEX]: false
        },
        enabledCursor: {
            [CURSOR_POINTER]: true,
            [CURSOR_SELECT]: false
        },
        
        // studio
        studio: {
            enabled: false,
            resolution: null,
            mode: null
        },

        // presets
        presets: {
            [PRESET_STUDIO]: null,
            [PRESET_DEFAULT]: newCameraState()
        },
        activePresetKey: PRESET_DEFAULT,

        // selection
        selection: {},

        // focus
        focus: 0,

        // offset
        offset: {
            x: 0,
            y: 0
        },

        // limit
        limit: LIMIT_DEFAULT,
        limitPhi: {
            min: LIMIT_PHI_MIN,
            max: LIMIT_PHI_MAX
        },
        limitRadius: {
            min: LIMIT_RADIUS_MIN,
            max: LIMIT_RADIUS_MAX
        },

        // animation
        animationFly: ANIMATION_FLY_DEFAULT,
        animationTurntable: ANIMATION_TURNTABLE_DEFAULT
    }
}


//// STATE

const createState = () => {
    return {
        activeWindow: WINDOW_DEFAULT,
        pages: createPagesDefault(),
        activePage: PAGE_PREVIEW,
        model: createModelState(),
        viewer: createViewerState()
    }
}


//// STORE
export const model = {
    namespaced: true,
    state: createState(),
    mutations: {
        RESET_INTERFACE(state) 
        {
            Object.assign(state, createState())
        },
        RESET_MODEL(state)
        {
            state.model = createModelState()
        },
        RESET_VIEWER(state)
        {
            state.viewer = createViewerState()
        },
        RESET_SELECTION(state)
        {
            state.viewer.selection = {}
        },

        SET_ACTIVE_WINDOW(state, window) 
        {
            state.activeWindow = window
        },
        ADD_PAGE(state, pageId) 
        {
            // try to find page with the id
            let existingPage = state.pages.find(p => p.id === pageId)

            // if it does not exist, create a new page
            if (!existingPage)
            {
                // create new page
                const newPage = createPageMesh(pageId)

                // add new page to array
                state.pages.push(newPage)
            }
        },
        // pages are not meant to be removed 
        // (at least not in the new UI -- for now)
        REMOVE_PAGE(state, pageId) 
        {
            // preview page cannot be removed
            if (pageId === PAGE_PREVIEW) return
            // model page cannot be removed
            if (pageId === PAGE_MODEL) return
            
            // if the page to be removed is active
            if (pageId === state.activePage) state.activePage = PAGE_DEFAULT
            
            // remove page
            state.pages = state.pages.filter(p => p.id !== pageId)
        },
        SET_ACTIVE_PAGE(state, pageId) 
        {
            state.activePage = pageId
        },
        SET_ACTIVE_TAB(state, { pageId, tabId }) 
        {
            const page = state.pages.find(p => p.id === pageId)
            if (page) page.activeTab = tabId
        },
        SET_ACTIVE_VIEW(state, { pageId, tabId, viewId }) 
        {
            // find relevant page
            const page = state.pages.find(p => p.id === pageId)

            // only if page exists and contains tabs
            if (page && page.tabs) 
            {
                // find relevant tab
                const tab = page.tabs.find(t => t.id === tabId)

                // only if tab exists
                if (tab) tab.view = viewId
            }
        },
        SET_ACTIVE_FILTER(state, { pageId, tabId, filter }) 
        {
            const page = state.pages.find(p => p.id === pageId)

            if (page && page.tabs) 
            {
                const tab = page.tabs.find(t => t.id === tabId)
                if (tab) tab.filter = filter
            }
        },
        SET_MODEL_ASSET(state, asset) 
        {
            state.model.asset = asset
        },
        SET_MODEL_WRAPPER(state, wrapper)
        {
            state.model.wrapper = wrapper
        },
        SET_MODEL_OUTDATED(state, outdated) 
        {
            state.model.outdated = outdated
        },

        SET_ACTIVE_MODE(state, activeMode) 
        {
            state.viewer.activeMode = activeMode
        },
        SET_ACTIVE_TARGET(state, activeTarget)
        {
            state.viewer.activeTarget = activeTarget
        },
        SET_ACTIVE_CURSOR(state, activeCursor)
        {
            state.viewer.activeCursor = activeCursor
            state.viewer.activeTarget = TARGET_DEFAULT
            state.viewer.selection = {}
        },
        SET_ENABLED_MODE(state, { mode, enabled })
        {
            state.viewer.enabledMode[mode] = enabled
        },
        SET_ENABLED_TARGET(state, { target, enabled })
        {
            state.viewer.enabledTarget[target] = enabled
        },
        SET_ENABLED_CURSOR(state, { cursor, enabled })
        {
            state.viewer.enabledCursor[cursor] = enabled
        },
        SET_ENABLED_MODE(state, { mode, enabled })
        {
            state.viewer.enabledMode[mode] = enabled
        },
        ACTIVATE_STUDIO(state, { resolution, mode })
        {
            state.viewer.studio.enabled = true
            state.viewer.studio.resolution = resolution
            state.viewer.studio.mode = mode
            state.viewer.presets[PRESET_STUDIO] = newCameraState()
        },
        DEACTIVATE_STUDIO(state)
        {
            state.viewer.studio.enabled = false
            state.viewer.studio.resolution = null
            state.viewer.studio.mode = null
            state.viewer.presets[PRESET_STUDIO] = null
        },
        SET_ACTIVE_PRESET_KEY(state, presetKey)
        {
            state.viewer.activePresetKey = presetKey
        },
        SET_PRESET_CENTER(state, { presetKey, centerX, centerY, centerZ }) 
        {
            // ensure preset exists
            if (!state.viewer.presets[presetKey])
                state.viewer.presets[presetKey] = newCameraState()

            // set center position
            state.viewer.presets[presetKey].center = {
                x: centerX,
                y: centerY,
                z: centerZ
            }
        },
        SET_PRESET_THETA(state, { presetKey, theta }) 
        {
            // ensure preset exists
            if (!state.viewer.presets[presetKey])
                state.viewer.presets[presetKey] = newCameraState()

            // set theta angle
            state.viewer.presets[presetKey].theta = theta
        },
        SET_PRESET_PHI(state, { presetKey, phi }) 
        {
            // ensure preset exists
            if (!state.viewer.presets[presetKey])
                state.viewer.presets[presetKey] = newCameraState()

            // set phi angle
            if (state.viewer.limit)
            {
                const limitMax = state.limitPhi.max
                const limitMin = state.limitPhi.min
                state.viewer.presets[presetKey].phi = Math.min(limitMax, Math.max(limitMin, phi))
            }
            else
                state.viewer.presets[presetKey].phi = phi
        },
        SET_PRESET_RADIUS(state, { presetKey, radius})
        {
            // ensure preset exists
            if (!state.viewer.presets[presetKey])
                state.viewer.presets[presetKey] = newCameraState()

            // set radius
            if (state.viewer.limit) 
            {
                const limitMax = state.viewer.limitRadius.max
                const limitMin = state.viewer.limitRadius.min
                state.viewer.presets[presetKey].radius = Math.min(limitMax, Math.max(limitMin, radius))
            }
            else
                state.viewer.presets[presetKey].radius = radius
        },
        SET_SELECTION(state, { mesh, select, selection }) 
        {
            // if mesh (key) does NOT exist yet
            if (!state.viewer.selection.hasOwnProperty(mesh)) 
            {
                // only if something is selected, we need to store it
                if (select) state.viewer.selection[mesh] = [...selection]
                else return
            } 
            // if mesh (key) exists
            else 
            {
                // add selection
                if (select) 
                {
                    const currentSelection = new Set(state.viewer.selection[mesh])
                    selection.forEach(id => currentSelection.add(id))
                    state.viewer.selection[mesh] = Array.from(currentSelection)
                } 
                // remove selection
                else state.viewer.selection[mesh] = state.viewer.selection[mesh].filter(id => !selection.includes(id))
            }
        },
        SET_FOCUS(state, focus)
        {
            state.viewer.focus = focus
        },
        SET_OFFSET(state, { x, y }) 
        {
            state.viewer.offset.x = x
            state.viewer.offset.y = y
        },
        SET_LIMIT(state, limit)
        {
            state.viewer.limit = limit
        },
        SET_PHI_LIMIT(state, {limitMin, limitMax})
        {
            state.viewer.limitPhi.min = limitMin
            state.viewer.limitPhi.max = limitMax
        },
        SET_RADIUS_LIMIT(state, {limitMin, limitMax})
        {
            state.viewer.limitRadius.min = limitMin
            state.viewer.limitRadius.max = limitMax
        },
        SET_ANIMATION_FLY(state, animationFly)
        {
            state.viewer.animationFly = animationFly
        },
        SET_ANIMATION_TURNTABLE(state, animationTurntable)
        {
            state.viewer.animationTurntable = animationTurntable
        }
    },
    getters: {
        keyWindowDefault: () => WINDOW_DEFAULT,
        keyWindowImport: () => WINDOW_IMPORT,
        keyWindowModify: () => WINDOW_MODIFY,
        keyWindowRender: () => WINDOW_RENDER,
        keyWindowExport: () => WINDOW_EXPORT,
        keyPageDefault: () => PAGE_DEFAULT,
        keyPagePreview: () => PAGE_PREVIEW,
        keyPageModel: () => PAGE_MODEL,
        keyTabDefault: () => TAB_DEFAULT,
        keyTabGeneral: () => TAB_GENERAL,
        keyTabTools: () => TAB_TOOLS,
        keyTabGeometry: () => TAB_GEOMETRY,
        keyTabMaterial: () => TAB_MATERIAL,
        keyViewDefault: () => VIEW_DEFAULT,

        keyModeDefault: () => MODE_DEFAULT,
        keyModeRender: () => MODE_RENDER,
        keyModeTexture: () => MODE_TEXTURE,
        keyModeNormals: () => MODE_NORMALS,
        keyModeDepth: () => MODE_DEPTH,
        keyTargetDefault: () => TARGET_DEFAULT,
        keyTargetMesh: () => TARGET_MESH,
        keyTargetSet: () => TARGET_SET,
        keyTargetFace: () => TARGET_FACE,
        keyTargetEdge: () => TARGET_EDGE,
        keyTargetVertex: () => TARGET_VERTEX,
        keyCursorDefault: () => CURSOR_DEFAULT,
        keyCursorPointer: () => CURSOR_POINTER,
        keyCursorSelect: () => CURSOR_SELECT,
        keyPresetDefault: () => PRESET_DEFAULT,
        keyPresetStudio: () => PRESET_STUDIO,
        keyAnimationFlyDefault: () => ANIMATION_FLY_DEFAULT,
        keyAnimationFlyValue: () => ANIMATION_FLY_VALUE,
        keyAnimationTurntableDefault: () => ANIMATION_TURNTABLE_DEFAULT,
        keyAnimationTurntableValue: () => ANIMATION_TURNTABLE_VALUE,

        
        hasModelAsset: state => state.model.asset !== null, // TODO refactor to hasModel?

        isWindowDefault: state => state.activeWindow === WINDOW_DEFAULT,
        isWindowImport: state => state.activeWindow === WINDOW_IMPORT,
        isWindowModify: state => state.activeWindow === WINDOW_MODIFY,
        isWindowRender: state => state.activeWindow === WINDOW_RENDER,
        isWindowExport: state => state.activeWindow === WINDOW_EXPORT,
        isPageDefault: state => state.activePage === PAGE_DEFAULT,
        isPagePreview: state => state.activePage === PAGE_PREVIEW,
        isPageModel: state => state.activePage === PAGE_MODEL,
        isPageMesh: state => state.activePage > 0,
        isTabDefault: (state, getters) => getters.getActiveTab === TAB_DEFAULT,
        isTabGeneral: (state, getters) => getters.getActiveTab === TAB_GENERAL,
        isTabTools: (state, getters) => getters.getActiveTab === TAB_TOOLS,
        isTabGeometry: (state, getters) => getters.getActiveTab === TAB_GEOMETRY,
        isTabMaterial: (state, getters) => getters.getActiveTab === TAB_MATERIAL,
        isViewDefault: (state, getters) => getters.getActiveView === VIEW_DEFAULT,
        isFilterDefault: (state, getters) => getters.getActiveFilter === FILTER_DEFAULT,
        isImported: state => state.model.wrapper?.reactive.ready,
        isModelOutdated: state => state.model.outdated,

        isModeDefault: (state, getters) => getters.getActiveMode === MODE_DEFAULT,
        isModeRender: (state, getters) => getters.getActiveMode === MODE_RENDER,
        isModeTexture: (state, getters) => getters.getActiveMode === MODE_TEXTURE,
        isModeNormals: (state, getters) => getters.getActiveMode === MODE_NORMALS,
        isModeDepth: (state, getters) => getters.getActiveMode === MODE_DEPTH,
        isTargetDefault: state => state.viewer.activeTarget === TARGET_DEFAULT,
        isTargetMesh: state => state.viewer.activeTarget === TARGET_MESH,
        isTargetSet: state => state.viewer.activeTarget === TARGET_SET,
        isTargetFace: state => state.viewer.activeTarget === TARGET_FACE,
        isTargetEdge: state => state.viewer.activeTarget === TARGET_EDGE,
        isTargetVertex: state => state.viewer.activeTarget === TARGET_VERTEX,
        isCursorDefault: state => state.viewer.activeCursor === CURSOR_DEFAULT,
        isCursorPointer: state => state.viewer.activeCursor === CURSOR_POINTER,
        isCursorSelect: state => state.viewer.activeCursor === CURSOR_SELECT,
        isModeEnabled: (state) => (mode) => {
            return state.viewer.enabledMode[mode]
        },
        isTargetEnabled: (state) => (target) => {
            return state.viewer.enabledTargets[target]
        },
        isCursorEnabled: (state) => (cursor) => {
            return state.viewer.enabledCursor[cursor]
        },
        isStudio: (state) => state.viewer.studio.enabled,
        isPreset: (state) => (presetId) => {
            if (state.viewer.presets[presetId]) return true
            else return false
        },
        isSelection: state => {
            if (state.viewer.selection && Object.keys(state.selection).length) {
                for (let mesh of Object.keys(state.selection)) {
                    if (state.viewer.selection[mesh].length) return true
                }
            }
            return false
        },
        isShowHover: state => {
            if (state.viewer.studio.enabled) return false
            return true
        },
        isShowSelection: state => {
            if (state.viewer.studio.enabled) return false
            if (state.viewer.activeCursor === CURSOR_SELECT) return true
            return false
        },
        isShowSets: state => {
            if (state.viewer.studio.enabled) return false
            if (state.viewer.activeTarget === TARGET_SET) return true
            return false
        },
        isLimit: (state) => {
            if (state.viewer.studio.enabled) return false
            return state.viewer.limit
        },
        isAnimationFly: (state) => state.viewer.animationFly,
        isAnimationTurntable: (state) => state.viewer.animationTurntable,


        getActiveWindow: state => state.activeWindow,
        getPages: state => state.pages,
        getPageObject: state => pageId => state.pages.find(p => p.id === pageId),
        getActivePage: state => state.activePage,
        getActivePageObject: (state, getters) => getters.getPageObject(state.activePage),
        getTabs: (state, getters) => pageId => {
            const page = getters.getPageObject(pageId)
            if (page) return page.tabs
            return null
        },
        getTabObject: (state, getters) => (pageId, tabId) => {
            const page = getters.getPageObject(pageId)
            if (!page || !page.tabs) return null
            return page.tabs.find(t => t.id === tabId)
        },
        getActiveTab: (state, getters) => getters.getActivePageObject?.activeTab,
        getActiveTabObject: (state, getters) => {
            return getters.getTabObject(state.activePage, getters.getActiveTab)
        },
        getActiveView: (state, getters) => getters.getActiveTabObject?.view,
        getActiveFilter: (state, getters) => getters.getActiveTabObject?.filter,


        getModelAsset: state => state.model.asset,
        getModelWrapper: state => state.model.wrapper,
        getMeshes: state => state.model.wrapper?.getMeshes(),
        getMesh: (state, getters) => (meshId) => {
            const meshes = getters.getMeshes
            if (meshes === null) return
            return meshes.find(mesh => mesh.id === meshId)
        },
        getGeometries: (state, getters) => (meshId) => {
            const mesh = getters.getMesh(meshId)
            if (mesh === null) return
            const geometries = mesh.nonreactive.geometries
            if (geometries === null) return
            return geometries
        },
        getMaterials: (state, getters) => (meshId) => {
            const mesh = getters.getMesh(meshId)
            if (mesh === null) return
            const materials = mesh.nonreactive.materials
            if (materials === null) return
            return materials
        },
        getVersion: state => state.model.wrapper?.reactive.version,
        
        



        // additional
        // key: 'material-color-single'
        // category: 'material'
        // type: 'color'
        // subtype: 'single'


        getAssets: (state, getters, rootState, rootGetters) => {

            // get all assets
            let assets = rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppModulator']
            )

            // if assets exist
            if (assets) 
            {
                // sort assets by created date
                assets = assets.sort((a, b) => b.created - a.created)
                return assets
            }

            return null
        },
        getTextAssets: (state, getters) => {

            // get all assets
            let assets = getters.getAssets

            // if assets exist
            if (assets) 
            {
                // filter assets by type
                assets = assets.filter(asset => asset.type === 'text') // TODO use const
                return assets
            }

            return null
        },
        getImageAssets: (state, getters) => {
            
            // get all assets
            let assets = getters.getAssets

            // if assets exist
            if (assets) 
            {
                // filter assets by type
                assets = assets.filter(asset => asset.type === 'image') // TODO use const
                return assets
            }

            return null
        },
        getModelAssets: (state, getters) => {

            // TODO replace with recent because it is the same

        },





        getMaterialColorSingleAssets: (state, getters) => {

            let assets = getters.getTextAssets

            if (assets)
            {
                assets = assets.filter(asset => asset.additional.key === 'material-color-single')
                return assets
            }

            return null
        },
        getMaterialTextureGenericAssets: (state, getters) => {

            let assets = getters.getImageAssets

            if (assets)
            {
                assets = assets.filter(asset => asset.additional.key === 'material-texture-generic')
                return assets
            }

            return null
        },
        getMaterialTexturePatternAssets: (state, getters) => {


        },




        getGeneratedRenders: (state, getters) => {
            
            let assets = getters.getImageAssets
            if (assets)
            {
                

                //let reference = getters.getModelAsset?.id
                //if (reference)
                //{
                    assets = assets.filter(asset => 
                        asset.additional.key === 'render-generated') // && 
                        //asset.additional.reference === reference)

                        //console.log(assets)

                    return assets
                //}
            }

            return null
        },






        getRecentModels: (state, getters, rootState, rootGetters) => {
            let assets = rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppModulator'],
                rootGetters['api/keyAssetTypeModel']
            )
            if (assets) 
            {
                assets = assets
                .sort((a, b) => b.update - a.update)
                return assets.slice(0, 3)
            } 
            return null
        },
        getGeneratedModels: (state, getters, rootState, rootGetters) => {
            let assets = rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppSketch'],
                rootGetters['api/keyAssetTypeModel']
            )
            if (assets) {
                assets = assets.sort((a, b) => b.created - a.created)
                return assets.slice(0, 3)
            }
            return null
        },



        getActiveMode: state => {
            if (state.viewer.studio.enabled && state.viewer.studio.mode) 
                return state.viewer.studio.mode
            return state.viewer.activeMode
        },
        getActiveTarget: state => state.viewer.activeTarget,
        getActiveCursor: state => state.viewer.activeCursor,
        getResolution: state => {
            if (state.viewer.studio.enabled) return state.viewer.studio.resolution
            return null
        },
        getPreset: (state) => (presetKey) => {
            // get preset key (studio overwrites key)
            let key = state.viewer.studio.enabled ? PRESET_STUDIO : presetKey
            // make sure preset exists
            if (!state.viewer.presets[key]) state.viewer.presets[key] = newCameraState()
            // return preset
            return state.viewer.presets[key]
        },
        getActivePreset: (state, getters) => {
            return getters.getPreset(state.viewer.activePresetKey)
        },
        getActivePresetKey: (state) => {
            return state.viewer.studio.enabled ? PRESET_STUDIO : state.viewer.activePresetKey
        },
        getActivePresetCenter: (state, getters) => {
            const preset = getters.getActivePreset
            return { ...preset.center }
        },
        getActivePresetAngles: (state, getters) => {
            const preset = getters.getActivePreset
            return [ preset.theta, preset.phi ]
        },
        getActivePresetTheta: (state, getters) => {
            const preset = getters.getActivePreset
            return preset.theta
        },
        getActivePresetPhi: (state, getters) => {
            const preset = getters.getActivePreset
            return preset.phi
        },
        getActivePresetRadius: (state, getters) => {
            const preset = getters.getActivePreset
            return preset.radius
        },
        getSelection: state => state.viewer.selection,
        getFocus: state => state.viewer.focus,
        getOffset: state => ({ ...state.viewer.offset }),
        getLimitPhi: (state) => ({ ...state.viewer.limitPhi }),
        getLimitRadius: (state) => ({ ...state.viewer.limitRadius })
    },
    actions: {
        resetInterface({ commit }) 
        {
            commit('RESET_INTERFACE')
        },
        resetModel({ commit })
        {   
            commit('RESET_MODEL')
        },
        resetViewer({ commit })
        {
            commit('RESET_VIEWER')
        },
        resetSelection({ commit }) 
        {
            commit('RESET_SELECTION')
        },
        setActiveWindow({ commit }, window) 
        {
            commit('SET_ACTIVE_WINDOW', window)
        },
        setActiveWindowToDefault({ commit }) 
        {
            commit('SET_ACTIVE_WINDOW', WINDOW_DEFAULT)
        },
        setActiveWindowToImport({ commit }) 
        {
            commit('SET_ACTIVE_WINDOW', WINDOW_IMPORT)
        },
        setActiveWindowToModify({ commit }) 
        {
            commit('SET_ACTIVE_WINDOW', WINDOW_MODIFY)
        },
        setActiveWindowToRender({ commit }) 
        {
            commit('SET_ACTIVE_WINDOW', WINDOW_RENDER)
        },
        setActiveWindowToExport({ commit }) 
        {
            commit('SET_ACTIVE_WINDOW', WINDOW_EXPORT)
        },
        addPage({ commit }, pageId) 
        {
            commit('ADD_PAGE', pageId)
        },
        removePage({ commit }, pageId) 
        {
            commit('REMOVE_PAGE', pageId)
        },
        setActivePage({ commit, getters }, pageId) 
        {
            // try to get page
            const page = getters.getPageObject(pageId)

            // if page does not exist
            if (page == null) {
                // create page
                commit('ADD_PAGE', pageId)
            }

            // set page active
            commit('SET_ACTIVE_PAGE', pageId)
        },
        setActivePageToDefault({ dispatch }) 
        {
            dispatch('setActivePage', PAGE_DEFAULT)
        },
        setActivePageToPreview({ dispatch }) 
        {
            dispatch('setActivePage', PAGE_PREVIEW)
        },
        setActivePageToModel({ dispatch }) 
        {
            dispatch('setActivePage', PAGE_MODEL)
        },
        // payload
        // requires: tabId
        // optional: pageId (otherwise active is used)
        setActiveTab({ commit, getters }, payload) 
        {
            let pageId = payload.pageId
            if (pageId == null) pageId = getters.getActivePage

            commit('SET_ACTIVE_TAB', { pageId, tabId: payload.tabId })
        },
        setActiveTabToGeneral({ dispatch }) 
        {
            dispatch('setActiveTab', TAB_GENERAL)
        },
        setActiveTabToGeometry({ dispatch }) 
        {
            dispatch('setActiveTab', TAB_GEOMETRY)
        },
        setActiveTabToMaterial({ dispatch }) 
        {
            dispatch('setActiveTab', TAB_MATERIAL)
        },
        // payload
        // requires: viewId
        // optional: pageId, tabId (otherwise active is used)
        setActiveView({ commit, getters }, payload) 
        {
            let pageId = payload.pageId
            let tabId = payload.tabId
            if (pageId == null) pageId = getters.getActivePage
            if (tabId == null) tabId = getters.getActiveTab
            commit('SET_ACTIVE_VIEW', { pageId, tabId, viewId: payload.viewId })
        },
        setActiveViewToDefault({ dispatch }) 
        {
            dispatch('setActiveView', { viewId: VIEW_DEFAULT })
        },
        // payload
        // requires: filter
        // optional: pageId, tabId (otherwise active is used)
        setActiveFilter({ commit, getters }, payload) 
        {
            let pageId = payload.pageId
            let tabId = payload.tabId
            if (pageId == null) pageId = getters.getActivePage
            if (tabId == null) tabId = getters.getActiveTab
            commit('SET_ACTIVE_FILTER', { pageId, tabId, filter: payload.filter })
        },
        setActiveFilterToDefault({ dispatch }) 
        {
            dispatch('setActiveFilter', { filter: FILTER_DEFAULT })
        },


        //// VIEWER

        setActiveMode({ commit }, mode) 
        {
            commit('SET_ACTIVE_MODE', mode)
        },
        setActiveModeToDefault({ commit }) 
        {
            commit('SET_ACTIVE_MODE', MODE_DEFAULT)
        },
        setActiveModeToRender({ commit }) 
        {
            commit('SET_ACTIVE_MODE', MODE_RENDER)
        },
        setActiveModeToTexture({ commit }) 
        {
            commit('SET_ACTIVE_MODE', MODE_TEXTURE)
        },
        setActiveModeToNormals({ commit }) 
        {
            commit('SET_ACTIVE_MODE', MODE_NORMALS)
        },
        setActiveModeToDepth({ commit }) 
        {
            commit('SET_ACTIVE_MODE', MODE_DEPTH)
        },
        setActiveTarget({ commit }, target) 
        {
            commit('SET_ACTIVE_TARGET', target)
        },
        setActiveTargetToDefault({ commit }) 
        {
            commit('SET_ACTIVE_TARGET', TARGET_DEFAULT)
        },
        setActiveTargetToMesh({ commit }) 
        {
            commit('SET_ACTIVE_TARGET', TARGET_MESH)
        },
        setActiveTargetToSet({ commit }) 
        {
            commit('SET_ACTIVE_TARGET', TARGET_SET)
        },
        setActiveTargetToFace({ commit }) 
        {
            commit('SET_ACTIVE_TARGET', TARGET_FACE)
        },
        setActiveTargetToEdge({ commit }) 
        {
            commit('SET_ACTIVE_TARGET', TARGET_EDGE)
        },
        setActiveViewerTargetToVertex({ commit }) 
        {
            commit('SET_ACTIVE_TARGET', TARGET_VERTEX)
        },
        setActiveCursor({ commit }, cursor) 
        {
            commit('SET_ACTIVE_CURSOR', cursor)
        },
        setActiveCursorToDefault({ commit }) 
        {
            commit('SET_ACTIVE_CURSOR', CURSOR_DEFAULT)
        },
        setActiveCursorToPointer({ commit }) 
        {
            commit('SET_ACTIVE_CURSOR', CURSOR_POINTER)
        },
        setActiveCursorToSelect({ commit }) 
        {
            commit('SET_ACTIVE_CURSOR', CURSOR_SELECT)
        },
        setEnabledMode({ commit }, { mode, enabled })
        {
            commit('SET_ENABLED_MODE', { mode, enabled })
        },
        setEnabledTarget({ commit }, { target, enabled })
        {
            commit('SET_ENABLED_TARGET', { target, enabled })
        },
        setEnabledCursor({ commit }, { cursor, enabled })
        {
            commit('SET_ENABLED_CURSOR', { cursor, enabled })
        },
        activateStudio({ commit }, { resolution, mode }) 
        {
            commit('ACTIVATE_STUDIO', { resolution, mode })
        },
        deactivateStudio({ commit }) 
        {
            commit('DEACTIVATE_STUDIO')
        },
        setActivePreset({ commit, getters }, preset)
        {
            const presetKey = getters.getActivePresetKey
            commit('SET_PRESET_CENTER', { 
                presetKey, 
                centerX: preset.center.x, 
                centerY: preset.center.y, 
                centerZ: preset.center.z 
            })
            commit('SET_PRESET_THETA', { presetKey, theta: preset.theta })
            commit('SET_PRESET_PHI', { presetKey, phi: preset.phi })
            commit('SET_PRESET_RADIUS', { presetKey, radius: preset.radius })
        },
        setActivePresetKey({ commit }, presetKey)
        {
            commit('SET_ACTIVE_PRESET_KEY', presetKey)
        },
        setActivePresetCenter({ commit, getters }, { centerX, centerY, centerZ })
        {
            const presetKey = getters.getActivePresetKey
            commit('SET_PRESET_CENTER', { presetKey, centerX, centerY, centerZ })
        },
        setActivePresetTheta({ commit, getters }, theta)
        {
            const presetKey = getters.getActivePresetKey
            commit('SET_PRESET_THETA', { presetKey, theta })
        },
        setActivePresetPhi({ commit, getters }, phi)
        {
            const presetKey = getters.getActivePresetKey
            commit('SET_PRESET_PHI', { presetKey, phi })
        },
        setActivePresetRadius({ commit, getters }, radius)
        {
            const presetKey = getters.getActivePresetKey
            commit('SET_PRESET_RADIUS', { presetKey, radius })
        },
        setSelectionAll({ commit }, { mesh, select, count })
        {
            const selection = Array.from({ length: count }, (_, i) => i)
            commit('SET_SELECTION', { mesh, select, selection })
        },
        setSelection({ commit }, { mesh, select, selection })
        {
            commit('SET_SELECTION', { mesh, select, selection })
        },
        setFocus({ commit }, focus)
        {
            commit('SET_FOCUS', focus)
        },
        setOffset({ commit }, { x, y }) {
            commit('SET_OFFSET', { x, y })
        },
        limitCamera({ commit }) 
        {
            commit('SET_CAMERA_LIMIT', true)
        },
        unlimitCamera({ commit }) 
        {
            commit('SET_CAMERA_LIMIT', false)
        },
        setLimitPhi({ commit }, { limitMin, limitMax }) 
        {
            commit('SET_LIMIT_PHI', { limitMin, limitMax })
        },
        setLimitRadius({ commit }, { limitMin, limitMax }) 
        {
            commit('SET_LIMIT_RADIUS', { limitMin, limitMax })
        },
        setAnimationFly({ commit }, animationFly) 
        {
            commit('SET_ANIMATION_FLY', animationFly)
        },
        toggleAnimationFly({ commit, getters })
        {
            const isAnimation = getters.isAnimationFly
            commit('SET_ANIMATION_FLY', !isAnimation)
        },
        setAnimationTurntable({ commit }, animationTurntable) 
        {
            commit('SET_ANIMATION_TURNTABLE', animationTurntable)
        },
        toggleAnimationTurntable({ commit, getters })
        {
            const isAnimation = getters.isAnimationTurntable
            commit('SET_ANIMATION_TURNTABLE', !isAnimation)
        },
    

        //// ASYNC ACTIONS	

        //         
        async importModel({ dispatch, rootGetters }, { 
            file, 
            base64 
        }) 
        {
            // ensure file parameter is provided
            if (file === null || file === undefined) {
                throw new Error("Please provide a file to import.")
            }

            // ensure base64 parameter is provided
            if (base64 === null || base64 === undefined) {
                throw new Error("Please provide the file content to import.")
            }      
            
            try
            {
                // variable to store item asset
                let asset = null

                // extract extension from file name
                let extension = extractExtension(file.name)

                // supported formats
                const supported = ['glb', 'gltf', 'stl', 'obj', 'ply', 'fbx', 'filmbox', 'step', 'stp']

                // if format is not supported
                if (!supported.includes(extension)) {
                    throw new Error("The file format is not supported.")
                }

                // set correct mime types
                if (extension === 'glb' || extension === 'gltf') extension = 'gltf-binary'
                if (extension === 'step') extension = 'stp'
                if (extension === 'filmbox') extension = 'fbx'
                
                // change generic mime type according to extension
                base64 = base64.replace('application/octet-stream', 'model/' + extension)

                // specify additional
                const additional = { 
                    // TODO
                }

                // if gltf, we can directly upload the item
                if (extension === 'gltf-binary')
                {
                    // call route to post the model asset
                    asset = await dispatch('api/assetsPost', {
                        fileData: base64,
                        additional
                    }, { root: true })
    
                    // no converting needed, so the base64 is the model file
                    asset.files['default'].data = base64
                    asset.files['default'].status = rootGetters['api/keyAssetStatusRetrieved']
    
                    // flag thumbnail as unrequested
                    asset.thumbnail.status = rootGetters['api/keyAssetStatusUnrequested']
    
                    // call route to check if the asset is ready
                    await dispatch('api/assetsAssetStatusGet', {
                        assetId: asset.id
                    }, { root: true })
    
                    // call route to generate thumbnail
                    await dispatch('api/modelsModelThumbnailGeneratePost', {
                        asset
                    }, { root: true })
                }
                // else, we need to convert it
                else
                {
                    // convert model file to glb
                    asset = await dispatch('api/modelsFromFileConvertPost', {
                        file: base64,
                        additional
                    }, { root: true })
                }

                // set model asset
                await dispatch('loadModelAsset', asset)
            }
            catch(error)
            {
                throw error
            }
        },

        async loadModelAsset({ commit, dispatch, rootGetters }, asset) 
        {
            // ensure asset parameter is present
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // reset 
                // TODO additional function just for model clearance (remove pages, etc.) 
                dispatch('resetInterface')
                commit('SET_MODEL_WRAPPER', null)
                
                dispatch('viewer/resetViewer', null, { root: true })

                // if asset belongs to another app
                if (asset.app !== rootGetters['app/keyAppModulator']) 
                {
                    // inform users about model cloning
                    dispatch('app/setLoadingText', 'cloning model', { root: true })

                    // clone asset
                    asset = await dispatch('api/assetsAssetClonePost', {
                        assetId: asset.id,
                        additional: asset.additional
                    }, { root: true })
                }

                // set the model asset 
                // TODO consider latest save (filekey: 'latest')
                commit('SET_MODEL_ASSET', asset)

                // if asset file default is unrequested: retrieve it
                const statusUnrequested = rootGetters['api/keyAssetStatusUnrequested']
                if (asset.files['default'].status === statusUnrequested) 
                {
                    // inform users about model retrieving
                    dispatch('app/setLoadingText', 'retrieving model', { root: true })

                    // retrieve model asset
                    // TODO move function to here
                    await dispatch('retrieveModelFile', 'default')
                }

                // inform users about model preparing
                dispatch('app/setLoadingText', 'preparing model', { root: true })

                // set active window to modify
                dispatch('setActiveWindowToModify')

                // update scene
                await dispatch('updateScene')

                commit('SET_MODEL_OUTDATED', true)

                return asset
            }
            catch(error)
            {
                console.error(error)
                throw error
            }
        },

        // TODO move into loadModel
        async retrieveModelFile({ getters, dispatch, rootGetters }, fileKey) 
        {
            try 
            {
                // get model asset
                const asset = getters.getModelAsset

                // ensure image asset exists
                if (asset === null || asset === undefined) {
                    throw new Error("Please provide a model asset.")
                }

                // call route to retrieve depth file
                const response = await dispatch('api/assetsAssetFileKeyGet', {
                    asset,
                    key: fileKey
                }, { root: true })

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // TODO move into loadModel
        async updateScene({ commit, getters, dispatch })
        {
            try
            {
                // get model asset
                const asset = getters.getModelAsset

                // ensure model asset exists
                if (asset === null || asset === undefined) {
                    throw new Error("Please provide a model asset.")
                }

                
                let model = null

                // 
                const gltfLoader = new GLTFLoader()

                function dataBuffer(data) 
                {
                    const base64 = data.split(',')[1]
                    let binaryString = atob(base64) 
                    let len = binaryString.length
                    let bytes = new Uint8Array(len) 

                    for (let i = 0; i < len; i++) 
                        bytes[i] = binaryString.charCodeAt(i)

                    return bytes.buffer
                }

                gltfLoader.parse(
                    dataBuffer(asset.files['default'].data),
                    '',
                    (gltf) => {

                        // TODO store complete gltf object for complete save
                        // scenes, animations, metadata, etc. is lost otherwise

                        // get the model (which is the scene holding all meshes)
                        const modelThree = gltf.scene

                        // create model wrapper
                        model = ModelFactory.newModelFromThree(modelThree)

                        // set model in vuex store
                        commit('SET_MODEL_WRAPPER', model)

                        // add pages for all meshes
                        model.getMeshes().forEach(mesh => {
                            dispatch('addPage', mesh.id)
                        })
                    },
                    (error) => {
                        console.error(error)
                    }
                )   

                return model
            }
            catch(error)
            {
                throw error
            }
        },

        async generateMaterialColorSingle({ dispatch }, prompt) {

            const additional = {
                'key': 'material-color-single',
                'category': 'material',
                'type': 'color',
                'subtype': 'single',
                'positive': prompt
            }

            try
            {
                // call route to generate single color material
                const response = await dispatch('api/textsGeneratePost', {
                    query: prompt,
                    additional
                }, { root: true })

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        async generateMaterialTextureGeneric({ dispatch }, prompt) {

            const additional = {
                'key': 'material-texture-generic',
                'category': 'material',
                'type': 'texture',
                'subtype': 'generic',
                'positive': prompt
            }

            prompt = "texture, albedo, tileable, seamless, " + prompt

            try
            {
                // call route to generate generic textures
                const response = await dispatch('api/imagesFromPromptGeneratePost', {
                    positive: prompt,
                    additional
                }, { root: true })

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        async generateMaterialTextureCustom({ getters, dispatch, rootGetters }, { pattern, prompt }) {

            // get model asset
            const modelAsset = getters.getModelAsset

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // inform users about texture generation
                dispatch('app/setLoadingText', 'generating material', { root: true })

                // call route to generate pattern texture                
                const patternImage = await dispatch('convertPatternToImage', pattern)

                // upload pattern image
                const patternAsset = await dispatch('api/assetsPost', {
                    fileData: patternImage,
                    additional: {
                        'key': 'material-texture-pattern',
                        'category': 'material',
                        'type': 'texture',
                        'subtype': 'pattern',
                        'reference': modelAsset.id
                    }
                }, { root: true })

                // generate image from guidance with pattern image
                const textureAssets = await dispatch('api/imagesFromGuidanceGeneratePost', {
                    parentId: patternAsset.id,
                    canny: 0.9,
                    positive: prompt,
                    resolution: 1024,
                    seeds: [ 256 ],
                    additional: {
                        'key': 'material-texture-custom',
                        'category': 'render',
                        'type': 'custom',
                        'reference': modelAsset.id
                    }
                }, { root: true })
                const textureAsset = textureAssets[0]

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                return textureAsset
            }
            catch(error)
            {
                throw error
            }
        },

        async generateMaterialTextureMaps({ dispatch }, { 
            depth = true,
            normal = false
        })
        {
            try
            {
                

            }
            catch(error)
            {
                throw error
            }
        },

        async convertPatternToImage({ dispatch }, svg)
        {
            // create a Blob from the SVG string
            const blob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' })
            const url = URL.createObjectURL(blob)

            // create an Image and load the SVG Blob
            const img = new Image()
            img.src = url

            // ensure the image loads before processing
            return new Promise((resolve, reject) => {
                img.onload = () => {
                        
                    // create a canvas element
                    const canvas = document.createElement('canvas')
                    canvas.width = 512
                    canvas.height = 512

                    // draw the SVG image on the canvas
                    const ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0)

                    // convert canvas content to Base64 PNG
                    const imageData = canvas.toDataURL('image/png')
                    resolve(imageData)

                    // clean up
                    URL.revokeObjectURL(url)
                }

                img.onerror = reject
            })
        },

        async updateMaterial({ dispatch, rootGetters }, { 
            material, 
            asset, 
        })
        {
            // ensure material parameter is provided
            if (material === null || material === undefined) {
                throw new Error("Please provide a material.")
            }

            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // inform users about model generation
                dispatch('app/setLoadingText', 'loading material', { root: true })

                // TODO remove the if => only for testing of text router
                if (asset.type != 'text')
                {

                    // call route to retrieve all asset files
                    await dispatch('api/assetsAssetFilesGet', {
                        asset,
                    }, { root: true })

                }

                // set asset in material
                material.setAsset(asset)

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })
            }
            catch(error)
            {
                throw error
            }
        },

        async generateRender({ getters, dispatch, rootGetters }, { prompt, depthImage }) 
        {
            if (prompt === null || prompt === undefined)
                throw new Error("Please provide a prompt.")

            // TODO check depth image

            // get model asset
            const modelAsset = getters.getModelAsset

            // ensure model asset exists
            if (modelAsset === null || modelAsset === undefined) 
                throw new Error("No model asset available.")

            try 
            {
                // upload depth image
                const depthAsset = await dispatch('api/assetsPost', {
                    fileKey: "data:image/png;base64," + depthImage,
                    additional: {
                        'key': 'render-depth',
                        'category': 'render',
                        'type': 'depth',
                        'reference': modelAsset.id
                    }
                }, { root: true })

                // generate image from guidance with depth image
                const renderAssets = await dispatch('api/imagesFromGuidanceGeneratePost', {
                    parentId: depthAsset.id,
                    depth: 0.9,
                    positive: prompt,
                    resolution: 1024,
                    seeds: [ 256 ],
                    additional: {
                        'key': 'render-generated',
                        'category': 'render',
                        'type': 'generated',
                        'reference': modelAsset.id
                    }
                }, { root: true })
                const renderAsset = renderAssets[0]

                /*
                console.log(renderAsset)

                // wait for asset to be ready
                await dispatch('api/assetsAssetStatusGet', {
                    assetId: renderAsset.id
                }, { root: true })

                // generate thumbnail
                await dispatch('api/imagesImageThumbnailGeneratePost', {
                    asset: renderAsset
                }, { root: true })
                */

                return renderAsset
            }
            catch(error)
            {
                throw error
            }
        },

        async saveModel({ getters, commit, dispatch, rootGetters }) 
        {
            try
            {
                // get model asset
                const modelAsset = getters.getModelAsset

                // ensure model asset exists
                if (modelAsset === null || modelAsset === undefined) {
                    throw new Error("Model asset is missing.")
                }

                // get model wrapper
                const modelWrapper = rootGetters['model/getModel']

                // ensure model wrapper exists
                if (modelWrapper === null || modelWrapper === undefined) {
                    throw new Error("Model wrapper is missing.")
                }

                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // inform users about model generation
                dispatch('app/setLoadingText', 'saving model', { root: true })

                // get three.js model from wrapper
                const modelThree = modelWrapper.three()

                // exporter for gltf-based models
                const exporter = new GLTFExporter()

                // get model data
                const modelData = await new Promise((resolve, reject) => {
                    exporter.parse(
                        modelThree,
                        (result) => {
                            const blob = new Blob([result], { type: 'model/gltf-binary' })
                            const reader = new FileReader()
                            reader.onloadend = () => {
                                resolve(reader.result)
                            }
                            reader.onerror = (error) => {
                                reject(new Error("Error reading model blob: " + error.message))
                            }
                            reader.readAsDataURL(blob)
                        },
                        (error) => {
                            reject(new Error("Error exporting model: " + error.message))
                        },
                        {
                            binary: true
                        }
                    )
                })

                // upload new model to same asset under file 'latest'
                // TODO later file keys can be savedates/versions and user can choose
                await dispatch('api/assetsAssetFilesPost', {
                    assetId: modelAsset.id,
                    file_data: modelData,
                    file_details: {},
                    file_key: 'latest'
                }, { root: true })

                // flag asset as saved
                commit('SET_MODEL_OUTDATED', false)

                return modelData
            }
            catch(exception)
            {
                throw exception
            }   
            finally
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })
            }
        },
        async downloadModel({ getters, dispatch }, { format }) 
        {
            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // save model if it is outdated
                if (getters.isModelOutdated)
                {
                    await dispatch('saveModel')
                }

                // call route to trigger download
                const response = dispatch('api/assetsAssetDownloadHelper', {
                    asset: getters.getModelAsset,
                    fileKey: 'latest',
                    format
                }, { root: true })

                return response
            }
            catch(error)
            {
                throw error
            }
            finally
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })
            }
        },
    }
}