<template>

    <!--
        CSS z-index
        global-overlay >= 500
        navigation / appbar >= 400
        app-overlay >= 300
        floating >= 200
        toolbar / tabs >= 100
    -->

    <router-view />
</template>

<script>
    import { mapActions } from 'vuex'
    import { version } from '../package.json'

    export default {
        name: 'App',
        provide() {
			return {
				openLink: this.openLink,
				openNewTab: this.openNewTab,
                // TODO think about moving dialog functionality to here
			}
		},
        methods: {
            ...mapActions('app', [
                'initializeApp'
            ]),
            ...mapActions('api', [
                'initializeAPI'
            ]),
            openLink(url) {
				window.open(url, '_self')
			},
			openNewTab(url) {
				window.open(url, '_blank', 'noopener,noreferrer')
			},
        },
        mounted() 
        {
            // initialize the app vuex store
            this.initializeApp(version)

            // initialize the api vuex store
            this.initializeAPI()
        }
    }
</script>

<style>
    html {
        overflow: hidden;
        font-size: 18px !important;
    }

    body {
        margin: 0px;
        padding: 0px;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        border: 0px;
        overflow-x: hidden;
        overflow-y: auto;
        color: rgb(var(--v-theme-primary));
        background: rgb(var(--v-theme-darkerbase));
    }

    ::-webkit-scrollbar {
        display: none;
    }

    ::-webkit-scrollbar-track {
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        display: none;
    }

    ::-webkit-scrollbar-thumb:hover {
        display: none;
    }

    * {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    @media (max-width: 2000px) {
        html {
            font-size: 17px !important;
        }
    }

    @media (max-width: 1600px) {
        html {
            font-size: 16px !important;
        }
    }

    @media (max-width: 1200px) {
        html {
            font-size: 14px !important;
        }
    }

    @media (max-width: 750px) {
        ::-webkit-scrollbar {
            display: none;
        }
    }
</style>
