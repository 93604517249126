import { createStore } from 'vuex'
import { api } from '@/stores/vuex-api'
import { app } from '@/stores/vuex-app'
import { library } from '@/stores/vuex-library'
import { sketch } from '@/stores/vuex-sketch'
import { flow } from '@/stores/vuex-flow'
import { mesh } from '@/stores/vuex-mesh'
import { texture } from '@/stores/vuex-texture'
import { model } from '@/stores/vuex-model'
import { box } from '@/stores/vuex-box'


const store = createStore({
    modules: {
        // base modules
        api,
        app,

        // app modules
        library,
        sketch,

        // prototype modules
        flow,
        mesh,
        texture,
        
        // legacy modules (not in app list)
        box,
        model
    }
})

export default store