/*
 * Box Store
 */


//// IMPORTS
import * as THREE from 'three'
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter.js'
import { Container, PerfectContainer } from '@/classes/box/Container.js'
import { Infill } from '@/classes/box/Infill.js'
import { centerGeometry, normalizeGeometry, divideGeometry, repairTextureMapping } from '@/utils/geometry.js'
import { computeBoxVolume, computeMeshVolume } from '@/utils/volume.js'
import { extractExtension, downloadFile } from '@/utils/file.js'
import { calculateLimitedDimensions } from '@/utils/geometry.js'
import { loadMesh } from '@/utils/loader.js'
import { round } from '@/utils/math.js'


//// CONSTANTS

// windows
const WINDOW_ITEM = 'item'
const WINDOW_CONTAINER = 'container'
const WINDOW_INFILL = 'infill'
const WINDOW_RENDER = 'render'
const WINDOW_EXPORT = 'export'
const WINDOW_DEFAULT = WINDOW_ITEM

// direction
// TODO make it into enum if that exists ....
const DIRECTION_BIRD = 6
const DIRECTION_RIGHT = 2
const DIRECTION_LEFT = 3
const DIRECTION_TOP = 4
const DIRECTION_BOTTOM = 5
const DIRECTION_FRONT = 0
const DIRECTION_BACK = 1

const CONVERSION_SIZE = 10.0

const CAMERA_PERSPECTIVE_DEFAULT = DIRECTION_BIRD

const MODEL_TYPE_ITEM = 'item'
const MODEL_TYPE_CONTAINER = 'container'
const MODEL_TYPE_INFILL = 'infill'

const CONTAINER_SPACING_MODE_AUTOMATIC = 'automatic'
const CONTAINER_SPACING_MODE_MANUAL = 'manual'
const CONTAINER_SPACING_MODE_DEFAULT = CONTAINER_SPACING_MODE_AUTOMATIC

const CONTAINER_SPACING_AUTOMATIC_DEFAULT = 20
const CONTAINER_SPACING_MANUAL_DEFAULT = 20

const INFILL_TOLERANCE_DEFAULT = 1

const RENDER_MODE_TEXTURED = 'textured'
const RENDER_MODE_SOLID = 'solid'
const RENDER_MODE_TRANSPARENT = 'transparent'
const RENDER_MODE_WIREFRAME = 'wireframe'
const RENDER_MODE_DEFAULT = RENDER_MODE_TEXTURED

const RENDER_FORMAT_PNG = 'png'
const RENDER_FORMAT_JPG = 'jpg'
const RENDER_FORMAT_DEFAULT = RENDER_FORMAT_PNG

const EXPORT_SCALING_DEFAULT = 100

const EXPORT_TYPE_POSITIVE = 'positive'
const EXPORT_TYPE_NEGATIVE = 'negative'

const EXPORT_FORMAT_STEP = 'step'
const EXPORT_FORMAT_STL = 'stl'
const EXPORT_FORMAT_DEFAULT = EXPORT_FORMAT_STL

// TODO make the following two functions into a class 
function newSize() {
    return {
        width: 0,
        height: 0,
        depth: 0
    }
}

function convertSize(direction, size) {
    let newSize = null
    switch (direction) {
        case DIRECTION_RIGHT:
        case DIRECTION_LEFT:
            newSize = {
                width: size.height,
                height: size.width,
                depth: size.depth
            }
            break
        case DIRECTION_TOP:
        case DIRECTION_BOTTOM:
            newSize = {
                width: size.width,
                height: size.height,
                depth: size.depth
            }
            break
        case DIRECTION_FRONT:
        case DIRECTION_BACK:
            newSize = {
                width: size.width,
                height: size.depth,
                depth: size.height
            }
            break
    }
    return newSize
}


export const box = {
    namespaced: true,
    state: {
        window: WINDOW_DEFAULT,
        camera: {
            perspective: CAMERA_PERSPECTIVE_DEFAULT
        },
        item: {
            visible: true,
            asset: null,
            mesh: null,
            center: [0, 0, 0],
            size: {
                original: newSize(),
                current: newSize()
            },
            direction: DIRECTION_TOP,
            optimized: null,
            debugging: false
        },
        container: {
            visible: true,
            slots: {
                columns: 1,
                stacks: 1,
                rows: 1
            },
            options: [],
            selection: null,
            spacing: {
                mode: CONTAINER_SPACING_MODE_DEFAULT,
                align: true,
                automatic: {
                    inner: CONTAINER_SPACING_AUTOMATIC_DEFAULT,
                    outer: CONTAINER_SPACING_AUTOMATIC_DEFAULT
                },
                manual: {
                    inner: CONTAINER_SPACING_MANUAL_DEFAULT,
                    outer: CONTAINER_SPACING_MANUAL_DEFAULT
                }
            },
            sides: {
                highlight: null,
                selection: null
            }
        },
        infill: {
            visible: true,
            list: [],
            selection: null,
            asset: null,
            mesh: null,
            calculated: false,
            tolerance: INFILL_TOLERANCE_DEFAULT,
            optimized: true,
            resolution: 64,
            smooth: true,
            inflate: true
        },
        render: {
            mode: RENDER_MODE_DEFAULT,
            format: RENDER_FORMAT_DEFAULT
        },
        export: {
            scaling: EXPORT_SCALING_DEFAULT,
            type: EXPORT_TYPE_POSITIVE,
            format: EXPORT_FORMAT_DEFAULT
        }
    },
    mutations: {
        SET_WINDOW(state, window) {
            state.window = window
        },

        SET_CAMERA_PERSPECTIVE(state, perspective) {
            state.camera.perspective = perspective
        },

        RESET_ITEM(state) {
            state.item.visible = true
            state.item.asset = null
            state.item.mesh = null
            state.item.center = [ 0, 0, 0 ]
            state.item.size = {
                original: newSize(),
                current: newSize()
            }
            state.item.direction = DIRECTION_TOP
            state.item.optimized = null
            state.item.debugging = false
        },
        SET_ITEM_VISIBLE(state, visible) {
            state.item.visible = visible
        },
        SET_ITEM_ASSET(state, asset) {
            state.item.asset = asset
        },
        SET_ITEM_MESH(state, mesh) {
            state.item.mesh = mesh
        },
        SET_ITEM_CENTER(state, { x, y, z })
        {
            state.item.center = [x, y, z]
        },
        SET_ITEM_SIZE_ORIGINAL(state, { width, height, depth }) {
            state.item.size.original.width = width
            state.item.size.original.height = height
            state.item.size.original.depth = depth
        },
        SET_ITEM_SIZE_CURRENT(state, { width, height, depth }) 
        {
            const newSize = {
                width: round(width, 2),
                height: round(height, 2),
                depth: round(depth, 2)
            }

            state.item.size.current = newSize
        },
        SET_ITEM_SIZE_CURRENT_CONVERTED(state, { width, height, depth }) 
        {
            const newSize = convertSize(state.item.direction, {
                width: round(width, 2),
                height: round(height, 2),
                depth: round(depth, 2)
            })

            state.item.size.current = newSize
        },
        SET_ITEM_DIRECTION(state, direction) 
        {
            state.item.direction = direction
        },
        SET_ITEM_OPTIMIZED(state, optimized)
        {
            state.item.optimized = optimized
        },
        SET_ITEM_DEBUGGING(state, debugging)
        {
            state.item.debugging = debugging
        },

        RESET_CONTAINER(state) {
            state.container.visible = true
            state.container.slots.rows = 1
            state.container.slots.stacks = 1
            state.container.slots.columns = 1
            state.container.selection = null
            state.container.spacing.mode = CONTAINER_SPACING_MODE_DEFAULT
            state.container.spacing.automatic.inner = CONTAINER_SPACING_AUTOMATIC_DEFAULT
            state.container.spacing.automatic.outer = CONTAINER_SPACING_AUTOMATIC_DEFAULT
            state.container.spacing.manual.inner = CONTAINER_SPACING_MANUAL_DEFAULT
            state.container.spacing.manual.outer = CONTAINER_SPACING_MANUAL_DEFAULT
            state.container.sides.highlight = null
            state.container.sides.selection = null
        },
        RESET_CONTAINER_OPTIONS(state) {
            state.container.options = []
        },
        SET_CONTAINER_VISIBLE(state, visible) {
            state.container.visible = visible
        },
        SET_CONTAINER_COLUMNS(state, columns) {
            state.container.slots.columns = columns
        },
        SET_CONTAINER_STACKS(state, stacks) {
            state.container.slots.stacks = stacks
        },
        SET_CONTAINER_ROWS(state, rows) {
            state.container.slots.rows = rows
        },
        SET_CONTAINER_OPTIONS(state, options) {
            state.container.options = [...options]

            // find the current selection in the new options
            const selectionExists = state.container.options.some(
                (option) => option.id === state.container.selection
            )
            // if it does not exist, then reset the selection
            if (!selectionExists) state.container.selection = null
        },
        ADD_CONTAINER_OPTION(state, container) {
            state.container.options.push(container)
        },
        REMOVE_CONTAINER_OPTION(state, containerId) {
            state.container.options = state.container.options.filter(
                (option) => option.id !== containerId
            )
        },
        SET_CONTAINER_SELECTION(state, selection) {
            state.container.selection = selection
        },
        SET_CONTAINER_SPACING_MODE(state, mode) {
            state.container.spacing.mode = mode
        },
        SET_CONTAINER_SPACING_ALIGN(state, align) {
            state.container.spacing.align = align
        },
        SET_CONTAINER_SPACING_AUTOMATIC(state, { inner, outer }) {
            state.container.spacing.automatic = {
                inner,
                outer
            }
        },
        SET_CONTAINER_SPACING_MANUAL(state, { inner, outer }) {
            state.container.spacing.manual = {
                inner,
                outer
            }
        },
        SET_CONTAINER_SIDES_HIGHLIGHT(state, highlight) {
            state.container.sides.highlight = highlight
        },
        SET_CONTAINER_SIDES_SELECTION(state, selection) {
            state.container.sides.selection = selection
        },

        RESET_INFILL(state) {
            state.infill.visible = true
            state.infill.list = []
            state.infill.selection = null
            state.infill.asset = null
            state.infill.mesh = null
            state.infill.calculated = false
            Infill.lastId = 0
        },
        ADD_INFILL(state, infill) {
            state.infill.list.push(infill)
        },
        REMOVE_INFILL(state, infillId) {
            state.infill.list = state.infill.list.filter(infill => infill.id !== infillId)
            if (state.infill.selection == infillId) state.infill.selection = null
        },
        SET_INFILL_VISIBLE(state, visible) {
            state.infill.visible = visible
        },
        SET_INFILL_LIST(state, list) {
            state.infill.list = list
            const hasSelection = state.infill.list.some(infill => infill.id === state.infill.selection)
            if (!hasSelection) state.infill.selection = null
        },      
        SET_INFILL_SELECTION(state, selection) {
            state.infill.selection = selection
        },
        SET_INFILL_ASSET(state, asset) {
            state.infill.asset = asset
        },
        SET_INFILL_MESH(state, mesh) {
            state.infill.mesh = mesh
        },
        SET_INFILL_CALCULATED(state, calculated) {
            state.infill.calculated = calculated
        },
        SET_INFILL_TOLERANCE(state, tolerance) {
            state.infill.tolerance = tolerance
        },
        SET_INFILL_OPTIMIZED(state, optimized) {
            state.infill.optimized = optimized
        },
        SET_INFILL_OPTIMIZE_RESOLUTION(state, resolution) {
            state.infill.resolution = resolution
        },
        SET_INFILL_OPTIMIZE_SMOOTH(state, smooth) {
            state.infill.smooth = smooth
        },
        SET_INFILL_OPTIMIZE_INFLATE(state, inflate) {
            state.infill.inflate = inflate
        },

        SET_RENDER_MODE(state, mode) {
            state.render.mode = mode
        },
        SET_RENDER_FORMAT(state, format) {
            state.render.format = format
        },

        SET_EXPORT_SCALING(state, scaling) {
            state.export.scaling = scaling
        },
        SET_EXPORT_TYPE(state, type) {
            state.export.type = type
        },
        SET_EXPORT_FORMAT(state, format) {
            state.export.format = format
        }
    },
    getters: {
        keyWindowDefault: () => WINDOW_DEFAULT,
        keyWindowItem: () => WINDOW_ITEM,
        keyWindowContainer: () => WINDOW_CONTAINER,
        keyWindowInfill: () => WINDOW_INFILL,
        keyWindowRender: () => WINDOW_RENDER,
        keyWindowExport: () => WINDOW_EXPORT,
        keyDirectionBird: () => DIRECTION_BIRD,
        keyDirectionRight: () => DIRECTION_RIGHT,
        keyDirectionLeft: () => DIRECTION_LEFT,
        keyDirectionTop: () => DIRECTION_TOP,
        keyDirectionBottom: () => DIRECTION_BOTTOM,
        keyDirectionFront: () => DIRECTION_FRONT,
        keyDirectionBack: () => DIRECTION_BACK,
        keyConversionSize: () => CONVERSION_SIZE,
        keyCameraPerspectiveDefault: () => CAMERA_PERSPECTIVE_DEFAULT,
        keyModelTypeItem: () => MODEL_TYPE_ITEM,
        keyModelTypeContainer: () => MODEL_TYPE_CONTAINER,
        keyModelTypeInfill: () => MODEL_TYPE_INFILL,
        keyContainerSpacingModeDefault: () => CONTAINER_SPACING_MODE_DEFAULT,
        keyContainerSpacingModeAutomatic: () => CONTAINER_SPACING_MODE_AUTOMATIC,
        keyContainerSpacingModeManual: () => CONTAINER_SPACING_MODE_MANUAL,
        keyContainerSpacingAutomaticDefault: () => CONTAINER_SPACING_AUTOMATIC_DEFAULT,
        keyContainerSpacingManualDefault: () => CONTAINER_SPACING_MANUAL_DEFAULT,
        keyInfillToleranceDefault: () => INFILL_TOLERANCE_DEFAULT,
        keyRenderModeDefault: () => RENDER_MODE_DEFAULT,
        keyRenderModeTextured: () => RENDER_MODE_TEXTURED,
        keyRenderModeSolid: () => RENDER_MODE_SOLID,
        keyRenderModeTransparent: () => RENDER_MODE_TRANSPARENT,
        keyRenderModeWireframe: () => RENDER_MODE_WIREFRAME,
        keyRenderFormatDefault: () => RENDER_FORMAT_DEFAULT,
        keyRenderFormatPNG: () => RENDER_FORMAT_PNG,
        keyRenderFormatJPG: () => RENDER_FORMAT_JPG,
        keyExportTypePositive: () => EXPORT_TYPE_POSITIVE,
        keyExportTypeNegative: () => EXPORT_TYPE_NEGATIVE,
        keyExportFormatDefault: () => EXPORT_FORMAT_DEFAULT,
        keyExportFormatSTEP: () => EXPORT_FORMAT_STEP,
        keyExportFormatSTL: () => EXPORT_FORMAT_STL,
        isWindowDefault: state => state.window === WINDOW_DEFAULT,
        isWindowItem: state => state.window === WINDOW_ITEM,
        isWindowContainer: state => state.window === WINDOW_CONTAINER,
        isWindowInfill: state => state.window === WINDOW_INFILL,
        isWindowRender: state => state.window === WINDOW_RENDER,
        isWindowExport: state => state.window === WINDOW_EXPORT,
        isCameraPerspectiveDefault: state => state.camera.perspective === CAMERA_PERSPECTIVE_DEFAULT, 
        isCameraPerspectiveBird: state => state.camera.perspective === DIRECTION_BIRD, 
        isCameraPerspectiveRight: state => state.camera.perspective === DIRECTION_RIGHT,
        isCameraPerspectiveLeft: state => state.camera.perspective === DIRECTION_LEFT,
        isCameraPerspectiveTop: state => state.camera.perspective === DIRECTION_TOP,
        isCameraPerspectiveBottom: state => state.camera.perspective === DIRECTION_BOTTOM,
        isCameraPerspectiveFront: state => state.camera.perspective === DIRECTION_FRONT,
        isCameraPerspectiveBack: state => state.camera.perspective === DIRECTION_BACK,
        isItemVisible: state => state.item.visible,
        isItemHuge: (state, getters) => getters.getItemSizeCurrentMagnitude > 1000,
        isItemDebugging: state => state.item.debugging,
        isContainerVisible: state => state.container.visible,
        isContainerUndersized: (state, getters) => (option) => {
            const { width, height, depth } = option.dimensions
            const space = getters.getContainerSpaceSelectedMinimum
            return space.width > width || space.height > height || space.depth > depth
        },
        isContainerSelectedUndersized: (state, getters) => {
            const option = getters.getContainerOptionSelected
            if (option === null) return false
            return getters.isContainerUndersized(option)
        },
        isContainerColumnsOversized: (state, getters) => {
            const option = getters.getContainerOptionSelected
            if (option === null) return false
            const space = getters.getContainerSpaceSelectedMinimum
            if (space.width > option.dimensions.width) return true
            return false
        },
        isContainerStacksOversized: (state, getters) => {
            const option = getters.getContainerOptionSelected
            if (option === null) return false
            const space = getters.getContainerSpaceSelectedMinimum
            if (space.height > option.dimensions.height) return true
            return false
        },
        isContainerRowsOversized: (state, getters) => {
            const option = getters.getContainerOptionSelected
            if (option === null) return false
            const space = getters.getContainerSpaceSelectedMinimum
            if (space.depth > option.dimensions.depth) return true
            return false
        },
        isContainerSpacingAutomatic: state => state.container.spacing.mode === CONTAINER_SPACING_MODE_AUTOMATIC,
        isContainerSpacingManual: state => state.container.spacing.mode === CONTAINER_SPACING_MODE_MANUAL,
        isContainerSpacingAlign: state => state.container.spacing.align,

        isInfillVisible: state => state.infill.visible,
        isInfillSelected: state => state.infill.selection !== null,
        isInfillCalculated: state => state.infill.calculated,
        isInfillOptimized: state => state.infill.optimized,
        isInfillOptimizeSmooth: state => state.infill.smooth,
        isRenderModeDefault: state => state.render.mode === RENDER_MODE_DEFAULT,
        isRenderModeTextured: state => state.render.mode === RENDER_MODE_TEXTURED,
        isRenderModeSolid: state => state.render.mode === RENDER_MODE_SOLID,
        isRenderModeTransparent: state => state.render.mode === RENDER_MODE_TRANSPARENT,
        isRenderModeWireframe: state => state.render.mode === RENDER_MODE_WIREFRAME,
        hasItem: state => state.item.mesh !== null,
        hasItemAsset: state => state.item.asset !== null,
        hasItemOptimized: state => state.item.optimized != null,
        hasItemWarning: (state, getters) => {
            if (getters.isItemHuge) return true
            return false
        },
        hasContainer: state => state.container.selection !== null,
        hasContainerSidesSelection: state => state.container.sides.selection !== null,
        hasContainerWarning: (state, getters) => {
            if (getters.isContainerSelectedUndersized) return true
            return false
        },
        hasInfill: state => state.infill.list.length > 0,
        hasInfillAsset: state => state.infill.asset !== null,
        hasInfillWarning: (state, getters) => {
            return false
        },
        hasWarning: (state, getters) => {
            if (getters.isWindowItem) return getters.hasItemWarning
            if (getters.isWindowContainer) return getters.hasContainerWarning
            if (getters.isWindowInfill) return getters.hasInfillWarning
            return false
        },

        getDirectionLabel: state => (direction) => {
            if (direction === DIRECTION_RIGHT) return 'right'
            if (direction === DIRECTION_LEFT) return 'left'
            if (direction === DIRECTION_TOP) return 'top'
            if (direction === DIRECTION_BOTTOM) return 'bottom'
            if (direction === DIRECTION_FRONT) return 'front'
            if (direction === DIRECTION_BACK) return 'back'
            if (direction === DIRECTION_BIRD) return 'bird'
            return ''
        },
        getActiveWindow: state => state.window,
        getCameraPerspective: state => state.camera.perspective,

        getItemAsset: state => state.item.asset,
        getItemMesh: state => state.item.mesh,
        getItemCenter: state => state.item.center,
        getItemSizeOriginal: state => state.item.size.original,
        getItemSizeCurrent: state => state.item.size.current,
        getItemSizeCurrentConverted: state => {
            return convertSize(state.item.direction, state.item.size.current)
        },
        getItemSizeCurrentMagnitude: state => {
            return Math.max(
                state.item.size.current.width, 
                state.item.size.current.height,
                state.item.size.current.depth
            )
        },
        getItemVolume: (state, getters) => {
            const mesh = getters.getItemMesh
            if (mesh === null) return 0
            const size = getters.getItemSizeCurrent
            if (size === null) return 0
            return computeMeshVolume(mesh, [size.width, size.height, size.depth])
        },
        getItemDirection: state => state.item.direction,
        getItemDirectionEuler: state => {
            switch(state.item.direction) 
            {
                case DIRECTION_RIGHT:
                    return [0, 0, -90]
                case DIRECTION_LEFT:
                    return [0, 0,  90]
                case DIRECTION_TOP:
                    return [0, 0, 0] // default
                case DIRECTION_BOTTOM:
                    return [0, 0, 180]
                case DIRECTION_FRONT:
                    return [ 90, 0, 0]
                case DIRECTION_BACK:
                    return [-90, 0, 0]
            }
            return [0, 0, 0]
        },
        getItemOptimized: state => state.item.optimized,
        getItemRecents: (state, getters, rootState, rootGetters) => {
            let assets = rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppBox'],
                rootGetters['api/keyAssetTypeModel']
            )
            if (assets) 
            {
                assets = assets
                .filter(asset => asset.additional?.type === MODEL_TYPE_ITEM)
                .sort((a, b) => b.created - a.created)
                return assets.slice(0, 3)
            } 
            return null
        },
        getItemExamples: (state, getters, rootState, rootGetters) => {
            let assets = rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppSketch'],
                rootGetters['api/keyAssetTypeModel']
            )
            if (assets) {
                assets = assets.sort((a, b) => b.created - a.created)
                return assets.slice(0, 3)
            }
            return null
        },

        getContainerColumns: state => state.container.slots.columns,
        getContainerStacks: state => state.container.slots.stacks,
        getContainerRows: state => state.container.slots.rows,
        getContainerItemCount: state => {
            return state.container.slots.columns *
                state.container.slots.stacks *
                state.container.slots.rows
        },
        getContainerOptions: (state, getters) => {
            const options = state.container.options
            if (options === null) return null

            const perfectDimensions = getters.getContainerSpaceSelectedMinimum
            options.forEach(option => {
                if (option instanceof PerfectContainer) {
                    option.updateDimensions(
                        Math.ceil(perfectDimensions.width),
                        Math.ceil(perfectDimensions.height),
                        Math.ceil(perfectDimensions.depth)
                    )
                }
            })

            return options
        },
        getContainerOptionSelected: state => { 
            return state.container.options.find(option => 
                option.id === state.container.selection) || null
        },
        getContainerSelection: state => state.container.selection,
        getContainerSize: (state, getters) => {
            const option = getters.getContainerOptionSelected
            return option?.dimensions
        },
        getContainerSizeMinimum: (state, getters) => {
            const size = getters.getContainerSize
            if (size === null || size === undefined) return 0
            return Math.min(
                size.width, 
                size.height,
                size.depth
            )
        },
        getContainerSizeMagnitude: (state, getters) => {
            const size = getters.getContainerSize
            if (size === null || size === undefined) return 0
            return Math.max(
                size.width, 
                size.height,
                size.depth
            )
        },
        getContainerVolume: (state, getters) => (option) => {
            if (option === null) return 0
            const size = option.dimensions
            return computeBoxVolume(size.width, size.height, size.depth)
        },
        getContainerSelectedVolume: (state, getters) => {
            const option = getters.getContainerOptionSelected
            if (option) return getters.getContainerVolume(option)
            return 0
        },
        getContainerSpacingMode: state => state.container.spacing.mode,
        getContainerSpacingAutomatic: state => {
            return { 
                inner: state.container.spacing.automatic.inner, 
                outer: state.container.spacing.automatic.outer
            }
        },
        getContainerSpacingManual: state => {
            return {
                inner: state.container.spacing.manual.inner,
                outer: state.container.spacing.manual.outer
            }
        },
        getContainerSpacingCalculatedAutomatic: (state, getters) => {

            // get automatic spacing (minimum spacing)
            const minimumSpacing = state.container.spacing.automatic

            // get selected container option
            const selectedOption = getters.getContainerOptionSelected

            // if option selected and it is not a perfect container
            if (selectedOption && !(selectedOption instanceof PerfectContainer))
            {
                // helper function to calculate spacing for one dimension
                const calculate = (containerSize, itemCount, itemSize) => {
        
                    // get available space
                    const availableSpace = containerSize - (itemSize * itemCount)
        
                    // calculate required space
                    const requiredSpace = ((itemCount - 1) * minimumSpacing.inner) + (2 * minimumSpacing.outer)
        
                    // if we have more space availabe than is required
                    if (availableSpace > requiredSpace)
                    {
                        // calculate additional space
                        const additionalSpace = availableSpace - requiredSpace
                        
                        // calculate additional spacing
                        const additionalSpacing = additionalSpace / (itemCount + 1)
        
                        return {
                            inner: minimumSpacing.inner + additionalSpacing,
                            outer: minimumSpacing.outer + additionalSpacing
                        }
                    }
        
                    // otherwise, then we use minimum spacing
                    return minimumSpacing
                }

                // get container dimensions
                const dimensions = selectedOption.dimensions

                // get columns and rows
                const columns = getters.getContainerColumns
                const stacks = getters.getContainerStacks
                const rows = getters.getContainerRows

                // get item dimensions
                const itemSize = getters.getItemSizeCurrentConverted

                // calculate spacing for all three dimensions
                const spacingWidth = calculate(dimensions.width, columns, itemSize.width)
                const spacingHeight = calculate(dimensions.height, stacks, itemSize.height)
                const spacingDepth = calculate(dimensions.depth, rows, itemSize.depth)

                return { 
                    width: spacingWidth, 
                    height: spacingHeight, 
                    depth: spacingDepth 
                }
            }
            else
            {
                // return minimum spacing
                return {
                    width: {
                        inner: minimumSpacing.inner,
                        outer: minimumSpacing.outer
                    },
                    height: {
                        inner: minimumSpacing.inner,
                        outer: minimumSpacing.outer
                    },
                    depth: {
                        inner: minimumSpacing.inner,
                        outer: minimumSpacing.outer
                    }
                }
            }
        },
        getContainerSpacingCalculatedManual: (state, getters) => {
            const fixedSpacing = state.container.spacing.manual
            return {
                width: {
                    inner: fixedSpacing.inner,
                    outer: fixedSpacing.outer
                },
                height: {
                    inner: fixedSpacing.inner,
                    outer: fixedSpacing.outer
                },
                depth: {
                    inner: fixedSpacing.inner,
                    outer: fixedSpacing.outer
                }
            }
        },
        getContainerSpacingSelected: (state, getters) => {
            if (getters.isContainerSpacingAutomatic) return getters.getContainerSpacingAutomatic
            return getters.getContainerSpacingManual
        },
        getContainerSpacingSelectedCalculated: (state, getters) => {
            if (getters.isContainerSpacingAutomatic) return getters.getContainerSpacingCalculatedAutomatic
            return getters.getContainerSpacingCalculatedManual
        },
        getContainerSpace: (state, getters) => (spacingCalculated) => {

            // helper function to calculate space for one dimension
            const calculate = (spacing, itemCount, itemSize) => {
                return (2 * spacing.outer) + ((itemCount - 1) * spacing.inner) + (itemCount * itemSize)
            }

            // get needed state variables
            const columns = getters.getContainerColumns
            const stacks = getters.getContainerStacks
            const rows = getters.getContainerRows
            const size = getters.getItemSizeCurrentConverted

            // calculate container space for all three dimensions
            const width = calculate(spacingCalculated.width, columns, size.width)
            const height = calculate(spacingCalculated.height, stacks, size.height)
            const depth = calculate(spacingCalculated.depth, rows, size.depth)
            
            return {
                width,
                height,
                depth
            }
        },
        getContainerSpaceSelectedMinimum: (state, getters) => {
            const spacing = getters.getContainerSpacingSelected
            return getters.getContainerSpace({
                width: spacing,
                height: spacing,
                depth: spacing
            })
        },
        getContainerSpaceSelected: (state, getters) => {
            const spacing = getters.getContainerSpacingSelectedCalculated
            return getters.getContainerSpace(spacing)
        },
        getContainerSlots: (state, getters) => {

            const slots = []

            // get needed state variables
            const size = getters.getItemSizeCurrentConverted
            const columns = getters.getContainerColumns
            const rows = getters.getContainerRows
            const spacing = getters.getContainerSpacingSelectedCalculated
            const space = getters.getContainerSpaceSelected

            const calculate = (itemIndex, itemSize, spacing, containerSize) => {
                return itemIndex * itemSize + spacing.outer + itemIndex * spacing.inner - containerSize / 2 + itemSize / 2
            }

            // iterate over columns
            for (let column = 0; column < columns; column++) 
            {
                // iterate over rows
                for (let row = 0; row < rows; row++) 
                {
                    // calculate the position of the slot
                    const x = calculate(column, size.width, spacing.width, space.width)
                    const y = 0
                    const z = calculate(row, size.depth, spacing.depth, space.depth)

                    // add current item position as slot
                    slots.push({ x, y, z })
                }
            }

            return slots
        },
        getContainerSidesHighlight: state => state.container.sides.highlight,
        getContainerSidesSelection: state => state.container.sides.selection,

        getInfillList: state => state.infill.list,
        getInfillCount: state => state.infill.list.length,
        getInfillSelection: state => state.infill.selection,
        getInfillSelected: state => {
            if (state.infill.selection === null) return null
            return state.infill.list.find(
                infill => infill.id === state.infill.selection
            ) || null
        },
        getInfillVolume: (state, getters) => (infill) => {
            const attributes = getters.getInfillAttributes(infill)
            const { dimensions, shift } = calculateLimitedDimensions(
                attributes.width,
                attributes.height,
                attributes.depth,
                attributes.position,
                attributes.limit
            )

            return computeBoxVolume(
                dimensions[0] * CONVERSION_SIZE,
                dimensions[1] * CONVERSION_SIZE,
                dimensions[2] * CONVERSION_SIZE
            )
        },
        getInfillVolumeTotal: (state, getters) => {
            if (getters.isInfillCalculated)
                return computeMeshVolume(getters.getInfillMesh)

            let infillVolume = 0
            for (const infill of getters.getInfillList) 
            {
                infillVolume += getters.getInfillVolume(infill)
            }

            return infillVolume
        },
        getInfillVolumeSides: (state, getters) => {
            const side = getters.getContainerSidesSelection
            let infillVolume = 0
            for (const infill of getters.getInfillList) 
            {
                if (infill.side === side)
                    infillVolume += getters.getInfillVolume(infill)
            }

            return infillVolume
        },
        getInfillAttributes: (state, getters) => (infill) => {

            const id = infill.id

            const base = infill.dimensions.base / CONVERSION_SIZE
            const extent = infill.dimensions.height / CONVERSION_SIZE

            let { width, height, depth } = convertSize(infill.side, {
                width: base,
                height: extent,
                depth: base
            })

            const position = infill.position

            const option = getters.getContainerOptionSelected
            
            const limit = [
                option.dimensions.width / CONVERSION_SIZE, 
                option.dimensions.height / CONVERSION_SIZE, 
                option.dimensions.depth / CONVERSION_SIZE]

            const edge = infill.radii.edge / CONVERSION_SIZE
            const corner = infill.radii.corner / CONVERSION_SIZE

            return {    
                id, 
                width,
                height,
                depth,
                position,
                limit,
                edge,
                corner
            }
        },
        getInfillAsset: state => state.infill.asset,
        getInfillMesh: state => state.infill.mesh,
        getInfillTolerance: state => state.infill.tolerance,
        getInfillOptimizeResolution: state => state.infill.resolution,
        getInfillOptimizeInflate: state => state.infill.inflate,

        getRenderMode: state => state.render.mode,
        getRenderFormat: state => state.render.format,
        getRenderFormats: () => [
            { id: 0, title: RENDER_FORMAT_PNG}, 
            { id: 1, title: RENDER_FORMAT_JPG}
        ],
        getExportScaling: state => state.export.scaling,
        getExportType: state => state.export.type,
        getExportFormat: state => state.export.format,
        getExportFormats: () => [
            //{ id: 0, title: EXPORT_FORMAT_STEP },
            { id: 0, title: EXPORT_FORMAT_STL }
        ]
    },
    actions: {
        initializeBox({ dispatch }) {
            dispatch('fetchAppAdditional')
        },

        async fetchAppAdditional({ commit, dispatch }) {
            try
            {
                const response = await dispatch('api/appsAppAdditionalGet', 
                    null, { root: true })

                commit('RESET_CONTAINER_OPTIONS')

                // add perfect container (always first)
                const perfectContainer = new PerfectContainer()
                commit('ADD_CONTAINER_OPTION', perfectContainer)

                // add retrieved container options
                for (const key in response.containers) 
                {
                    const option = response.containers[key]
                    const container = new Container(
                        option.label,
                        option.width, option.height, option.depth
                    )
                    commit('ADD_CONTAINER_OPTION', container)
                }

                return response
            }
            catch(error)
            {
                //throw error
                console.log('[ERROR] App data not accessable')
            }
        },
        async updateAppAdditional({ getters, commit, dispatch }) 
        {
            // retrieve all states that are stored in additional
            const options = getters.getContainerOptions
            const containers = []
            for (const option of options) {
                containers.push({
                    label: option.label,
                    width: option.dimensions.width,
                    height: option.dimensions.height,
                    depth: option.dimensions.depth
                })
            }

            // call route to put app additional
            return dispatch('api/appsAppAdditionalPut', {
                additional: {
                    containers
                }
            }, { root: true })
        },

        setActiveWindow({ commit }, window) {
            commit('SET_WINDOW', window)
        },
        setActiveWindowToDefault({ commit }) {
            commit('SET_WINDOW', WINDOW_DEFAULT)
        },
        setActiveWindowToItem({ commit }) {
            commit('SET_WINDOW', WINDOW_ITEM)
        },
        setActiveWindowToContainer({ commit }) {
            commit('SET_WINDOW', WINDOW_CONTAINER)
        },
        setActiveWindowToInfill({ commit }) {
            commit('SET_WINDOW', WINDOW_INFILL)
        },
        setActiveWindowToRender({ commit }) {
            commit('SET_WINDOW', WINDOW_RENDER)
        },
        setActiveWindowToExport({ commit }) {
            commit('SET_WINDOW', WINDOW_EXPORT)
        },

        setCameraPerspective({ commit }, perspective) {
            commit('SET_CAMERA_PERSPECTIVE', perspective)
        },
        setCameraPerspectiveToDefault({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', CAMERA_PERSPECTIVE_DEFAULT)
        },
        setCameraPerspectiveToBird({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_BIRD)
        },
        setCameraPerspectiveToRight({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_RIGHT)
        },
        setCameraPerspectiveToLeft({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_LEFT)
        },
        setCameraPerspectiveToTop({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_TOP)
        },
        setCameraPerspectiveToBottom({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_BOTTOM)
        },
        setCameraPerspectiveToFront({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_FRONT)
        },
        setCameraPerspectiveToBack({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_BACK)
        },

        clearItem({ commit }) {
            commit('RESET_INFILL')
            commit('RESET_CONTAINER')
            commit('RESET_ITEM')
        },
        async importItem({ getters, commit, dispatch, rootGetters }, { file, base64 }) 
        {
            // ensure file parameter is provided
            if (file === null || file === undefined) {
                throw new Error("Please provide a file to import.")
            }

            // ensure base64 parameter is provided
            if (base64 === null || base64 === undefined) {
                throw new Error("Please provide the file content to import.")
            }

            try
            {
                // inform users about item importing
                dispatch('app/setLoadingText', 'importing item', { root: true })

                // clear existing item
                dispatch('clearItem')

                // variable to store item asset
                let asset = null

                // extract extension from file name
                let extension = extractExtension(file.name)

                // supported formats
                const supported = ['glb', 'gltf', 'stp', 'step', 'stl', 'obj', 'ply', 'fbx', 'filmbox']

                // if format is not supported
                if (!supported.includes(extension)) {
                    throw new Error("The file format is not supported.")
                }

                // set correct mime types
                if (extension === 'glb' || extension === 'gltf') extension = 'gltf-binary'
                if (extension === 'step') extension = 'stp'
                if (extension === 'filmbox') extension = 'fbx'

                // change generic mime type according to extension
                base64 = base64.replace('application/octet-stream', 'model/' + extension)

                // specify model type (item)
                const additional = { 
                    type: MODEL_TYPE_ITEM
                }

                // if gltf, we can directly upload the item
                if (extension === 'gltf-binary')
                {
                    // call route to post the model asset
                    asset = await dispatch('api/assetsPost', {
                        fileData: base64,
                        additional
                    }, { root: true })

                    // no converting needed, so the base64 is the model file
                    asset.files['default'].data = base64
                    asset.files['default'].status = rootGetters['api/keyAssetStatusRetrieved']

                    // flag thumbnail as unrequested
                    asset.thumbnail.status = rootGetters['api/keyAssetStatusUnrequested']

                    // call route to check if the asset is ready
                    await dispatch('api/assetsAssetStatusGet', {
                        assetId: asset.id
                    }, { root: true })

                    // call route to generate thumbnail
                    await dispatch('api/modelsModelThumbnailGeneratePost', {
                        asset
                    }, { root: true })
                }
                // else, we need to convert it
                else
                {
                    // convert model file to glb
                    asset = await dispatch('api/modelsFromFileConvertPost', {
                        file: base64,
                        additional
                    }, { root: true })
                }

                // set the asset item
                await dispatch('box/setItemAsset', asset, { root: true })
            }
            catch(error)
            {
                throw error
            }
        },
        async setItemAsset({ getters, commit, dispatch, rootGetters }, asset)
        {
            try
            {
                // inform users about item preparing
                dispatch('app/setLoadingText', 'preparing item', { root: true })

                // ensure asset parameter exists
                if (asset === null) {
                    throw new Error("No asset item provided.")
                }

                // if asset belongs to another app
                if (asset.app !== rootGetters['app/keyAppBox']) {

                    // specify model type (item)
                    const additional = { 
                        type: MODEL_TYPE_ITEM
                    }

                    // clone asset
                    asset = await dispatch('api/assetsAssetClonePost', {
                        assetId: asset.id,
                        additional
                    }, { root: true })
                }

                // set item model asset
                commit('SET_ITEM_ASSET', asset)

                dispatch('app/logEvent', {
                    name: 'user opened item',
                    description: 'user opened the asset with the id ' + asset.id
                }, { root: true })

                // ensure default file is requested
                const fileDefault = await dispatch('api/assetsAssetFileKeyGet', {
                    asset
                }, { root: true })

                // load mesh
                loadMesh('gltf', fileDefault.data)
                .then((mesh) => {

                    // recalculate normals
                    //mesh.geometry = calculateNormals(mesh.geometry)

                    // center geometry
                    const offset = centerGeometry(mesh.geometry)

                    // normalize geometry
                    const { width, height, depth } = normalizeGeometry(mesh.geometry, getters.keyConversionSize)

                    // commit item to store
                    commit('SET_ITEM_MESH', mesh)

                    commit('SET_ITEM_CENTER', { x: offset.x, y: offset.y, z: offset.z })

                    commit('SET_ITEM_SIZE_ORIGINAL', { width, height, depth })
                    commit('SET_ITEM_SIZE_CURRENT', { width, height, depth })

                    commit('SET_CAMERA_PERSPECTIVE', DIRECTION_BIRD)
                })
            }
            catch(error)
            {
                throw error
            }
        },
        setItemVisible({ commit }, visible) {
            commit('SET_ITEM_VISIBLE', visible)
        },
        setItemSizeCurrent({ commit }, { width, height, depth }) {
            commit('RESET_INFILL')
            commit('SET_ITEM_SIZE_CURRENT', { width, height, depth })
            commit('SET_ITEM_OPTIMIZED', null)
        },
        setItemSizeCurrentConverted({ commit }, { width, height, depth }) {
            commit('RESET_INFILL')
            commit('SET_ITEM_SIZE_CURRENT_CONVERTED', { width, height, depth })
            commit('SET_ITEM_OPTIMIZED', null)
        },
        setItemDirection({ getters, commit }, direction) {
            commit('SET_ITEM_DIRECTION', direction)

            const mesh = getters.getItemMesh
            const euler = getters.getItemDirectionEuler

            mesh.rotation.set(
                euler[0] * (Math.PI / 180),
                euler[1] * (Math.PI / 180),
                euler[2] * (Math.PI / 180)
            )

            commit('SET_ITEM_OPTIMIZED', null)
            commit('RESET_INFILL')
        },
        async optimizeItem({ getters, commit, dispatch }) {     
            try
            {
                // determine the direction
                let direction = 'y'
                switch(getters.getItemDirection)
                {
                    case DIRECTION_RIGHT:
                        direction = 'x'
                        break
                    case DIRECTION_LEFT:
                        direction = 'x'
                        break
                    case DIRECTION_TOP:
                        direction = 'y'
                        break
                    case DIRECTION_BOTTOM:
                        direction = 'y'
                        break
                    case DIRECTION_FRONT:
                        direction = 'z'
                        break
                    case DIRECTION_BACK:
                        direction = 'z'
                        break
                }

                // call route to optimize item asset
                const response = await dispatch('api/modelsModelOptimizePost', {
                    asset: getters.getItemAsset,
                    centering_active: true,
                    voxelizing_active: true,
                    voxelizing_direction: direction,
                    voxelizing_resolution: getters.getInfillOptimizeResolution,
                    voxelizing_inflate: getters.getInfillOptimizeInflate,
                    reducing_active: false,
                    smoothing_active: getters.isInfillOptimizeSmooth,
                    repairing_active: false
                }, { root: true })

                // wait for optimization to be complete
                const fileOptimize = await dispatch('api/assetsAssetFileKeyGet', {
                    asset: response,
                    key: 'optimize',
                    timeMaximum: 300000 // wait five minutes at max
                }, { root: true })

                // load mesh
                loadMesh('gltf', fileOptimize.data)
                .then((mesh) => {

                    // adjust rotation of mesh
                    const euler = getters.getItemDirectionEuler
                    mesh.rotation.set(
                        euler[0] * (Math.PI / 180),
                        euler[1] * (Math.PI / 180),
                        euler[2] * (Math.PI / 180)
                    )

                    // normalize geometry
                    normalizeGeometry(mesh.geometry, getters.keyConversionSize)

                    // commit item to store
                    commit('SET_ITEM_OPTIMIZED', mesh)
                })

                return fileOptimize
            }
            catch(error)
            {
                console.error(error)
                throw error
            }
        },
        setItemDebugging({ commit }, debugging) {
            commit('SET_ITEM_DEBUGGING', debugging)
        },

        clearContainer({ commit }) {
            commit('RESET_INFILL')
            commit('RESET_CONTAINER')
        },
        setContainerVisible({ commit }, visible) {
            commit('SET_CONTAINER_VISIBLE', visible)
        },
        setContainerColumns({ commit }, columns) {
            commit('SET_CONTAINER_COLUMNS', columns)
        },
        setContainerStacks({ commit }, stacks) {
            commit('SET_CONTAINER_STACKS', stacks)
        },
        setContainerRows({ commit }, rows) {
            commit('SET_CONTAINER_ROWS', rows)
        },
        setContainerSelection({ commit }, selection) {
            commit('RESET_INFILL')
            commit('SET_CONTAINER_SELECTION', selection)
        },
        setContainerOptions({ commit, dispatch }, options) {
            commit('SET_CONTAINER_OPTIONS', options)
            dispatch('updateAppAdditional')
        },
        setContainerSpacingMode({ commit }, mode) {
            commit('SET_CONTAINER_SPACING_MODE', mode)
        },
        setContainerSpacingAlign({ commit }, align) {
            commit('SET_CONTAINER_SPACING_ALIGN', align)
        },
        setContainerSpacingAutomatic({ commit }, { inner, outer }) {
            commit('SET_CONTAINER_SPACING_AUTOMATIC', { inner, outer })
        },
        setContainerSpacingManual({ commit }, { inner, outer }) {
            commit('SET_CONTAINER_SPACING_MANUAL', { inner, outer })
        },
        setContainerSidesHighlight({ commit }, highlight) {
            commit('SET_CONTAINER_SIDES_HIGHLIGHT', highlight)
        },
        setContainerSidesSelection({ commit, getters }, selection) {
            commit('SET_CONTAINER_SIDES_SELECTION', selection)
        },

        clearInfill({ commit }) {
            commit('RESET_INFILL')
        },
        createInfill({ commit }, { side, position }) {
            const infill = new Infill(side, position) 
            commit('ADD_INFILL', infill)
            commit('SET_INFILL_CALCULATED', false)
            return infill
        },
        deleteInfill({ commit }, infillId) {
            if (infillId) 
            {
                commit('REMOVE_INFILL', infillId)
                commit('SET_INFILL_CALCULATED', false)
            }
        },
        deleteSelectedInfill({ commit, getters }) {
            const selection = getters.getInfillSelection
            if (selection) 
            {
                commit('REMOVE_INFILL', selection)
                commit('SET_INFILL_CALCULATED', false)
            }
        },
        setInfillVisible({ commit }, visible) {
            commit('SET_INFILL_VISIBLE', visible)
        },
        setInfillSelection({ commit }, selection) {
            commit('SET_INFILL_CALCULATED', false)
            commit('SET_INFILL_SELECTION', selection)
        },
        setInfillTolerance({ commit }, tolerance) {
            commit('SET_INFILL_TOLERANCE', tolerance)
            commit('SET_INFILL_CALCULATED', false)
        },
        setInfillOptimized({ commit }, optimized) {
            commit('SET_INFILL_OPTIMIZED', optimized)
            commit('SET_INFILL_CALCULATED', false)
            commit('SET_ITEM_OPTIMIZED', null)
        },
        setInfillOptimizeResolution({ commit }, resolution) {
            commit('SET_INFILL_OPTIMIZE_RESOLUTION', resolution)
            commit('SET_INFILL_CALCULATED', false)
            commit('SET_ITEM_OPTIMIZED', null)
        },
        setInfillOptimizeSmooth({ commit }, smooth) {
            commit('SET_INFILL_OPTIMIZE_SMOOTH', smooth)
            commit('SET_INFILL_CALCULATED', false)
            commit('SET_ITEM_OPTIMIZED', null)
        },
        setInfillOptimizeInflate({ commit }, inflate) {
            commit('SET_INFILL_OPTIMIZE_INFLATE', inflate)
            commit('SET_INFILL_CALCULATED', false)
            commit('SET_ITEM_OPTIMIZED', null)
        },
        setInfillAsset({ commit }, asset) {
            commit('SET_INFILL_ASSET', asset)
        },
        setInfillCalculated({ commit }, calculated) {
            commit('SET_INFILL_CALCULATED', calculated)
        },  
        async calculateInfill({ getters, commit, dispatch, rootGetters }) 
        {   
            // mark infill as not yet calculated
            commit('SET_INFILL_CALCULATED', false)

            try
            {
                // get script parameter
                const scale = getters.keyConversionSize
                const itemCenter = getters.isInfillOptimized ? [0, 0, 0] : getters.getItemCenter
                const itemSizeOriginal = getters.getItemSizeOriginal
                const itemSizeCurrent = getters.getItemSizeCurrent
                const itemTolerance = [
                    1 + (getters.getInfillTolerance / itemSizeCurrent.width),
                    1 + (getters.getInfillTolerance / itemSizeCurrent.height),
                    1 + (getters.getInfillTolerance / itemSizeCurrent.depth)
                ]
                const itemScale = [
                    (itemSizeCurrent.width / itemSizeOriginal.width) * itemTolerance[0],
                    (itemSizeCurrent.height / itemSizeOriginal.height) * itemTolerance[0],
                    (itemSizeCurrent.depth / itemSizeOriginal.depth) * itemTolerance[0]
                ]
                const itemDirection = getters.getItemDirectionEuler
                const itemAsset = getters.getItemAsset
                const itemAssetFileKey = getters.isInfillOptimized ? 'optimize' : 'default'
                const slots = getters.getContainerSlots
                const infillList = getters.getInfillList

                // script infills
                let scriptInfills = `module infills() {`
                for (const infill of infillList) 
                {
                    const attr = getters.getInfillAttributes(infill)
                    scriptInfills += `infill(${attr.width}, ${attr.height}, ${attr.depth},
                        [${attr.position.x}, ${attr.position.y}, ${attr.position.z}], 
                        [${attr.limit[0]}, ${attr.limit[1]}, ${attr.limit[2]}], 
                        ${attr.edge});`
                }
                scriptInfills += `}`

                // script item
                const scriptItem =
                `
                module item(posX, posY, posZ) 
                {
                    translate([posX / ${scale}, posY / ${scale}, posZ / ${scale}])
                    {
                        scale([${itemScale[0]}, ${itemScale[1]}, ${itemScale[2]}])
                        {
                            rotate([${itemDirection[0]}, ${itemDirection[1]}, ${itemDirection[2]}])
                            {
                                translate([${itemCenter[0]}, ${itemCenter[1]}, ${itemCenter[2]}])
                                {
                                    {{id:${itemAsset.id},key:'${itemAssetFileKey}'}}
                                }
                            }
                        }
                    }
                }
                `

                // script items
                let scriptItems = `module items() {`
                for (const slot of slots) 
                {
                    scriptItems += `item(${slot.x}, ${slot.y}, ${slot.z});`
                }
                scriptItems += `}`

                // general script 
                const script = 
                `
                    ${scriptInfills}
                    ${scriptItem}
                    ${scriptItems}

                    rotate([90, 0, 0])
                    {
                        difference()
                        {
                            infills();
                            items();
                        }
                    }
                `

                // specify model type (infill)
                const additional = { 
                    type: MODEL_TYPE_INFILL
                }

                // call route to generate model from script
                const asset = await dispatch('api/modelsFromScriptGeneratePost', {
                    script,
                    libraries: [ 'infill' ],
                    additional
                }, { root: true })

                // set infill asset
                await dispatch('setInfillAsset', { asset })

                // mark infill as calculated
                commit('SET_INFILL_CALCULATED', true)

                return asset
            }
            catch(error)
            {
                throw error
            }
        },
        async setInfillAsset({ commit, getters, dispatch }, {
            asset
        })
        {
            try
            {
                // ensure asset parameter exists
                if (asset === null) {
                    throw new Error("No asset item provided.")
                }

                // set new infill asset
                commit('SET_INFILL_ASSET', asset)

                // ensure default file is requested
                const fileDefault = await dispatch('api/assetsAssetFileKeyGet', {
                    asset,
                    timeMaximum: 300000 // wait five minutes at max
                }, { root: true })

                // load mesh
                loadMesh('gltf', fileDefault.data)
                .then((mesh) => {

                    // repair texture mapping (UVs)
                    mesh = repairTextureMapping(mesh, getters.keyConversionSize)

                    // fix normals
                    mesh.geometry.computeVertexNormals()

                    // commit infill to store
                    commit('SET_INFILL_MESH', mesh)
                })
            }
            catch(error) 
            {
                throw error
            }
        },
        async exportInfill({ commit, getters }) 
        {
            if (!getters.isInfillCalculated)
            {
                throw new Error('Infill needs to be calculated first.')
            }

            try
            {
                // get infill mesh
                const infillMesh = getters.getInfillMesh

                // get export scaling (100 means no scaling; value in percent)
                const exportScaling = getters.getExportScaling

                // get geometry and apply scaling
                const geometry = infillMesh.geometry.clone()
                const scaleFactor = exportScaling / 100
                geometry.scale(scaleFactor, scaleFactor, scaleFactor)

                // split geometry into unconnected geometries / make to individual meshes
                const geometries = divideGeometry(geometry)

                // download all meshes
                const exporter = new STLExporter()

                geometries.forEach((geom, index) => {
                    const mesh = new THREE.Mesh(geom, infillMesh.material)
                    const data = exporter.parse(mesh)

                    downloadFile(data, 'text/plain', 'infill_' + index, 'stl')
                })

                if (geometries.length >= 10)
                {
                    throw new Error('Export of infill failed.')
                }

                // TODO negative infill
            }
            catch(error)
            {
                throw error
            }
        },

        setRenderMode({ commit }, mode) {
            commit('SET_RENDER_MODE', mode)
        },
        setRenderModeToDefault({ commit }) {
            commit('SET_RENDER_MODE', RENDER_MODE_DEFAULT)
        },
        setRenderModeToTextured({ commit }) {
            commit('SET_RENDER_MODE', RENDER_MODE_TEXTURED)
        },
        setRenderModeToSolid({ commit }) {
            commit('SET_RENDER_MODE', RENDER_MODE_SOLID)
        },
        setRenderModeToWireframe({ commit }) {
            commit('SET_RENDER_MODE', RENDER_MODE_WIREFRAME)
        },
        setRenderFormat({ commit }, format) {
            commit('SET_RENDER_FORMAT', format)
        },

        setExportScaling({ commit }, scaling) {
            commit('SET_EXPORT_SCALING', scaling)
        },
        setExportType({ commit }, type) {
            commit('SET_EXPORT_TYPE', type)
        },
        setExportFormat({ commit }, format) {
            commit('SET_EXPORT_FORMAT', format)
        },
    }
}