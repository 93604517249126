// vuex.js store for Sketchurizer v2 (aka Flow)


//// CONSTANTS

// cards
const CARD_DEFAULT = 'CardSelectFlow'

// flows
const FLOW_SKETCH = 'sketch'
const FLOW_IMPORT = 'import'
const FLOW_DESCRIBE = 'describe'

// categories
const CATEGORY_SKETCH = 'sketch'
const CATEGORY_IMAGE = 'image'
const CATEGORY_PREVIEW = 'preview'
const CATEGORY_MODEL = 'model'

// prompt
const PROMPT_POSITIVE_DEFAULT = ''
const PROMPT_NEGATIVE_DEFAULT = ''
const PROMPT_LENGTH_MAX = 1024
const BALANCE_DEFAULT = 0.5

// viewer
const VIEWER_ANIMATION_DEFAULT = false
const VIEWER_MODE_TEXTURE = 'texture'
const VIEWER_MODE_SOLID = 'solid'
const VIEWER_MODE_WIREFRAME = 'wireframe'
const VIEWER_MODE_DEFAULT = VIEWER_MODE_TEXTURE


//// CLASSES

// class to wrap card 
class Card {
    static _counter = 0

    constructor(index, component, task) {
        this.id = Card._counter++
        this.index = index
        this.component = component
        this.task = task
        this.state = {}

        // resolve task to access category
        this._category = 'asset'
        if (this.task) {
            this.task.then((task) => this._category = task.category)
        }
    }

    description() {
        switch (this.component) {

            case 'CardGallery':
                return 'view imported images'
            case 'CardImageBase':
                return `edit ${this._category}`
            case 'CardImageCrop':
                return `crop ${this._category}`
            case 'CardImageRemove':
                return 'remove parts of image'
            case 'CardImport':
                return 'import image'
            case 'CardModelBase':
                return 'view generated 3D model'
            case 'CardPrompt':
                return 'describe 3D model'
            case 'CardSelectAsset':
                return `select ${this._category}`
            case 'CardSelectFlow':
                return 'select your flow'
            case 'CardSelectGenerate':
                return 'select generation preference'
            default:
                return 'no description available'
        }
    }
}

// class to wrap task
class Task {
    constructor(executor, fileKey, category) {

        // ensure executor is a function
        if (typeof executor !== "function") {
            throw new Error("Task requires an executor function.")
        }
                
        // set public variables
        this.fileKey = fileKey
        this.category = category ? category : 'asset'

        // set private variables
        this._executor = executor
        this._promise = null
        this._progressCallback = null
        this._finishCallback = null
        this._errorCallback = null
    }

    run() {
        if (this._promise) return this

        this._promise = new Promise((resolve, reject) => {
            try {
                this._executor(
                    (assets) => {
                        resolve({ 
                            assets: Array.isArray(assets) ? assets : [],
                            fileKey: this.fileKey,
                            category: this.category
                        })
                    },
                    (error) => {
                        if (this._errorCallback) this._errorCallback(error)
                        reject(error)
                    },
                    (message) => {
                        if (this._progressCallback) this._progressCallback(message)
                    }
                )
            } catch (error) {
                if (this._errorCallback) this._errorCallback(error)
                reject(error)
            }
        })

        this._promise.then((result) => {
            if (this._finishCallback) this._finishCallback(result)
        })

        return this
    }

    onProgress(callback) {
        this._progressCallback = callback
        return this
    }

    onFinish(callback) {
        this._finishCallback = callback
        return this
    }

    onError(callback) {
        this._errorCallback = callback
        return this
    }

    clone() {
        const newTask = new Task(this._executor, this.fileKey, this.category)

        return new Promise((resolve) => {
            resolve(newTask)
        })
    }
}



//// HELPER

// create new state
function newState() {
    return {
        flow: {
            id: null,
            name: null,
        },
        cards: [
            new Card(0, CARD_DEFAULT)
        ],
        activeCardIndex: 0,
        fullscreen: false,
    }
}


//// VUEX STORE

export const flow = {
    namespaced: true,
    state: newState(),

    mutations: {
        RESET(state) {
            Object.assign(state, newState())
        },
        RESET_CARDS(state) {
            state.cards = [ state.cards[0] ]
            state.activeCardIndex = 0
        },
        NEW_FLOW(state, { id, name }) {
            state.flow.id = id
            state.flow.name = name
        },
        CARD_ADD(state, card) {
            state.cards.push(card)
            state.activeCardIndex = state.cards.length - 1
        },
        CARD_REMOVE(state, index) {

            // if index is not a valid value, nothing to remove
            if (index == null || index == undefined) return

            // if index is out of bounds, nothing to remove
            if (index < 0 || index >= state.cards.length) return

            // if index is first card, reset state
            if (index === 0) {
                Object.assign(state, newState())
                return
            }

            // remove all items after the given index
            state.cards.splice(index)

            // adjust activeCardIndex if necessary
            if (state.activeCardIndex > index) {
                state.activeCardIndex = index
            }
        },
        SET_ACTIVE_CARD(state, index) {
            if (index < 0 || index >= state.cards.length) return
            state.activeCardIndex = index
        },
        SET_FULLSCREEN(state, fullscreen) {
            state.fullscreen = fullscreen
        }
    },

    getters: {
        keyFlowSketch: () => FLOW_SKETCH,
        keyFlowImport: () => FLOW_IMPORT,
        keyFlowDescribe: () => FLOW_DESCRIBE,

        keyCategorySketch: () => CATEGORY_SKETCH,
        keyCategoryImage: () => CATEGORY_IMAGE,
        keyCategoryPreview: () => CATEGORY_PREVIEW,
        keyCategoryModel: () => CATEGORY_MODEL,

        keyPromptPositiveDefault: () => PROMPT_POSITIVE_DEFAULT,
        keyPromptNegativeDefault: () => PROMPT_NEGATIVE_DEFAULT,
        keyPromptLengthMax: () => PROMPT_LENGTH_MAX,
        keyBalanceDefault: () => BALANCE_DEFAULT,

        keyViewerAnimationDefault: () => VIEWER_ANIMATION_DEFAULT,
        keyViewerModeTexture: () => VIEWER_MODE_TEXTURE,
        keyViewerModeSolid: () => VIEWER_MODE_SOLID,
        keyViewerModeWireframe: () => VIEWER_MODE_WIREFRAME,
        keyViewerModeDefault: () => VIEWER_MODE_DEFAULT,

        isActiveCardByIndex: (state) => (index) => {
            return index === state.activeCardIndex
        },
        isLastCardByIndex: (state) => (index) => {
            return index === state.cards.length - 1
        },
        isFullscreen: (state) => state.fullscreen,

        getFlowId: (state) => {
            return state.flow.id
        },
        getFlowName: (state) => {
            return state.flow.name
        },
        getActiveCard: (state) => {
            if (state.activeCardIndex == null) return null
            return state.cards[state.activeCardIndex] || null
        },
        getActiveCardIndex: (state) => {
            return state.activeCard
        },
        getCardByIndex: (state) => (index) => {
            return state.cards.find((card) => card.index === index) || null
        },
        getFirstAssetFromAssets: () => (assets) => {
            return Array.isArray(assets) && assets.length > 0 ? assets[0] : null
        },
        getAssetPrompt: () => (asset) => {
            const additional = {
                positive: PROMPT_POSITIVE_DEFAULT,
                negative: PROMPT_NEGATIVE_DEFAULT,
                balance: BALANCE_DEFAULT
            }
            if (asset === null || asset === undefined) return additional
            if (asset.additional?.positive) additional.positive = asset.additional.positive
            if (asset.additional?.negative) additional.negative = asset.additional.negative
            if (asset.additional?.balance) additional.balance = asset.additional.balance
            return additional
        },
    },

    actions: {
        resetFlow({ commit }) {
            commit('RESET')
        },
        newFlow({ commit, rootGetters }, name) {
            const id = rootGetters['app/getUniqueIdentifier']
            commit('RESET_CARDS')
            commit('NEW_FLOW', { id, name })
        },

        addCard({ commit, state }, { index, component, task = null }) {
            if (index == null || index == undefined) return
            commit('CARD_REMOVE', index) // TODO user confirmation via dialog
            const newCard = new Card(state.cards.length, component, task)
            commit('CARD_ADD', newCard)
        },
        removeCard({ commit }, index) {
            commit('CARD_REMOVE', index)
        },
        setActiveCard({ commit }, index) {
            commit('SET_ACTIVE_CARD', index)
        },
        setActiveCardToLast({ commit, state }) {
            commit('SET_ACTIVE_CARD', state.activeCardIndex - 1)
        },
        setActiveCardToNext({ commit, state }) {
            commit('SET_ACTIVE_CARD', state.activeCardIndex + 1)
        },
        setFullscreen({ commit }, fullscreen) {
            commit('SET_FULLSCREEN', fullscreen)
        },
        toggleFullscreen({ commit, state }) {
            commit('SET_FULLSCREEN', !state.fullscreen)
        },

        newBaseTask({ dispatch, rootGetters }, { 
            assets = [], 
            fileKey = rootGetters['api/keyFileKeyDefault'], 
            category = null,
            preload = true
        } = {}) {
            return new Task(async (resolve, reject, progress) => {
                try {

                    // if assets should be preloaded
                    if (preload) {

                        // inform user about status
                        progress(`preparing ${category}`)

                        // preload all assets
                        await Promise.all(assets.map(asset =>
                            dispatch('api/assetsAssetFileKeyGet', {
                                asset,
                                key: fileKey
                            }, { root: true })
                        ))
                    }

                    // resolve with assets
                    resolve(assets)

                } catch(error) {

                    // reject with error
                    reject(error)

                } finally {

                    // log event
                    dispatch("app/logEvent", {
                        name: "New Base Task",
                        description: "User has created a new task to request an existing asset"
                    }, { root: true })

                }
            }, fileKey, category)
        },
        newAssetTask({ dispatch, getters, rootGetters }, {
            fileData = null,
            fileKey = rootGetters['api/keyFileKeyDefault'],
            category = null,
            process = null,
            additional = null,
            preload = true
        } = {}) {
            return new Task(async (resolve, reject, progress) => {
                try {

                    // inform user about status
                    if (Array.isArray(fileData)) progress(`preparing ${category}s`)
                    else progress(`preparing ${category}`)
            
                    // additional for new asset
                    if (additional === null) additional = {}
                    additional.flow = {
                        id: getters.getFlowId,
                        name: getters.getFlowName,
                        index: getters.getActiveCardIndex
                    }
                    additional.category = category
                    additional.rating = 0
            
                    // ensure fileData is an array
                    const fileDataArray = Array.isArray(fileData) ? fileData : [fileData]
            
                    // upload each fileData, preload if necessary
                    const assets = []
                    for (const [index, singleFileData] of fileDataArray.entries()) {

                        // upload asset
                        const asset = await dispatch('api/assetsPost', {
                            fileData: singleFileData,
                            fileKey,
                            process,
                            additional
                        }, { root: true })
            
                        // if asset should be preloaded
                        if (preload) {
                            await dispatch('api/assetsAssetFileKeyGet', {
                                asset,
                                key: fileKey
                            }, { root: true })
                        }
            
                        // add asset to list
                        assets.push(asset)
                    }
            
                    // resolved successfully
                    resolve(assets)

                } catch (error) {

                    // reject with error
                    reject(error)

                } finally {

                    // log event
                    dispatch('app/logEvent', {
                        name: 'New Asset Task',
                        description: 'User has created a new task to upload asset(s)'
                    }, { root: true })
                }
            }, fileKey, category)
        },
        newImageToSketchTask({ dispatch, getters, rootGetters }, {
            sourceAsset = null,
            preload = true
        } = {}) {
            return new Task(async (resolve, reject, progress) => {
                try {

                    // check if required source asset exists
                    if (sourceAsset == null) {
                        throw new Error("Create sketch from image failed.")
                    }

                    // inform user about status
                    progress("creating sketch")

                    // additional for new asset
                    const additional = rootGetters['flow/getAssetPrompt'](sourceAsset)
                    additional['flow'] = {
                        id: getters.getFlowId,
                        name: getters.getFlowName,
                        index: getters.getActiveCardIndex,
                    }
                    additional['category'] = getters.keyCategorySketch
                    additional['rating'] = 0

                    // generate sketch from image
                    const sketchAsset = await dispatch('api/imagesImageSketchGeneratePost', {
                        asset: sourceAsset,
                        additional
                    }, { root: true })
                    
                    // if asset should be preloaded
                    if (preload) {
                        await dispatch('api/assetsAssetFileKeyGet', {
                            asset: sketchAsset
                        }, { root: true })
                    }

                    // resolved successfully
                    resolve([ sketchAsset ])
                
                } catch (error) {

                    // reject with error
                    reject(error)

                } finally {
                    
                    // log event
                    dispatch("app/logEvent", {
                        name: "New Image to Sketch Task",
                        description: "User has created a new task to create a sketch from an image"
                    }, { root: true })
                
                }
            }, rootGetters['api/keyFileKeyDefault'], getters.keyCategorySketch)
        },
        newImageRemoveBackgroundTask({ dispatch, getters, rootGetters }, {
            sourceAssets = null,
            preload = true
        } = {}) {
            return new Task(async (resolve, reject, progress) => {
                try {

                    // check if required source assets exists
                    if (!Array.isArray(sourceAssets) || sourceAssets.length === 0) {
                        throw new Error("Removal of image background failed.")
                    }

                    // inform user about status
                    progress(`removing background`)

                    // wait for all source assets to be ready
                    await Promise.all(
                        sourceAssets.map(async (asset) => {

                            // wait for this asset to be ready
                            const status = await dispatch( "api/assetsAssetFileKeyStatusGet", { 
                                asset 
                            }, { root: true })

                            // ensure status is ready
                            if (status.status !== rootGetters["api/keyFileStatusReady"]) {
                                throw new Error("Removal of image background failed.")
                            }

                        })
                    )

                    // remove background from all source assets
                    const imageAssets = await Promise.all(
                        sourceAssets.map(async (asset) => {
                            const additional = {
                                ...rootGetters['flow/getAssetPrompt'](asset),
                                flow: {
                                    id: getters.getFlowId,
                                    name: getters.getFlowName,
                                    index: getters.getActiveCardIndex,
                                },
                                category: getters.keyCategoryImage,
                                rating: 0
                            }

                            // remove background
                            const imageAsset = await dispatch('api/imagesImageModifyAlphaAddPost', {
                                asset,
                                additional
                            }, { root: true })

                            // preload if needed
                            if (preload) {
                                await dispatch('api/assetsAssetFileKeyGet', {
                                    asset: imageAsset
                                }, { root: true })
                            }

                            return imageAsset
                        })
                    )

                    // resolved successfully with all new assets
                    resolve(imageAssets)
                }
                catch (error) {

                    // reject with error
                    reject(error)
                
                } finally {

                    // log event
                    dispatch("app/logEvent", {
                        name: "New Image Remove Background Task",
                        description: "User has created a new task to remove the background of an image"
                    }, { root: true })

                }
            }, rootGetters['api/keyFileKeyDefault'], getters.keyCategoryImage)
        },       
        newImageRemoveTextTask({ dispatch, getters, rootGetters }, {
            sourceAsset = null,
            preload = true
        } = {}) {
            return new Task(async (resolve, reject, progress) => {
                try {

                    // check if required source asset exists
                    if (sourceAsset == null) {
                        throw new Error("Removal of image text failed.")
                    }

                    // inform user about status
                    progress(`removing text`)

                    // wait for file status of source asset to be ready
                    const status = await dispatch('api/assetsAssetFileKeyStatusGet', {
                        asset: sourceAsset
                    }, { root: true })

                    // ensure status is ready
                    if (status.status !== rootGetters['api/keyFileStatusReady']) {
                        throw new Error("Removal of image text failed.")
                    }

                    // additional for new asset
                    const additional = rootGetters['flow/getAssetPrompt'](sourceAsset)
                    additional['flow'] = {
                        id: getters.getFlowId,
                        name: getters.getFlowName,
                        index: getters.getActiveCardIndex,
                    }
                    additional['category'] = getters.keyCategoryImage
                    additional['rating'] = 0

                    // remove text from source asset
                    const imageAsset = await dispatch('api/imagesImageModifyAnnotionRemovePost', {
                        asset: sourceAsset,
                        additional
                    }, { root: true })

                    // if asset should be preloaded
                    if (preload) {
                        await dispatch('api/assetsAssetFileKeyGet', {
                            asset: imageAsset
                        }, { root: true })
                    }

                    // resolved successfully
                    resolve([ imageAsset ])
                }
                catch (error) {

                    // reject with error
                    reject(error)
                
                } finally {

                    // log event
                    dispatch("app/logEvent", {
                        name: "New Image Remove Text Task",
                        description: "User has created a new task to remove the text of an image"
                    }, { root: true })

                }
            }, rootGetters['api/keyFileKeyDefault'], getters.keyCategoryImage)
        },    
        newSketchesGenerateTask({ dispatch, getters, rootGetters }, {
            sourceAsset = null,
            sourceFileKey = rootGetters['api/keyFileKeyDefault'],
            sourceCategory = null,
            promptPositive = '',
            promptNegative = '',
            balance = 0.5,
            count = 1,
            resolution = 1024,
            preload = true
        } = {}) {
            return new Task(async (resolve, reject, progress) => {
                try {

                    // inform user about status
                    progress("generating sketches")

                    // additional for new sketches
                    const additional = {
                        flow: {
                            id: getters.getFlowId,
                            name: getters.getFlowName,
                            index: getters.getActiveCardIndex,
                        },
                        category: getters.keyCategorySketch,
                        rating: 0,
                        positive: promptPositive,
                        negative: promptNegative,
                        balance
                    }

                    // prepare seeds for generation
                    const seeds = new Array(count).fill(-1)

                    // variable for generated sketches
                    let sketchAssets = null

                    // generate sketches from prompt
                    if (sourceAsset === null) {

                        // generate sketches from prompt
                        sketchAssets = await dispatch("api/imagesFromPromptGeneratePost", {
                            positive: promptPositive,
                            negative: promptNegative,
                            seeds,
                            alpha: true,
                            resolution,
                            style: rootGetters['api/keyImageSubSketch'],
                            additional
                        }, { root: true })

                    }

                    // generate sketches from source asset
                    else {

                        // wait for file status of source asset to be ready
                        const status = await dispatch('api/assetsAssetFileKeyStatusGet', {
                            asset: sourceAsset
                        }, { root: true })

                        // ensure status is ready
                        if (status.status !== rootGetters['api/keyFileStatusReady']) {
                            throw new Error("Generation of sketches failed.")
                        }

                        // apply canny to everything but sketch
                        let process = null
                        if (sourceCategory !== getters.keyCategorySketch) {
                            process = rootGetters['api/keyImageSubCanny']
                        }

                        // generate sketches from source asset
                        sketchAssets = await dispatch("api/imagesFromGuidanceGeneratePost", {
                            parentId: sourceAsset.id,
                            fileKey: sourceFileKey,
                            positive: promptPositive,
                            negative: promptNegative,
                            process,
                            strength: balance,
                            seeds,
                            alpha: true,
                            resolution,
                            style: rootGetters['api/keyImageSubSketch'],
                            additional
                        }, { root: true })
                    }

                    // if assets should be preloaded
                    if (preload) {

                        // preload all assets
                        await Promise.all(sketchAssets.map(asset =>
                            dispatch('api/assetsAssetFileKeyGet', {
                                asset
                            }, { root: true })
                        ))
                    }

                    // resolved successfully
                    resolve(sketchAssets)

                } catch (error) {

                    // reject with error
                    reject(error)

                } finally {

                    // log event
                    dispatch("app/logEvent", {
                        name: "New Sketches Generate Task",
                        description: "User has created a new task to generate sketches"
                    }, { root: true })

                }
            }, rootGetters['api/keyFileKeyDefault'], getters.keyCategorySketch)
        },
        newPreviewsGenerateTask({ dispatch, getters, rootGetters }, {
            sourceAsset = null,
			sourceFileKey = rootGetters['api/keyFileKeyDefault'],
            sourceCategory = null,
            promptPositive = '',
            promptNegative = '',
            balance = 0.5,
            count = 1,
            resolution = 1024,
            preload = true
        } = {}) {
            return new Task(async (resolve, reject, progress) => {
                try {

                    // inform user about status
                    if (count > 1) progress("generating previews")
                    else progress("generating preview")

                    // additional for new previews
                    const additional = {
                        flow: {
                            id: getters.getFlowId,
                            name: getters.getFlowName,
                            index: getters.getActiveCardIndex,
                        },
                        category: getters.keyCategoryPreview,
                        rating: 0,
                        positive: promptPositive,
                        negative: promptNegative,
                        balance
                    }

                    // prepare seeds for generation
                    const seeds = new Array(count).fill(-1)

                    // variable for generated previews
                    let previewAssets = null

                    // generate previews from prompt
                    if (sourceAsset === null) {

                        previewAssets = await dispatch("api/imagesFromPromptGeneratePost", {
                            positive: promptPositive,
                            negative: promptNegative,
                            seeds,
                            alpha: true,
                            resolution,
                            additional
                        }, { root: true })

                    }

                    // generate previews from parent asset
                    else {
                        // wait for file status of source asset to be ready
                        const status = await dispatch('api/assetsAssetFileKeyStatusGet', {
                            asset: sourceAsset
                        }, { root: true })

                        // ensure status is ready
                        if (status.status !== rootGetters['api/keyFileStatusReady']) {
                            throw new Error("Generation of previews failed.")
                        }

                        // apply canny to everything but sketch
                        let process = null
                        if (sourceCategory !== getters.keyCategorySketch) {
                            process = rootGetters['api/keyImageSubCanny']
                        }

                        // generate previews from source asset
                        previewAssets = await dispatch("api/imagesFromGuidanceGeneratePost", {
                            parentId: sourceAsset.id,
                            fileKey: sourceFileKey,
                            positive: promptPositive,
                            negative: promptNegative,
                            process,
                            strength: balance,
                            seeds,
                            alpha: true,
                            resolution,
                            additional
                        }, { root: true })
                    }

                    // if assets should be preloaded
                    if (preload) {

                        // preload all assets
                        await Promise.all(previewAssets.map(asset =>
                            dispatch('api/assetsAssetFileKeyGet', {
                                asset
                            }, { root: true })
                        ))
                    }

                    // resolved successfully
                    resolve(previewAssets)

                } catch (error) {

                    // reject with error
                    reject(error)

                } finally {

                    // log event
                    dispatch("app/logEvent", {
                        name: "New Previews Generate Task",
                        description: "User has created a new task to generate previews"
                    }, { root: true })

                }
            }, rootGetters['api/keyFileKeyDefault'], getters.keyCategoryPreview)
        },
        newModelGenerateTask({ dispatch, getters, rootGetters }, {
            sourceAssets = null,
            quality = 'high',
            preload = true
        } = {}) {
            return new Task(async (resolve, reject, progress) => {
                try {

                    // check if required source assets exist
                    if (!Array.isArray(sourceAssets) || sourceAssets.length === 0) {
                        throw new Error("Model generation failed.")
                    }

                    // inform user about status
                    progress("generating model")

                    // wait for all source assets to be ready
                    await Promise.all(
                        sourceAssets.map(async (asset) => {

                            // wait for this asset to be ready
                            const status = await dispatch( "api/assetsAssetFileKeyStatusGet", { 
                                asset 
                            }, { root: true })

                            // ensure status is ready
                            if (status.status !== rootGetters["api/keyFileStatusReady"]) {
                                throw new Error("Model generation failed")
                            }
                        })
                    )

                    // additional for new model
                    const additional = rootGetters['flow/getAssetPrompt'](sourceAssets[0])
                    additional['flow'] = {
                        id: getters.getFlowId,
                        name: getters.getFlowName,
                        index: getters.getActiveCardIndex,
                    }
                    additional['category'] = getters.keyCategoryModel
                    additional['rating'] = 0

                    // generate model from source asset
                    const modelAssets = await dispatch('api/modelsFromImageGeneratePost', {
                        sourceAssets,
                        additional,
                        quality
                    }, { root: true })

                    // if asset should be preloaded
                    if (preload) {

                        // preload all assets
                        await Promise.all(modelAssets.map(asset =>
                            dispatch('api/assetsAssetFileKeyGet', {
                                asset,
                                timeMaximum: 90000
                            }, { root: true })
                        ))
                    }

                    // resolved successfully
                    resolve(modelAssets)

                } catch (error) {

                    // reject with error
                    reject(error)

                } finally {

                    // log event
                    dispatch("app/logEvent", {
                        name: "New Model Generate Speed Task",
                        description: "User has created a new task to generate a model with fast generation"
                    }, { root: true })

                }
            }, rootGetters['api/keyFileKeyDefault'], getters.keyCategoryModel)
        },
        newModelGenerateProTask({ dispatch, getters, rootGetters }, {
            sourceAssets = null,
            quality = 'high',
            preload = true
        } = {}) {
            return new Task(async (resolve, reject, progress) => {
                try {

                    // check if required source asset exist
                    if (!Array.isArray(sourceAssets) || sourceAssets.length === 0) {
                        throw new Error("Model generation failed.")
                    }

                    // inform user about status
                    progress("analyzing input")

                    // wait for all source assets to be ready
                    await Promise.all(
                        sourceAssets.map(async (asset) => {

                            // wait for this asset to be ready
                            const status = await dispatch( "api/assetsAssetFileKeyStatusGet", { 
                                asset 
                            }, { root: true })

                            // ensure status is ready
                            if (status.status !== rootGetters["api/keyFileStatusReady"]) {
                                throw new Error("Model generation failed")
                            }
                        })
                    )

                    // generate or retrieve the "albedo" file for each asset
                    await Promise.all(
                        sourceAssets.map(async (asset) => {

                            // if this asset lacks "albedo," generate it
                            if (!asset.files.hasOwnProperty("albedo")) {
                                await dispatch("api/imagesImageAlbedoGeneratePost", {
                                    asset,
                                    key: "albedo"
                                }, { root: true })
                            }

                            // wait for "albedo" file to be generated
                            await dispatch("api/assetsAssetFileKeyGet", {
                                asset,
                                key: "albedo",
                                timeMaximum: 300000 // Wait up to 5 minutes
                            }, { root: true })
                        })
                    )

                    // inform user about status
                    progress("generating model")

                    // additional for new model
                    const additional = rootGetters['flow/getAssetPrompt'](sourceAssets[0])
                    additional['flow'] = {
                        id: getters.getFlowId,
                        name: getters.getFlowName,
                        index: getters.getActiveCardIndex,
                    }
                    additional['category'] = getters.keyCategoryModel
                    additional['rating'] = 0

                    // generate model from source asset
                    const modelAssets = await dispatch('api/modelsFromImageGeneratePost', {
                        sourceAssets,
                        sourceFileKey: 'albedo',
                        additional,
                        quality
                    }, { root: true })

                    // if asset should be preloaded
                    if (preload) {

                        // preload all assets
                        await Promise.all(modelAssets.map(asset =>
                            dispatch('api/assetsAssetFileKeyGet', {
                                asset,
                                timeMaximum: 90000
                            }, { root: true })
                        ))
                    }

                    // resolved successfully
                    resolve(modelAssets)

                } catch (error) {

                    // reject with error
                    reject(error)

                } finally {

                    // log event
                    dispatch("app/logEvent", {
                        name: "New Model Generate Quality Task",
                        description: "User has created a new task to generate a model with high quality"
                    }, { root: true })

                }
            }, rootGetters['api/keyFileKeyDefault'], getters.keyCategoryModel)
        }
    }
}